
<div class="text-center">
    <img class=" image-clickable" src="assets/icons/house_gray_24dp.svg" title="Move to current week" (click)="moveToToday()">
</div>
<div class="text-center">
    <div class="d-flex justify-content-center">
        <img class="chevron p-2" src="assets/icons/chevron_left_gray_24dp.svg" (click)="moveWeek(false)">
        <div class=" px-3">
            <span class="gray-text">{{'basic.week' | translate}} {{weekNumber}}</span>
            <br>
            <span>{{firstDay | date: 'dd.MM.yyyy'}} - {{lastDay | date: 'dd.MM.yyyy'}}</span>
        </div>
        <img class="chevron p-2" src="assets/icons/chevron_right_gray_24dp.svg" (click)="moveWeek(true)">
    </div>
</div>

