import {Component, NgModule, OnInit} from '@angular/core';
import {FormsModule, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import { baseUrl } from '@environments/environment';
import { AuthService } from '@core/auth/auth.service';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import Swal from "sweetalert2";
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  formGroup!: UntypedFormGroup
  passwordShow = false
  token = false

  constructor(
      private authService: AuthService,
      private translateService: TranslateService,
      private route: ActivatedRoute,
      private router: Router
  ) { }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
              this.token = params.token;
          }
      );
    this.initForm()
  }


  initForm() {
    this.formGroup = new UntypedFormGroup({

      password: new UntypedFormControl('', [Validators.required]),
      confirmpassword: new UntypedFormControl('', [Validators.required])
    })
  }

  hideShowPassword() {
      let passwordField = document.getElementById("reset-password");
      let passwordField2 = document.getElementById("reset-password-confirm");
      const type = passwordField!.getAttribute("type") === "password" ? "text" : "password";
      passwordField!.setAttribute("type", type);
      passwordField2!.setAttribute("type", type);
      this.passwordShow = !this.passwordShow
  }

  resetPassword() {
    // todo validate email address
    if(this.formGroup.valid){
        if(this.formGroup.value.password === this.formGroup.value.confirmpassword)
        {
            this.authService.register(`${baseUrl}/api/resetpassword`, {token:this.token, password:this.formGroup.value.password}).subscribe(result=>{
                if(result.status == 'success') {
                    this.router.navigate(['/login'])
                } else {
                    // alert(result)
                }
            })
        } else {
            Swal.fire(this.translateService.instant('basic.passwordsDontMatch'))
        }
    }
  }
}
