import { Pipe } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({name: 'safe'})
export class SafeHtml {
  constructor(private sanitizer:DomSanitizer){}

  /**
   * Used to inject stuff into the DOM (insecure if wrong stuff is injected)
   * @param html 
   * @returns 
   */
  transform(html) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}