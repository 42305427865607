import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChange } from '@angular/core';
import { Router } from '@angular/router';
import { WorkOrderService } from '@modules/planner/services/workorder/work-order.service';
import { UserService } from '@shared/services/user/user.service';
import { TranslateService } from '@ngx-translate/core';
import {Subject, Subscription, takeUntil} from 'rxjs';
import Swal from 'sweetalert2';
import {FieldReportingService} from "@modules/field-reporting/services/field-reporting/field-reporting.service";

@Component({
  selector: 'app-single-order-detail',
  templateUrl: './single-order-detail.component.html',
  styleUrls: ['./single-order-detail.component.scss']
})
export class SingleOrderDetailComponent implements OnInit, OnDestroy {

  componentDestroyed$: Subject<boolean> = new Subject()

  reserver_id;
  userid;
  reserver;
  address;
  timeStart;
  timeWindow;
  endUserListFinal = Array();
  notes;
  interrupt_reason;
  model;
  timeTable;
  accessType: string | null = null
  fieldReportingHeader = document.getElementById('field-reporting-header')
  showPreSelect: boolean = false
  subscriptions = new Subscription()
  accessTypePicture: string | null = null
  reported = false
  phoneNumber: string | null = null
  workorderSpinner: boolean = true
  callingServiceNotes: null | string = null
  oldDeviceId: number | null = null
  locationNumber: string | null = null
  linkSpinner: boolean = false
  project_type: number | null = null

  // Coordinates
  workorderLatitude: string = ""
  workorderLongitude: string = ""

  @Output() showDashboardToggle = new EventEmitter<any>();
  @Input() currentTab;
  @Input() id;
  @Input() runGetWorkOrder;

  constructor(
    private workOrderService: WorkOrderService,
    private fieldReportingService: FieldReportingService,
    private router: Router,
    private datePipe: DatePipe,
    private translateService: TranslateService,
    private userService: UserService,
    ) {}

  ngOnInit(): void {
      this.userService.getUserInfo()
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe( data => {
                  this.userid = data.id;
              }
          )
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }

  /**
   * Run getWorkorder api call to change values of displayed workorder when workorder changes.
   * @param changes
   */
  ngOnChanges(changes: { [propName: string]: SimpleChange}) {
    // changes.prop contains the old and the new value...
    if (changes['runGetWorkOrder'] && changes['runGetWorkOrder'].currentValue == true) {
      this.getWorkOrder()

    }
  }

  /**
   * Main api call to get information about workorder to interface
   */
  getWorkOrder() {
    this.workOrderService.getWorkOrder(this.id)
     .pipe(takeUntil(this.componentDestroyed$))
     .subscribe(
      data => {
        if (data.model != null) {
          this.model = JSON.parse(data.model.replaceAll("'", '"'));
        }

        this.reserver_id = data.reserver_id;
        this.reserver = data.reserver;

        this.oldDeviceId = data.old_device_id
        this.locationNumber = data.location_number
        this.phoneNumber = data.contact_info
        this.notes = data.notes;
        this.interrupt_reason = data.interrupt_reason;
        this.address = data.address;
        this.callingServiceNotes = data.worker_notes
        this.setAccessType(data.access_type)
        this.setTimeWindows(data.time_window_start, data.time_window_end);
        if (data.enduser_id != null) {
          this.getEndUsers(data.enduser_id)
        }
        if (data.time_table) {
          this.timeTable = data.time_table
        }

        if (data.attend_time) this.reported = true
        else this.reported = false
        let lon = ""
        let lat = ""

        // Try catch coordinates, every point in data might not have these
        try {
          lon = JSON.parse(data.coordinates!).lon
          lat = JSON.parse(data.coordinates!).lat
        } catch (error) {
          //console.log(error)

        }

        this.workorderLatitude = lat;
        this.workorderLongitude = lon

        if(data.project_id) {
          this.getProject(data.project_id)
        }

        this.workorderSpinner = false
      }

    )
  }
  /**
   * Main api call to get information about workorder to interface
   */
  getProject(projectId: number) {
    this.fieldReportingService.getWorkerProjects(projectId)
     .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(projectJSON => {
        if (projectJSON.hasOwnProperty('project_type')) {
          this.project_type = JSON.parse(projectJSON.project_type)
        }
      })

  }


  /**
   * Reset work orders info and emit function call to parent component closing single order detail
   */
  closeSingleOrder() {
    this.address = null;
    this.timeStart = null;
    this.timeWindow = null;
    this.endUserListFinal = Array()
    this.workorderSpinner = true
    this.showDashboardToggle.emit()
  }

  /**
   * Set time windows for workorder
   * @param timeWindowStart starting time of workorder, got from database
   * @param timeWindowEnd ending time of workorder, got from database
   */
  setTimeWindows(timeWindowStart, timeWindowEnd) {
    if (timeWindowStart != null && timeWindowEnd != null) {
      this.timeStart = this.datePipe.transform(timeWindowStart.replace(' ', 'T') ,'dd.MM.yyyy')
      this.timeWindow = this.datePipe.transform(timeWindowStart.replace(' ', 'T'), 'HH:mm') + " - " + this.datePipe.transform(timeWindowEnd.replace(' ', 'T'), 'HH:mm')
    }
  }

  /**
   * Set endusers of workorder to be displayed correctly
   * @param endUserList List of endusers, which contains all information we need
   */
  getEndUsers(endUserList) {
    this.endUserListFinal = []
    for (let i = 0; i < endUserList.length; i++) {
      let personType: string = this.translateService.instant('fieldReporting.singleOrderDetail.differentThanOwnerOrResident')
      if (endUserList[i].person_type == 0) personType = this.translateService.instant('persontype.0')
      else if (endUserList[i].person_type == 2) personType = this.translateService.instant('persontype.2')
      let name = endUserList[i].contact_person_first + " " +  endUserList[i].contact_person_last
      this.endUserListFinal.push({type: personType, phone: endUserList[i].phone, name: name})
    }
  }

  /**
   * Show report selection phase when user clicks "create report" button.
   * Also handles closing of report options.
   */
  toggleReportSelection() {
    if (this.showPreSelect == false) {
      this.showPreSelect = true
      if (this.fieldReportingHeader) this.fieldReportingHeader.style.display = 'none'
    }
    else {
      this.showPreSelect = false
      if (this.fieldReportingHeader) this.fieldReportingHeader.style.display = 'block'
    }
  }

  /**
   * Navigates to correct page of selected report option after click
   * @param reportName which option is selected
   */
  moveToReport(projectType: number | null) {
    this.workOrderService.updateWorkorderStatus(this.id, 3)
    .subscribe(
      () => {
        if (this.fieldReportingHeader) this.fieldReportingHeader.style.display = 'block'
        let url;
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
        if (projectType === 1) url = '/field-reporting/report/' + this.id
        else if (projectType === 2) url = '/field-reporting/wiring-report/' + this.id
        else if (projectType === 3) url = '/field-reporting/danish-water-meter-report/' + this.id
        else if (projectType === 4) url = '/field-reporting/danish-heat-meter-report/' + this.id
        else if (projectType === 5) url = '/field-reporting/danish-heat-meter-report-six-values/' + this.id
        else if (projectType === 6) url = '/field-reporting/german-electric-meter-report/' + this.id
        else if (projectType === 7) url = '/field-reporting/danish-heat-meter-report-twelve-pictures/' + this.id
        else {
          Swal.fire(this.translateService.instant('fieldReporting.singleOrderDetail.notYetImplemented'))
          return
        }
        if (url) this.router.navigate([url])
        
      }
    )


  }

  /**
   * Set access type to interface of workorder
   * @param accessTypeNumber number of access type from database
   */
  setAccessType(accessTypeNumber) {
    if (accessTypeNumber !== null) {
      if (accessTypeNumber === 3) {
        this.accessType = this.translateService.instant('accessTypes.1')
        this.accessTypePicture = "assets/icons/key_blue_24dp.svg"
      }
      else if (accessTypeNumber === 2) {
        this.accessType = this.translateService.instant('accessTypes.2')
        this.accessTypePicture = "assets/icons/person_lock_blue_24dp.svg"
      }
      else if (accessTypeNumber === 1) {
        this.accessType = this.translateService.instant('accessTypes.3')
        this.accessTypePicture = "assets/icons/lock_unlocked_blue_24dp.svg"
      }
    }
  }

  /**
   * Open google maps as link if everything is successful. Get workorders coordinates from database.
   * Get users coordinates from geolocator
   */
  openGoogleMaps() {
    this.linkSpinner = true
    if (this.workorderLatitude != "" && this.workorderLongitude != "") {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            let latitude = position.coords.latitude
            let longitude = position.coords.longitude
            if (latitude && longitude) {
              let link = ""+"https://maps.google.com/maps?saddr="+ latitude +","+ longitude +" &daddr="+ this.workorderLatitude +","+ this.workorderLongitude ;
              this.linkSpinner = false
              window.open(link, '_blank')?.focus()
            } else {
              Swal.fire(this.translateService.instant('fieldReporting.singleOrderDetail.noUserCoordinates'))
              this.linkSpinner = false
            }
          },
          // Navigator returning error
          error => {
            if (error.message == 'User denied Geolocation') {
              Swal.fire(this.translateService.instant('fieldReporting.singleOrderDetail.locationBlocked'))
              this.linkSpinner = false
            } else {
              Swal.fire(this.translateService.instant('basic.unknownError'))
              this.linkSpinner = false
            }
          }
        )
      } else {
        // Navigator does not support browser
        Swal.fire(this.translateService.instant('fieldReporting.singleOrderDetail.browserDoesNotSupport'))
        this.linkSpinner = false
      }
    } else {
      Swal.fire(this.translateService.instant('fieldReporting.singleOrderDetail.noWorkorderCoordinatesError'))
      this.linkSpinner = false
    }
  }

  copyToClipboard() {
    const element = <HTMLDivElement>document.querySelector('#phoneNumber');
    const promise = navigator.clipboard.writeText(element.innerHTML)
    promise.then(
      () => alert('Copied phone number to clipboard')
    )
}

  reserve() {
    let saveButton = <HTMLButtonElement>document.getElementById('reserveBtn')
    if (saveButton) saveButton.disabled = true
    this.workOrderService.reserveWorkorderToWorker(this.id, this.userid)
    .subscribe(
      () => {
          this.getWorkOrder()
          if (saveButton) saveButton.disabled = false
      }
    )
  }

  cancelReservation() {
    let saveButton = <HTMLButtonElement>document.getElementById('cancelBtn')
    if (saveButton) saveButton.disabled = true
    this.workOrderService.cancelReservation(this.id)
    .subscribe(
      () => {
          this.getWorkOrder()
          if (saveButton) saveButton.disabled = false
      }
    )
  }
}
