<div class="height100vh">
    <div class="d-flex justify-content-center">
        <load-spinner [hidden]="!workorderSpinner"></load-spinner>
    </div>
    <div class="rounded-card order-container" *ngIf="!showPreSelect && !workorderSpinner">
        <div class="dual-container">
            <div class="left">
                <h2>{{currentTab}}</h2>
            </div>
            <div class="right">
                <button type="button" id="map-nav-close-btn" class="btn-close right" (click)="closeSingleOrder()" aria-label="Close"></button>
            </div>
        </div>
        <div class="row">
            <div class="col-5 gray-text">
                <div class="display-flex" *ngIf="this.reserver_id">
                    <div class="tagbox-green" *ngIf="this.reserver_id == this.userid "><img id="meImg" src="assets/icons/person_white_32x32px.svg"> {{'fieldReporting.dashboard.assignedToMe' | translate }}</div>
                    <div class="tagbox-grey"*ngIf="this.reserver_id != this.userid"><img id="elseImg" src="assets/icons/person_white_32x32px.svg"> {{'fieldReporting.dashboard.assignedTo' | translate }} {{this.reserver}}</div>
                </div>
                <div class="display-flex" *ngIf="!this.reserver_id">
                    <div class="tagbox" >Free</div>
                </div>
                <hr *ngIf="this.reserver_id">
                <p>
                    <span>{{'basic.meter' | translate}}</span>
                    <span *ngIf="oldDeviceId" class="ml-1">{{oldDeviceId}}</span>
                    <span *ngIf="!oldDeviceId" class="ml-1">-</span>
                    <br>
                    <span *ngIf="locationNumber">{{locationNumber}}</span>
                    <span *ngIf="!locationNumber">-</span>
                </p>
            </div>
            <div class="col-7">
                <div class="float-end">
                    <img [src]="accessTypePicture" class="map-nav-icon">
                    <div class="m-c-child-content map-nav-item fw-500 ">
                        <p class="blue-text" *ngIf="accessType">{{accessType}}</p>
                        <p *ngIf="!accessType"></p>
                    </div>
                </div>
            </div>
        </div>

        <div class="m-c-child nav-item-container mt-3" >
            <div class="row">
                <div class="col-5">
                    <embed src="assets/icons/today_blue_24dp.svg" class="map-nav-icon">
                        <div class="m-c-child-content map-nav-item gray-text">
                            <span *ngIf="timeStart">{{timeStart}}</span>
                            <span *ngIf="!timeStart" class="red-text">{{'fieldReporting.shared.missingDate' | translate}}</span>
                        </div>
                </div>
                <div class="col-7">
                    <embed src="assets/icons/schedule_blue_24dp.svg" class="map-nav-icon">
                        <div class="m-c-child-content map-nav-item gray-text">
                            <span *ngIf="timeWindow">{{timeWindow}}</span>
                            <span *ngIf="!timeWindow" class="red-text">XX:XX - XX:XX</span>
                        </div>
                </div>
            </div>
        </div>
        <hr>

      <div class="m-c-child nav-item-container mt-3" >
        <div class="row">
          <div class="col-12">
            <embed src="assets/icons/speed_blue_24dp.svg" class="map-nav-icon">
            <div class="m-c-child-content map-nav-item gray-text">
              <div *ngFor="let estimate of this.timeTable | keyvalue">
                <b>{{estimate.key}}:</b> {{estimate.value}}
              </div>
            </div>
          </div>
        </div>
      </div>
        <hr>
        <div class="m-c-child nav-item-container" >
            <div class="dual-container">
                <div class="left">
                    <embed src="assets/icons/location_on_blue_24dp.svg" class="map-nav-icon">
                    <div class="m-c-child-content map-nav-item gray-text">
                        <span *ngIf="address">{{address}}</span>
                        <span *ngIf="!address" class="red-text">{{'fieldReporting.singleOrderDetail.missingAddress' | translate}}</span>
                    </div>
                </div>
                <div class="right cursor-pointer">
                    <img [hidden]="linkSpinner" (click)="openGoogleMaps()" src="assets/icons/directions_white_24dp.svg" class="icon-right directions">
                    <load-spinner [hidden]="!linkSpinner"></load-spinner>
                </div>
            </div>
        </div>
        <hr>
        <div class="m-c-child nav-item-container" >
            <div class="dual-container">
                <div class="left">
                    <embed src="assets/icons/call_blue_48.svg" class="map-nav-icon">
                    <div class="m-c-child-content map-nav-item gray-text">
                        <span *ngIf="phoneNumber" type="tel">{{phoneNumber}}</span>
                        <span *ngIf="!phoneNumber" >-</span>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="m-c-child-content nav-item-container mt-3">
            <div *ngFor="let item of endUserListFinal">
                <embed src="assets/icons/person_blue_24dp.svg" class="map-nav-icon">
                <div class="edit-input">
                    <p class="blue-text fw-500">{{item.type}}</p>
                    <p class="gray-text">{{item.name}}</p>
                    <a *ngIf="item.phone" (click)="copyToClipboard()"><img src="assets/icons/content_copy_darkgray_24dp.svg" class="map-nav-icon copy-text cursor-pointer"></a>
                    <p *ngIf="item.phone"  class="gray-text edit-input phone-text" id="phoneNumber">{{item.phone}}</p>
                </div>
            </div>
            <div *ngIf="endUserListFinal.length == 0" class="red-text">
                <embed src="assets/icons/person_blue_24dp.svg" class="map-nav-icon">
                <div class="edit-input">{{'fieldReporting.singleOrderDetail.noContacts' | translate}}</div>
            </div>
        </div>
        <hr>
        <div class="m-c-child nav-item-container" >
            <embed src="assets/icons/meter_model_blue_24dp.svg" class="map-nav-icon">
            <div class="edit-input">
                <p class="blue-text fw-500">{{'basic.meterModel' | translate}}</p>
                <p class="gray-text" *ngIf="model">{{model.name}}</p>
                <p *ngIf="!model">-</p>
            </div>
        </div>
        <hr>
        <div class="m-c-child nav-item-container mb-4" >
            <embed src="assets/icons/info_blue_24dp.svg" class="map-nav-icon">
            <div class="m-c-child-content map-nav-item gray-text">
                <div class="blue-text">{{'fieldReporting.shared.extraInformationPlanner' | translate }}</div>
                <span *ngIf="callingServiceNotes">{{callingServiceNotes}}</span>
                <span *ngIf="!callingServiceNotes">-</span>
                
                <!-- <div class="blue-text">{{'fieldReporting.shared.extraInformationCallingService' | translate }}</div>
                <span *ngIf="notes">{{notes}}</span>
                <span *ngIf="!notes">-</span> -->
                <div *ngIf="interrupt_reason" class="blue-text">{{'fieldReporting.shared.interruptReason' | translate }}</div>
                <span *ngIf="interrupt_reason">{{interrupt_reason}}</span>
            </div>
        </div>
        <hr>
        <div class="m-c-child nav-item-container mb-4">
            <button *ngIf="!this.reserver_id || this.reserver_id != this.userid" id="reserveBtn" class="btn btn-success w-100" (click)="reserve()">{{'fieldReporting.singleOrderDetail.reserve' | translate }}</button>
            <button *ngIf="this.reserver_id && this.reserver_id == this.userid"  id="cancelBtn" class="btn btn-secondary w-100" (click)="cancelReservation()">{{'fieldReporting.singleOrderDetail.cancelReservation' | translate }}</button>
        </div>
        <hr>
        <h5 class="blue-text mb-5" *ngIf="reported">{{'fieldReporting.singleOrderDetail.meterReported' | translate }}</h5>
        <button class="btn btn-primary w-100" (click)="moveToReport(project_type)">{{'fieldReporting.singleOrderDetail.createReport' | translate }}</button>
    </div>
</div>
