import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import languages from 'assets/language-list/language-list.json'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

/**
 * This is the app component #compodoctesti
 */
export class AppComponent {

  constructor( private translate: TranslateService ) {

  }


  title = 'Star';
  login = false;
  dashboard = true;
  languageList = languages;

  ngOnInit() {
    // Default language for application
    this.translate.setDefaultLang('en');
    this.translate.addLangs(this.languageList.languages)
    this.translate.use('en')
  }



}


