import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterMsaByProject'
})
export class FilterMsaByProjectPipe implements PipeTransform {

  transform(msaList: any[], selectedProjectId: number | undefined): any[] {
    if (!msaList || !selectedProjectId) {
      return msaList;
    }
    return msaList.filter(msa => (msa.project_id === selectedProjectId) || msa.project_id === 0);
  }

}
