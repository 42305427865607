import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter'
})
export class SearchPipe implements PipeTransform {

    /**
     * Followed by a guide in Codeburst
     * https://codeburst.io/create-a-search-pipe-to-dynamically-filter-results-with-angular-4-21fd3a5bec5c
     */
  
    transform(items: any[], searchText: string): any {
      if (!items) return [];
      if (!searchText) return items

      searchText = searchText.toLowerCase();
      return items.filter( it => {
        return it.name.toLowerCase().includes(searchText)
      })
    }
}


