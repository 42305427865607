<div class="page-container">
    <div class="page-heading">
        <h3>
           <img class="nav-icon" src="assets/icons/list_alt_black_24dp.svg">
           <span class="page-title">{{'sideNavigation.titleImportData' | translate}}</span>
        </h3>
     </div>
    <div class="page-content">
        <div class="card">
            <div class="card-body">
                <div class="header">
                    <nav class="nav justify-content-center">
                        <!-- The second part of ngStyle can later be adjusted accoring to design when the tab functionalities are completed -->
                        <a class="nav-link" [ngStyle]="{'color': tabindex == 1 ? '#0058ff' : '#707591', 'border-bottom': tabindex == 1 ? '1px solid #0058ff' : '1px solid #707591'}">{{'sideNavigation.titleImportData' | translate}}</a>
                        <a class="nav-link" [ngStyle]="{'color': tabindex == 2 ? '#0058ff' : '#707591', 'border-bottom': tabindex == 2 ? '1px solid #0058ff' : '1px solid #707591'}">{{'import.matchColumns' | translate}}</a>
                    </nav>
                </div>
                <div id="tab-container">

                    <!-- FIRST TAB -->

                    <ng-container id="tab1" *ngIf="tabindex == 1">
                        <div class="form-group mt-10">
                            <h3 id="import-data">{{'sideNavigation.titleImportData' | translate}}</h3>
                        </div>

                        <div id="project-basic-information">
                            <h4 class="gray-text">{{'planner.importData.selectImport' | translate}}</h4>
                            <div class="mt-10 d-flex">
                                <button id="import-workorders" class="btn left mr-3 text-light" (click)="workOrderSelect()" [ngClass]="workOrderSelected == true ? 'btn-primary' : 'btn-secondary'">{{'planner.importData.importWorkorders' | translate}}</button>
                                <button id="import-transformers" class="btn btn-secondary right" (click)="transformerSelect()" [ngClass]="transFormerSelected == true ? 'btn-primary' : 'btn-secondary'">{{'planner.importData.importTransformers' | translate}}</button>
                            </div>
                            <div class="form-group mt-10" *ngIf="transFormerSelected">
                                <h3>{{'planner.importData.importTransformers' | translate}}</h3>
                                <div class="import-coordinates p-4">
                                  <div>{{'import.generateTransformersBasic' | translate}}</div>
<!--                                  <div class="fw-bold">{{'import.generateTransformersCost' | translate}}.</div>-->
                                  <div class="d-flex mt-2">
                                    <input type="checkbox" [(ngModel)]="generateTransformers" class="form-check-input margin-top-1">
                                    <span class="ms-1">{{'import.generateTransformersConfirmation' | translate}}</span>
                                  </div>
                                </div>
                            </div>
                            <div class="form-group mt-10" *ngIf="workOrderSelected">
                                <h3>{{'planner.importData.importWorkorders' | translate}}</h3>
                                <div class="import-coordinates p-4">
                                    <div>{{'import.generateMetersBasic' | translate}}?</div>
                                    <div class="fw-bold">{{'import.generateMetersCost' | translate}}.</div>
                                    <div class="d-flex mt-2">
                                        <input type="checkbox" [(ngModel)]="generateMeters" class="form-check-input margin-top-1">
                                        <span class="ms-1">{{'import.generateMetersConfirmation' | translate}}?</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mt-10" *ngIf="workOrderSelected || transFormerSelected">
                                <div class="mt-10" ngClass="{{dragAreaClass}}">
                                    <div id="drop-xlsx" *ngIf="!fileSelected" class="text-center">
                                       <header>{{'import.dropXLSX' | translate}}</header>
                                    </div>
                                    <div *ngIf="fileSelected" class="text-center">
                                        <header class="blue-text">{{ fileName }}<span> {{'basic.selected' | translate | lowercase}}</span></header>
                                    </div>
                                    <input type="file" hidden accept=".xlsx">
                                  </div>
                                  <div class="row mt-10">
                                    <div class="col-md-3">
                                        <button *ngIf="!fileSelected" class="btn btn-primary w-100" (click)="fileInput.click()">{{'import.uploadFromPC' | translate}}<input #fileInput type="file" hidden accept=".xlsx" type="file" (change)="onFileChange($event)"></button>
                                        <button *ngIf="fileSelected" class="btn btn-secondary w-100" (click)="fileInput.click()">{{'import.changeFile' | translate}}<input #fileInput type="file" hidden accept=".xlsx" type="file" (change)="onFileChange($event)"></button>
                                    </div>
                                    <div class="col-md-3">
                                        <button id="next-button" class="btn btn-primary w-100" (click)="nextStep(3)">{{'basic.next' | translate}}</button>
                                    </div>
                                    <div class="col-md-3">
                                        <button class="btn btn-secondary w-100" (click)="nextStep(1)">{{'basic.cancel' | translate}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </ng-container>

                    <!-- MIDDLE TAB -->

                    <ng-container id="tab2" *ngIf="tabindex == 2">
                        <div class="form-group mt-10" *ngIf="userStepCount !== 5">
                            <h3 id="match-columns">{{'import.matchColumns' | translate}}</h3>
                        </div>
                        <div *ngIf="userStepCount === 3">
                            <div class="row">
                                <div class="col-md-7 row">
                                    <div class="col-md-6 row-selector form-group">
                                        <label class="select-label" for="headline">{{'import.headlineRow' | translate}}</label>
                                        <br>
                                        <select name="headline" id="headlineIndex" class="form-select" [(ngModel)]="headlineIndex">
                                            <option *ngFor="let row of rowsData| keyvalue: originalOrder" value="{{row.key}}" [selected]="row[0]" ng-disabled="row[0]">
                                                {{row.value}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-md-6 row-selector form-group">
                                        <label class="select-label" for="data">{{'import.dataStarts' | translate}}</label>
                                        <br>
                                        <select name="data" id="dataStartIndex" class="form-select" [(ngModel)]="dataStartIndex">
                                            <option *ngFor="let row of rowsData | keyvalue: originalOrder" value="{{row.key}}" [selected]="row[0]" ng-disabled="row[0]">
                                                {{row.value}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <button id="next-button-2" *ngIf="!rowsAreSelected" class="btn btn-primary w-100" (click)="rowSelectHandler()">{{'basic.next' | translate}}</button>
<!--                                            <button class="btn btn-secondary w-50" (click)="moveBack()">{{'basic.back' | translate}}</button>-->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="rowsAreSelected && userStepCount == 4" class="mt-10">
                            <div class="form-group">
                                <label>{{'planner.importData.matchColumnsHeadlines' | translate}}</label>
                            </div>
                            <div class="horizontal-scroll w-100">
                                <table class="table table-striped table-responsive" id="table1">
                                    <thead *ngIf="transFormerSelected">
                                       <tr>
                                            <th *ngFor="let title of rowsData[headlineIndex] | keyvalue: originalOrder" class="p-0">
                                                <div class="gray-border th-padding">
                                                    <div class="mt-1 fw-600"> {{ title.value }} </div>
                                                    <select (change)="matchColumns($event.target.value)" class="form-select column-select">
                                                        <option selected value="{{title.key}}">-</option>
                                                        <option [selected]="excelMatchArray['lat'] == title.key" value="lat-{{title.key}}">{{'basic.latitude' | translate}} {{this.generateTransformers ? '' : '*'}}</option>
                                                        <option [selected]="excelMatchArray['lon'] == title.key" value="lon-{{title.key}}">{{'basic.longitude' | translate}} {{this.generateTransformers ? '' : '*'}}</option>
                                                        <option [selected]="excelMatchArray['ordergroup_identifier'] == title.key" value="ordergroup_identifier-{{title.key}}">{{'basic.ordergroupID' | translate}}</option>
                                                        <option [selected]="excelMatchArray['msa_id'] == title.key" value="msa_id-{{title.key}}">{{'planner.newProject.columns.msaId' | translate}}</option>
                                                    </select>
                                                </div>
                                            </th>
                                       </tr>
                                    </thead>
                                    <thead *ngIf="workOrderSelected">
                                    <tr>
                                        <th *ngFor="let title of rowsData[headlineIndex] | keyvalue: originalOrder" class="p-0">
                                            <input *ngIf="excelMatchArray['time_parameter_columns']" type="checkbox" class="mr-1 ml-05" id="timeparameter_{{title.key}}" value="timeparameter-{{title.key}}" (change)="matchColumns($event.target.value)" [checked]="excelMatchArray['time_parameter_columns'].includes(title.key)">
                                            <input *ngIf="!excelMatchArray['time_parameter_columns']" type="checkbox" class="mr-1 ml-05" id="timeparameter_{{title.key}}" value="timeparameter-{{title.key}}" (change)="matchColumns($event.target.value)">
                                            <label class="gray-text fw-normal" for="timeparameter-{{title.key}}">{{'basic.use' | translate}}</label>
                                            <div class="gray-border th-padding">
                                                <div class="mt-1 fw-600"> {{ title.value }} </div>
                                                <!-- NOTE! If you add columns here, also add them to importHeadlines in planner-project.service-->
                                                <select (change)="matchColumns($event.target.value)" class="form-select column-select">
                                                    <option selected value="{{title.key}}">-</option>
                                                    <option [selected]="excelMatchArray['lat'] == title.key" value="lat-{{title.key}}">{{'basic.latitude' | translate}} {{generateMeters === false ? '*' : null}}</option>
                                                    <option [selected]="excelMatchArray['lon'] == title.key" value="lon-{{title.key}}">{{'basic.longitude' | translate}} {{generateMeters === false ? '*' : null}}</option>
                                                    <option [selected]="excelMatchArray['address'] == title.key" value="address-{{title.key}}">{{'basic.address' | translate}} {{generateMeters === true ? '*' : null}}</option>
                                                    <option [selected]="excelMatchArray['zip'] == title.key" value="zip-{{title.key}}">{{'planner.newProject.columns.zip' | translate}} {{generateMeters === true ? '*' : null}}</option>
                                                    <option [selected]="excelMatchArray['city'] == title.key" value="city-{{title.key}}">{{'planner.newProject.columns.city' | translate}} {{generateMeters === true ? '*' : null}}</option>
                                                    <option [selected]="excelMatchArray['ordergroup_identifier'] == title.key" value="ordergroup_identifier-{{title.key}}">{{'basic.ordergroupID' | translate}}</option>
                                                    <option [selected]="excelMatchArray['workorder_identifier'] == title.key" value="workorder_identifier-{{title.key}}">{{'basic.workorderID' | translate}}</option>
                                                    <option [selected]="excelMatchArray['notes'] == title.key" value="notes-{{title.key}}">{{'planner.newProject.columns.notes' | translate}}</option>
                                                    <option [selected]="excelMatchArray['access_type'] == title.key" value="access_type-{{title.key}}">{{'basic.accessType' | translate}}</option>
                                                    <option [selected]="excelMatchArray['access_type_notes'] == title.key" value="access_type_notes-{{title.key}}">{{'planner.newProject.columns.accessTypeNotes' | translate}}</option>
                                                    <option [selected]="excelMatchArray['location_number'] == title.key" value="location_number-{{title.key}}">{{'basic.locationNumber' | translate}}</option>
                                                    <option [selected]="excelMatchArray['old_device_id'] == title.key" value="old_device_id-{{title.key}}">{{'planner.newProject.columns.oldDeviceID' | translate}}</option>
                                                    <option [selected]="excelMatchArray['worker_notes'] == title.key" value="worker_notes-{{title.key}}">{{'planner.newProject.columns.workerNotes' | translate}}</option>
                                                    <option [selected]="excelMatchArray['fuse_limit'] == title.key" value="fuse_limit-{{title.key}}">{{'planner.newProject.columns.fuseLimit' | translate}}</option>
                                                    <option [selected]="excelMatchArray['fuse_type'] == title.key" value="fuse_type-{{title.key}}">{{'planner.newProject.columns.fuseType' | translate}}</option>
                                                    <option [selected]="excelMatchArray['story'] == title.key" value="story-{{title.key}}">{{'basic.story' | translate}}</option>
                                                    <option [selected]="excelMatchArray['placement'] == title.key" value="placement-{{title.key}}">{{'basic.placement' | translate}}</option>
                                                    <option [selected]="excelMatchArray['contact_info'] == title.key" value="contact_info-{{title.key}}">{{'planner.newProject.columns.contactInfo' | translate}}</option>
                                                    <option [selected]="excelMatchArray['msa_id'] == title.key" value="msa_id-{{title.key}}">{{'planner.newProject.columns.msaId' | translate}}</option>
                                                    <!-- Owner -->
                                                    <option [selected]="excelMatchArray['owner_firstname'] == title.key" value="owner_firstname-{{title.key}}">{{'planner.newProject.columns.oFirstName' | translate}}</option>
                                                    <option [selected]="excelMatchArray['owner_lastname'] == title.key" value="owner_lastname-{{title.key}}">{{'planner.newProject.columns.oLastName' | translate}}</option>
                                                    <option [selected]="excelMatchArray['owner_zip'] == title.key" value="owner_zip-{{title.key}}">{{'planner.newProject.columns.oZip' | translate}}</option>
                                                    <option [selected]="excelMatchArray['owner_address'] == title.key" value="owner_address-{{title.key}}">{{'planner.newProject.columns.oAddress' | translate}}</option>
                                                    <option [selected]="excelMatchArray['owner_city'] == title.key" value="owner_city-{{title.key}}">{{'planner.newProject.columns.oCity' | translate}}</option>
                                                    <option [selected]="excelMatchArray['owner_phone'] == title.key" value="owner_phone-{{title.key}}">{{'planner.newProject.columns.oPhone' | translate}}</option>
                                                    <option [selected]="excelMatchArray['owner_email'] == title.key" value="owner_email-{{title.key}}">{{'planner.newProject.columns.oEmail' | translate}}</option>
                                                    <!-- Resident -->
                                                    <option [selected]="excelMatchArray['resident_firstname'] == title.key" value="resident_firstname-{{title.key}}">{{'planner.newProject.columns.rFirstName' | translate}}</option>
                                                    <option [selected]="excelMatchArray['resident_lastname'] == title.key" value="resident_lastname-{{title.key}}">{{'planner.newProject.columns.rLastName' | translate}}</option>
                                                    <option [selected]="excelMatchArray['resident_zip'] == title.key" value="resident_zip-{{title.key}}">{{'planner.newProject.columns.rZip' | translate}}</option>
                                                    <option [selected]="excelMatchArray['resident_address'] == title.key" value="resident_address-{{title.key}}">{{'planner.newProject.columns.rAddress' | translate}}</option>
                                                    <option [selected]="excelMatchArray['resident_city'] == title.key" value="resident_city-{{title.key}}">{{'planner.newProject.columns.rCity' | translate}}</option>
                                                    <option [selected]="excelMatchArray['resident_phone'] == title.key" value="resident_phone-{{title.key}}">{{'planner.newProject.columns.rPhone' | translate}}</option>
                                                    <option [selected]="excelMatchArray['resident_email'] == title.key" value="resident_email-{{title.key}}">{{'planner.newProject.columns.rEmail' | translate}}</option>
                                                </select>
                                            </div>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                       <tr *ngFor="let data of customRowsData">
                                          <td *ngFor="let item of data"> {{ item }}</td>
                                       </tr>
                                    </tbody>
                              </table>
                            </div>
                            <div class="row mt-10">
                                <div class="col-md-6">
                                    <button *ngIf="transFormerSelected" class="btn btn-primary w-50" (click)="saveOrdergroups()">{{'basic.next' | translate}}</button>
                                    <button *ngIf="workOrderSelected" class="btn btn-primary w-50" (click)="saveWorkorders()">{{'basic.next' | translate}}</button>
                                    <button class="btn btn-secondary w-50" (click)="moveBack()">{{'basic.back' | translate}}</button>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="userStepCount == 5" class="text-center mt-10">
                            {{'planner.importData.waiting' | translate}}
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
