import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-admin-management',
  templateUrl: './customerAdmin-management.component.html',
  styleUrls: ['./customerAdmin-management.component.scss']
})
export class CustomerAdminManagementComponent implements OnInit {

  constructor(private router: Router) { }

  plannerCount = 0;
  activePlannerCount = 0;
  callServiceCount = 0;
  activeCallServiceCount = 0;
  adminCount = 0;
  activeAdminCount = 0;

  ngOnInit(): void {
  }

  /**
   * 12.12.2022
   * Navigate to workers or teams page
   * @param destination name of landing page
   * @author Jesse Lindholm
   */
  navigate(destination: string) {
    if (destination === 'workers' || destination === 'teams') this.router.navigate([`/planner/${destination}`])
  }

}
