<div class="d-flex justify-content-center login-container">
    <div id="auth-left2" class="shadow-sm rounded-card">
        <h2 class="auth-title fw-600">{{'loginRegister.forgotPassword' | translate}}</h2>
        <p class="auth-subtitle mb-5">{{'loginRegister.forgotText' | translate }}</p>
        <form action="index.html" id="forgot-form" [formGroup]="formGroup" (ngSubmit)="resetPassword()" class="mt-5">
            <div class="position-relative has-icon-left mb-4">
                {{'basic.password' | translate}}
                <div class="opacity-75 password_field dual-container">
                    <input id="reset-password" type="password" class="form-control mt-1" formControlName="password">
                    <button (click)="hideShowPassword()" id="button-toggle-hide-password" class="eye-icon" type="button"><img *ngIf="!passwordShow" src="assets/icons/visibility_gray_24dp.svg"> <img *ngIf="passwordShow" src="assets/icons/visibility_off_gray_24dp.svg"></button>
                </div>
            </div>
            <div class="position-relative has-icon-left mb-4">
                {{'basic.passwordAgain' | translate }}
                <div class="opacity-75 password_field dual-container">
                    <input id="reset-password-confirm" type="password" class="form-control mt-1" formControlName="confirmpassword">
                    <button (click)="hideShowPassword()" class="eye-icon" type="button"><img *ngIf="!passwordShow" src="assets/icons/visibility_gray_24dp.svg"> <img *ngIf="passwordShow" src="assets/icons/visibility_off_gray_24dp.svg"></button>
                </div>
            </div>
            <button id="login-button" class="btn btn-primary btn-block btn-lg shadow-lg mt-5">{{'basic.submit' | translate }}</button>
        </form>
    </div>
</div>
