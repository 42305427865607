import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '@app/app-routing.module';
import { EndUserLoginComponent } from './components/end-user-login/end-user-login.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmSlotComponent } from './components/confirm-slot/confirm-slot.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [
    EndUserLoginComponent,
    ConfirmSlotComponent,
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule
  ]
})
export class EndUserModule { }
