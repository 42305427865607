    <div class="d-flex justify-content-center login-container">
        <div id="auth-left" class="shadow-sm rounded-card">
            <h3 class="auth-title fw-600">{{'endUser.loginTitle' | translate}}</h3>
            <p class="mt-3">{{'endUser.loginHelp' | translate}}</p>
            <form id="login-form" [formGroup]="formGroup" (ngSubmit)="loginProcess()" class="mt-3">
                <p *ngIf="authFail" class="red-text">{{'endUser.error' | translate}}</p>
                <div class="position-relative has-icon-left mb-4">
                    {{'basic.meteringPointNumber' | translate}}
                    <div class="opacity-75">
                        <input id="login-email" type="text" class="form-control mt-1" placeholder="{{'basic.meteringPointNumber' | translate}}" formControlName="location_number">
                    </div>
                </div>
                <div class="position-relative has-icon-left mb-4">
                    {{'basic.password' | translate }}
                    <div class="opacity-75 password_field dual-container">
                        <input id="login-password" type="password" class="form-control mt-1" placeholder="{{'basic.password' | translate }}" formControlName="password">
                        <button (click)="hideShowPassword()" class="eye-icon" type="button"><img *ngIf="!passwordShow" src="assets/icons/visibility_gray_24dp.svg"> <img *ngIf="passwordShow" src="assets/icons/visibility_off_gray_24dp.svg"></button>
                    </div>
                </div>
                <div>{{'basic.interfaceLanguage' | translate}}</div>
                <div ngbDropdown class="mb-4">
                    <button class="btn btn-secondary w-100" type="button" id="dropdownButton" ngbDropdownToggle>
                        <span *ngIf="!selectedLanguage.databaseLanguage" class="float-start">{{'endUser.defaultLang' | translate}}</span>
                        <span *ngIf="selectedLanguage.databaseLanguage" class="float-start">{{selectedLanguage.displayName}}</span>
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownButton" class="w-100">
                        <div *ngFor="let language of interfaceLanguages">
                            <button ngbDropdownItem type="button" (click)="changeLanguage(language.databaseLanguage)">{{language.displayName}}</button>
                        </div>
                    </div>
                </div>
                <button id="login-button" class="btn btn-green btn-block btn-lg shadow-lg mt-2">{{'basic.login' | translate}}</button>
            </form>

        </div>
    </div>
