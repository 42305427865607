import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@core/auth/auth.service';
import {baseUrl, integUrl} from "@environments/environment";

/**
 *  An HTTP interceptor that adds an authorization header to all API requests.
 */

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService) {}

  intercept(
      request: HttpRequest<any>,
      next: HttpHandler
  ): Observable<HttpEvent<any>> {

    const accessToken = this.auth.getJwtToken()
    const accessIntegrationToken = this.auth.getIntegrationJwtToken()

    if(request.url.startsWith(baseUrl) && accessToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`
        }
      });
    } else if(request.url.startsWith(integUrl) && accessIntegrationToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessIntegrationToken}`
        }
      });
    }
    return next.handle(request);
  }
}
