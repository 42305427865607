import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { UserService } from '@shared/services/user/user.service';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class UserInfoResolver  {
  constructor(private userService: UserService) {}

  async resolve(route: ActivatedRouteSnapshot) {
    try {
      const userInfo = await firstValueFrom(this.userService.getUserInfo());
      return userInfo;
    } catch (error) {
      // Handle error if applicable
      console.error('Error fetching user info:', error);
      return null; // or return an appropriate default value
    }
  }
}
