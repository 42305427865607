<div class="page-content">
    <div class="card">
        <div class="header">
            <div class="d-flex">
                <div class="w-20 justify-content-center d-flex">
                    <img (click)="backToDashboard()" src="assets/icons/chevron_left_gray_24dp.svg">
                </div>
                <div class="w-80 d-flex justify-content-center">
                    <img class="image-size-edit" src="assets/icons/edit_blue_24dp.svg">
                    <h6 class="page-title">{{'fieldReporting.danishHeatMeterReport.oldNewMetersReport' | translate}}
                    </h6>
                </div>
                <div class="w-20"></div>
            </div>
            <br>
        </div>

        <div class="p-4">
            <div class="fw-bold">{{'fieldReporting.wiringReport.installationDone' | translate}}</div>
            <div class="d-flex">
                <div class="col-6 ">
                    <button id="yes-button" class="btn w-90"
                        [ngClass]="clickedButtonId === 1 ? 'btn-primary' : 'btn-secondary'"
                        (click)="changeButtonId(1)">{{'basic.yes' | translate}}</button>
                </div>
                <div class="col-6">
                    <button id="no-button" class="btn w-90"
                        [ngClass]="clickedButtonId === 2 ? 'btn-danger' : 'btn-secondary'"
                        (click)="changeButtonId(2)">{{'basic.no' | translate}}</button>
                </div>
            </div>
        </div>

        <div *ngIf="clickedButtonId === 1" class="p-4">

            <!-- Old Meter Report Section -->
            <div *ngIf="showOldMeterReport" class="p-4">
                <h2>Old meter report:</h2>
                <!-- Input for Heat meter number -->
                <div class="mb-3">
                    <span class="fw-bold" id="heat-meter-addon">{{'fieldReporting.danishHeatMeterReport.oldMeterNumber'
                        | translate}}</span>
                    <div>
                        <input type="text" class="form-control"
                            placeholder="{{'fieldReporting.danishHeatMeterReport.enterOldHeatMeterNumber' | translate}}"
                            [(ngModel)]="oldMeterNumber">
                    </div>
                </div>

                <!-- Dropdown for Placement report -->
                <div class="p-4" style="margin-left: -24px;">
                    <div class="fw-bold">
                        {{'fieldReporting.danishHeatMeterReport.placementReport' | translate}}
                    </div>
                    <select name="data" id="placementReport" class="form-select" [(ngModel)]="placementReport">
                        <option *ngFor="let option of placementReportOptions" [value]="option.value">
                            {{ option.label | translate }}
                        </option>
                    </select>
                </div>
                <div class="mb-3">
                    <span class="fw-bold">{{'fieldReporting.danishHeatMeterReport.kWh' | translate}}</span>
                    <div>
                        <input type="text" class="form-control"
                            placeholder="{{'fieldReporting.danishHeatMeterReport.enterKWh' | translate}}"
                            [(ngModel)]="oldkWh">
                    </div>
                </div>

                <div class="mb-3">
                    <span class="fw-bold">{{'fieldReporting.danishHeatMeterReport.m3Water' | translate}}</span>
                    <div>
                        <input type="text" class="form-control"
                            placeholder="{{'fieldReporting.danishHeatMeterReport.enterM3Water' | translate}}"
                            [(ngModel)]="oldm3Water">
                    </div>
                </div>

                <div class="mb-3">
                    <span class="fw-bold">{{'fieldReporting.danishHeatMeterReport.hours' | translate}}</span>
                    <div>
                        <input type="text" class="form-control"
                            placeholder="{{'fieldReporting.danishHeatMeterReport.enterHours' | translate}}"
                            [(ngModel)]="oldHours">
                    </div>
                </div>

                <div>
                    <div class="fw-bold">
                        {{'fieldReporting.danishHeatMeterReport.oldMeterOpenText' | translate}}</div>
                    <textarea rows="3" class="form-control"
                        placeholder="{{'fieldReporting.danishHeatMeterReport.enterOldMeterOpenText' | translate}}"
                        [(ngModel)]="oldOpenText"></textarea>
                </div>

                <div class="w-100 d-flex cursor-pointer" (click)="oldImgInput.click()">
                    <img src="assets/icons/kamera_blue.svg" class="w-10 camera-icon">
                    <p class="w-90 camera-text small">
                        {{'fieldReporting.danishHeatMeterReport.clickIconTakePicture' | translate}}
                    </p>
                    <input #oldImgInput type="file" accept="image/*" capture="environment" (change)="handleImageUpload($event, 'old')"
                        style="display:none;" />
                </div>

                <!-- Display all image thumbnails -->
                <div *ngFor="let image of oldImages">
                    <img class="thumbnail-img" [src]="image.img" height="50">
                </div>

                <!-- Display image count -->
                <div class="image-count" style="margin-left: 13px;">
                    {{ oldImages.length }}/4 {{'fieldReporting.danishHeatMeterReport.uploaded' | translate}}
                </div>

                <!-- Next Button to go to New Meter Report -->
                <div class="d-flex justify-content-center background-light p-4 w-100">
                    <button type="button" class="btn btn-primary w-90" [disabled]="!canSaveOldReport() || oldMeterReportSaved || isProcessing"
                        (click)="saveOldMeterReport()">
                        Next
                    </button>
                </div>
            </div>

            <!-- New Meter Report Section -->
            <div *ngIf="showNewMeterReport" class="p-4">
                <!-- New Meter Report Title -->
                <h2>{{'fieldReporting.danishHeatMeterReport.newMeterReport' | translate}}:</h2>

                <button (click)="startScanning()" class="green-button">Start QR Scanning</button>

                <div *ngIf="showQrScanner">
                    <div #qrReaderElement id="qr-reader" class="qr-reader"></div>
                </div>

                <!-- Input for Meter number -->
                <div class="mb-3">
                    <span class="fw-bold" id="new-meter-addon">{{'fieldReporting.danishHeatMeterReport.newMeterNumber' |
                        translate}}</span>
                    <div>
                        <input type="text" class="form-control"
                            placeholder="{{'fieldReporting.danishHeatMeterReport.enterOldHeatMeterNumber' | translate}}"
                            [(ngModel)]="newMeterNumber">
                    </div>
                </div>

                <!-- Other inputs -->
                <div class="mb-3">
                    <span class="fw-bold">{{'fieldReporting.danishHeatMeterReport.kWh' | translate}}</span>
                    <div>
                        <input type="text" class="form-control"
                            placeholder="{{'fieldReporting.danishHeatMeterReport.enterKWh' | translate}}"
                            [(ngModel)]="newkWh">
                    </div>
                </div>

                <div class="mb-3">
                    <span class="fw-bold">{{'fieldReporting.danishHeatMeterReport.m3Water' | translate}}</span>
                    <div>
                        <input type="text" class="form-control"
                            placeholder="{{'fieldReporting.danishHeatMeterReport.enterM3Water' | translate}}"
                            [(ngModel)]="newM3Water">
                    </div>
                </div>

                <div class="mb-3">
                    <span class="fw-bold">{{'fieldReporting.danishHeatMeterReport.hours' | translate}}</span>
                    <div>
                        <input type="text" class="form-control"
                            placeholder="{{'fieldReporting.danishHeatMeterReport.enterHours' | translate}}"
                            [(ngModel)]="newHours">
                    </div>
                </div>

                <!-- Checkbox for Antenna -->
                <div class="fw-bold mb-3 d-flex align-items-center">
                    <span style="margin-left: 10px;">{{'fieldReporting.danishHeatMeterReport.antenna' |
                        translate}}</span>
                    <input type="checkbox" class="outlined-checkbox" style="margin-left: 10px;" id="antennaCheckbox"
                        [(ngModel)]="antenna">
                </div>

                <div class="w-100 d-flex cursor-pointer" (click)="newImgInput.click()">
                    <img src="assets/icons/kamera_blue.svg" class="w-10 camera-icon">
                    <p class="w-90 camera-text small">
                        Click the icon to take a picture
                        <span *ngIf="imageMandatory">*</span>
                        <span *ngIf="imageOptional">(Optional)</span>
                    </p>
                    <input #newImgInput type="file" accept="image/*" capture="environment" (change)="handleImageUpload($event, 'new')"
                        style="display:none;" />

                </div>

                <!-- Display all image thumbnails -->
                <div *ngFor="let image of newImages">
                    <img class="thumbnail-img" [src]="image.img" height="50">
                </div>

                <!-- Display image count -->
                <div class="image-count" style="margin-left: 13px;">
                    {{ newImages.length }}/4 {{'fieldReporting.danishHeatMeterReport.uploaded' | translate}}
                </div>


                <!-- Previous and Save Buttons -->
                <div class="d-flex justify-content-center background-light p-4 w-100">
                    <button type="button" class="btn btn-secondary w-45 mr-2" (click)="goToOldMeterReport()">
                        Previous
                    </button>
                    <button type="button" class="btn btn-primary w-45" [disabled]="!canSaveNewReport()"
                        (click)="saveHeatMeterNewReport()">
                        {{'fieldReporting.wiringReport.saveReport' | translate}}
                    </button>
                </div>
            </div>
        </div>

        <hr *ngIf="clickedButtonId === 2">
        <div *ngIf="clickedButtonId === 2" class="p-4">
            <div class="fw-bold">{{'fieldReporting.wiringReport.interruptCode' | translate}}</div>
            <div ngbDropdown class="d-inline-block w-100">    
                <button class="btn btn-secondary w-100" id="dropdownInterrupt" ngbDropdownToggle>
                  <span *ngIf="!interruptCode">{{'fieldReporting.wiringReport.interruptCode' | translate}}</span>
                  <span *ngIf="interruptCode">{{interruptCode.definition}}</span>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownInterrupt" class="w-100">
                  <div *ngFor="let item of interruptOptions" ngbDropdownItem class="w-100 interrupt-option" (click)="setInterruptCode(item)">{{item.definition}}</div>
                </div>
            </div>
        </div>
        <div *ngIf="clickedButtonId === 2" class="p-4">
            <div class="fw-bold">
                {{'basic.reasonForInterruption' | translate}}
                <span *ngIf="isMandatory">*</span>
                <span *ngIf="isOptional">(Optional)</span>
            </div>
            <textarea type="text" rows="4" class="form-control"
                placeholder="{{'basic.reasonForInterruption' | translate}}" [(ngModel)]="interruptReason"
                [disabled]="!textareaEnabled">
            </textarea>
            <div class="w-100 d-flex cursor-pointer" (click)="interruptImgInput.click()">
                <img src="assets/icons/kamera_blue.svg" class="w-10 camera-icon">
                <p class="w-90 camera-text small">
                    {{'fieldReporting.danishHeatMeterReport.clickIconTakePicture' | translate}}
                    <span *ngIf="imageMandatory">*</span>
                    <span *ngIf="imageOptional">(Optional)</span>
                </p>
                <input #interruptImgInput type="file" accept="image/*" capture="environment" (change)="handleImageUpload($event, 'interrupt')"
                    style="display:none;" />

            </div>

            <!-- Display all image thumbnails -->
            <div *ngFor="let image of interruptImages">
                <img class="thumbnail-img" [src]="image.img" height="50">
            </div>
            <!-- Display image count -->
            <div class="image-count" style="margin-left: 13px;">
                {{ interruptImages.length }}/1 {{'fieldReporting.danishHeatMeterReport.uploaded' | translate}}
            </div>
            <div class="d-flex justify-content-center background-light p-4 w-100">
                <button type="button" class="btn btn-primary w-90" [disabled]="!canSaveInterruptedReport()"
                    (click)="saveHeatMeterInterruptedReport()">
                    {{'fieldReporting.wiringReport.saveReport' | translate}}
                </button>
            </div>
        </div>




    </div>
</div>