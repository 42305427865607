import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {Toast} from '@shared/models/toast';

@Injectable({providedIn: 'root'})

export class ToastService {

  items$: Observable<Toast[]> | undefined;
  toastArray = Array();

  // sendToast can be called as many times as wanted, it will queue up all toasts inside toastArray
  sendToast (success, description, d?) {
    let title
    let desc = description

    if(success) {
      title = 'toaster.success'
      desc = description
    } else {
      title  = 'toaster.error'
      desc = description
    }

    let data = {
      title: title,
      description: desc,
      success: success
    }
    this.toastArray.push(data)
    this.items$ = of(this.toastArray)
    this.toastTimer()
  }

  // We display a toast 6 seconds before removing it
  toastTimer() {
    if(this.toastArray.length > 0) {
      setTimeout(() =>
        {
          this.removeToast()
        },
        6000);
    }
  }

  // Called every time we want to remove a toast, which happens every 6 seconds if there is stuff in toastArray
  removeToast() {
    this.toastArray.splice(0, 1)
    this.items$ = of(this.toastArray)
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }
}
