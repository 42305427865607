<div class="page-container">
    <div class="page-content p-3">
        <h4>{{'planner.workordersWithoutCoordinates.title' | translate}}</h4>
        <div *ngIf="workorders.length === 0" class="red-text">{{'fieldReporting.dashboard.noWorkordersFound' | translate}}</div>
        <div *ngIf="workorders.length > 0 && !generateSpinner">
          <p class="gray-text">{{'planner.workordersWithoutCoordinates.infoText' | translate}}</p>
          <table class="table-striped table">
              <thead>
                  <th><div class="center"><input type="checkbox" class="form-check-input" [checked]="allSelected" (click)="toggleAllWorkorders()"></div></th>
                  <th>{{'basic.address' | translate}}</th>
                  <th>{{'basic.meterNumber' | translate}}</th>
                  <th>{{'basic.transformerID' | translate}}</th>
                  <th>{{'basic.imported' | translate}}</th>
                  <th>{{'basic.latitude' | translate}}</th>
                  <th>{{'basic.longitude' | translate}}</th>
              </thead>
              <tbody>
                  <tr *ngFor="let workorder of workorders | slice:workorderStart:workorderEnd" [ngClass]="workorder.selected ? 'background-light' : ''">
                      <td>
                          <!--
                              Need to send opposite value of workorder.selected to function, because function receives old value instead of new one.
                              One explanation could be that value is sent to function before it has been changed.
                          -->
                          <input type="checkbox" class="form-check-input" [(ngModel)]="workorder.selected" *ngIf="!workorder.coordinates.lat && !workorder.coordinates.lng" (click)="changeSelectedAmount(workorder, !workorder.selected)">
                      </td>
                      <td>{{workorder.address}}</td>
                      <td>{{workorder.old_device_id}}</td>
                      <td>{{workorder.ordergroup_id}}</td>
                      <td>{{workorder.created | date: 'dd.MM.YYYY'  }}</td>
                      <td>
                          <input placeholder="-" type="number" class="form-control max-width-input" (focus)="unselectCheckbox(workorder)" [(ngModel)]="workorder.coordinates.lat">
                      </td>
                      <td>
                          <input placeholder="-" type="number" class="form-control max-width-input" (focus)="unselectCheckbox(workorder)"  [(ngModel)]="workorder.coordinates.lng">
                      </td>
                      <td *ngIf="workorder.coordinates.lat && workorder.coordinates.lng">
                        <button type="button" class="btn btn-primary btn-sm me-1" (click)="updateCoordinatesSingle(workorder)">{{'basic.save' | translate}}</button>
                        <button type="button" class="btn btn-secondary btn-sm" (click)="clearCoordinatesSingle(workorder)">X</button>
                      </td>
                  </tr>
              </tbody>
          </table>
          <div *ngIf="selectedMetersAmount > 0">
              <p class="small-text gray-text">{{selectedMetersAmount}} {{'basic.meters' | translate}}</p>
              <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#generateConfirmModal">{{'planner.workordersWithoutCoordinates.generateCoordinates' | translate}}</button>
              <button type="button" class="btn btn-secondary  ms-1">{{'basic.delete' | translate}}</button>
              <div>
                  <img width="24" src="assets/icons/info_gray_filled.svg">
                  <span class="small-text gray-text ms-1">{{'planner.workordersWithoutCoordinates.additionalCosts' | translate}}</span>
              </div>
          </div>
          <div class="d-flex justify-content-center">
              <img class="p-2 chevron" src="assets/icons/chevron_left_gray_24dp.svg" (click)="changeWorkordersSet('previous')">
              <div class="d-flex flex-column px-2 align-items-center small-text">
                  <div>{{'basic.page' | translate}} {{currentPage}}/{{totalPages}}</div>
                  <div class="gray-text">{{lastWorkorder}}/{{totalWorkorders}}</div>
              </div>
              <img class="p-2 chevron" src="assets/icons/chevron_right_gray_24dp.svg" (click)="changeWorkordersSet('next')">
          </div>


          <!-- Barcode modal -->

          <div class="modal fade" id="generateConfirmModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered" style="max-height: 60vh;">
              <div class="modal-content background-modal">
                <div class="modal-body ">
                  <h4 class="text-center">{{'planner.workordersWithoutCoordinates.generatingText' | translate}}</h4>
                  <div id="selectedList">
                    <div class="d-flex mx-5 gray-text">{{'basic.address' | translate}}</div>
                    <ul class="list-group mx-5 mb-10 enableScroll">
                      <li class="list-group-item" *ngFor="let workorder of selectedworkorders">{{workorder.address}}</li>
                    </ul>
                  </div>
                  <div class="mx-5 mb-1 d-flex">
                    <button type="button" class="btn btn-sm btn-primary" data-bs-dismiss="modal" (click)="generateCoordinates()">{{'planner.workordersWithoutCoordinates.generateCoordinates' | translate}}</button>
                    <button type="button" class="btn btn-sm btn-secondary ms-2 col-6" data-bs-dismiss="modal">{{'basic.cancel' | translate}}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</div>
