
<header id="top-bar">
    <nav class="navbar navbar-expand">
          <a class="burger-btn d-block nav-toggler" id="burger-btn-toggle" (click)="toggleSidebar()">
            <img class="nav-toggler" src="assets/icons/menu_black_24dp.svg">
          </a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
              aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
          </button>
          <div class="media-query-enabled-project dropdown media-query-enabled-project-dropdown" id="project-dropdown">
                <span class="projectTitle">{{'topBar.project' | translate }}</span>
                <a href="#" data-bs-toggle="dropdown" aria-expanded="false">
                    <div class="project-menu ">
                        <span class="projectName" id="currentProject">{{currentProjectName}}</span>
                    </div>
                </a>
                <ul class="project-dropdown dropdown-menu dropdown-menu-end project-select" aria-labelledby="projectDropdownMenuButton">
                    <button class="btn btn-primary btn-create-project" 
                    [swal]="{title: 'You are creating a new project.', text:'Are you sure?', confirmButtonText:'Yes', showCancelButton: true}"
                    (confirm)="confirmNewProject()">
                    + {{'topBar.createNewProject' | translate}}</button>
                    <hr>
                    <li id="dropdownMenuItems" *ngFor="let item of projects | keyvalue">
                        <a [ngStyle]="currentProjectId === item.key ? {'background-color': '#F4F7FC', 'color': '#0058FF'} : null" class="dropdown-item"  (click)="switchProject(item.key)">{{item.value}}</a>
                    </li>
                </ul>
          </div>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
              </ul>
              <div class="dropdown">
                  <a href="#" data-bs-toggle="dropdown" aria-expanded="false">
                      <div class="user-menu d-flex circle">
                          <div class="user-name initials">
                              <h6 class="mb-0">{{ initials }}</h6>
                          </div>
                          <div class="user-img d-flex align-items-center">
                              <div class="avatar avatar-md">
                              </div>
                          </div>
                      </div>
                  </a>
                  <ul class="user-dropdown dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                        <li>
                            <h6 class="dropdown-header">{{'topBar.hello' | translate}}, {{ firstName }}!</h6>
                        </li>
                        <li>
                            <a class="dropdown-item" href="#">
                                <i class="icon-mid bi bi-person me-2"></i>
                                {{'topBar.profile' | translate }}
                            </a>
                        </li>
                        <li>
                            <a class="dropdown-item" href="#">
                                <i class="icon-mid bi bi-gear me-2"></i>
                                {{'basic.settings' | translate }}
                            </a>
                        </li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>
                        <li>
                            <a id="logout-button" (click)="logout()" class="dropdown-item" href="/login">
                                <i class="icon-mid bi bi-box-arrow-left me-2"></i>
                                {{'basic.logOut' | translate }}
                            </a>
                        </li>
                  </ul>
                </div>
            </div>
    </nav>
</header>
