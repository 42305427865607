import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FieldReportingService } from '@modules/field-reporting/services/field-reporting/field-reporting.service';
import { WorkOrderService } from '@modules/planner/services/workorder/work-order.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@shared/services/user/user.service';
import { Subject, takeUntil } from 'rxjs';
import Swal from 'sweetalert2';
import {DatePipe} from "@angular/common";
import { InterruptService } from '@shared/services/interrupt/interrupt.service';
import { Interrupt } from '@shared/models/interrupt';

@Component({
  selector: 'app-wiring-report',
  templateUrl: './wiring-report.component.html',
  styleUrls: ['./wiring-report.component.scss'],
  providers:[DatePipe]
})
export class WiringReportComponent implements OnInit {
reporterId: number | null = null
id: number = 0
address: string = ''
clickedButtonId: number = 0
interruptCode: Interrupt | null = null
interruptReason: string | null = null
componentDestroyed$: Subject<boolean> = new Subject()
interruptOptions: Interrupt[] = []

  constructor(
    private route: ActivatedRoute,
    private workorderService: WorkOrderService,
    private fieldReportingService: FieldReportingService,
    private userService: UserService,
    private translateService: TranslateService,
    private router: Router,
    private datePipe: DatePipe,
    private interruptService: InterruptService
    ) { }

  ngOnInit(): void {
    this.route.paramMap
    .subscribe(pmap =>  {
      let workorderID = pmap.get('id')
      if (workorderID) {
        this.id = parseInt(workorderID)
        this.getWiringInformation()
      } else Swal.fire('Workorder ID wrong in URL')
    })
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }

  /**
   * Get address for Wiring installation.
   * Address is shown in UI to user.
   * Get reporterID to send with report api-call.
   */
  getWiringInformation() {
    if (this.id !== 0 && typeof(this.id) === 'number') {
      this.workorderService.getWorkOrder(this.id)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          this.address = data.address
        }
      )
    }
    this.userService.getUserInfo()
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(
      data => {
        if (data.workers.length > 0) {
          // Reporter ID needs to be userId not id of user's table.
          this.reporterId = data.workers[0].userId
        }
      }
    )
    this.interruptService.getInterruptOptions().subscribe({
      next: data => {
        this.interruptOptions = data
      }
    })
  }

  /**
   * Check which button is clicked. Add status according to clicked button. Add interrupt reason if clicked button is 'No' button.
   * Else show error message to user.
   */
  saveWiringReport() {
    let reportData: any = {
      'attendee': this.reporterId,
      "attend_time": this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm', 'UTC')
    }
    if (this.clickedButtonId === 1) {
      reportData.status = 4
      this.sendReport(this.id, reportData)
    } else if(this.clickedButtonId === 2 && this.interruptCode) {
      reportData.status = 6
      reportData.interrupt_code = this.interruptCode.interrupt_code
      reportData.interrupt_reason = this.interruptReason
      this.sendReport(this.id, reportData)
    } else {
      Swal.fire(
        this.translateService.instant('fieldReporting.saveFailed'),
        this.translateService.instant('fieldReporting.saveFailedText'),
        'error'
      )
    }
  }

  /**
   * Api-call for report.
   * Navigate to dashboard listing after saving report to database.
   * @param id workorder ID
   * @param reportData JSON-object that contains data for report
   */
  sendReport(id: number, reportData: any) {
    const formData = new FormData()
    formData.append('reportdata',  JSON.stringify(reportData) as any)
    this.fieldReportingService.reportWorkorder(id, formData)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(
      result => {
        if(result) {
          Swal.fire({
            title: this.translateService.instant('fieldReporting.shared.swal.success'),
            text: this.translateService.instant('fieldReporting.report.swal.reportSuccessText'),
            icon: 'success',
            confirmButtonColor: '#3085d6',

          }).then((result) => {
            if (result.isConfirmed || result.isDismissed) {
              this.router.navigate(['field-reporting/dashboard'])
            }
          })
        } else {
          Swal.fire(
            this.translateService.instant('fieldReporting.report.swal.error'),
            this.translateService.instant('fieldReporting.shared.swal.tryAgain'),
            'error'
          )
        }
      }
    )
  }

  changeButtonId(buttonId: number) {
    if (buttonId !== this.clickedButtonId) this.clickedButtonId = buttonId
    else this.clickedButtonId = 0
  }

  /**
   * Navigate back to opened meter information.
   */
  navigateToOrderDetail() {
    const queryParamsValue: Params = { meterId: this.id };
    this.router.navigate(['field-reporting/dashboard'],{
      queryParams: queryParamsValue,
      queryParamsHandling: 'merge'
    })
  }

  checkDisabled() {
    if (this.clickedButtonId === 1) return {'disabled': false}
    else if (this.clickedButtonId === 2 && this.interruptCode) {
      if (this.interruptCode.comment_required && this.interruptReason) return {'disabled': false}
      else if (!this.interruptCode.comment_required) return {'disabled': false}
      else return {'disabled': true}
    } 
    else return {'disabled': true}
  }

}
