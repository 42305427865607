<div class="page-container">
  <div class="page-heading">
    <h3>{{'basic.adminManagement' | translate}}</h3>
  </div>
  <div class="page-content">
    <div class="card">
      <div class="card-body">
        <button type="button" class="btn btn-primary btn-lg me-2" (click)="navigate('workers')">{{'sideNavigation.titleWorkers' | translate}}</button>
        <button type="button" class="btn btn-primary btn-lg" (click)="navigate('teams')">{{'basic.teams' | translate}}</button>
      </div>
    </div>
  </div>
</div>
