<div id="field-reporting">
    <div id="field-reporting-header">
        <app-field-reporting-header></app-field-reporting-header>
    </div>
    <div id="mainview">
        <div class="toast-container position-fixed p-3">
            <!-- Then put toasts within -->
            <app-toast></app-toast>
        </div>
        <router-outlet></router-outlet>
    </div>
</div>
