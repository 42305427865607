import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseUrl } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { SpecifiedSlot } from '@shared/models/specified-slot';
import { ToastService } from '@shared/services/toast/toast.service';
import { catchError, map, Observable, of, throwError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CalendarsService {
  defaultmax: number = 150;

  constructor(
    private http: HttpClient,
    private toastService: ToastService,
    private translateService: TranslateService
  ) { }

  /**
   * Function used to generate calendar api call
   * @param calendar object that has stored data which we use in api call
   * @returns false if fails otherwise returns series of numbers
   */
  createCalendar(calendar) {

    let slots = JSON.stringify(calendar.slots)
    let data = {
      "name": calendar.name,
      "type": calendar.type,
      "startdate": calendar.startdate,
      "enddate": calendar.enddate,
      "slots": slots,
      "msa_id": calendar.msaId,
      "cleanup_id": calendar.cleanupCalendarId,
      "slot_max_default": calendar.slot_max_default
    }

    return this.http.post<any>(`${baseUrl}/api/calendars`, data)
      .pipe(
        map((data: any) => {
          this.toastService.sendToast(true, this.translateService.instant('services.createCalendarSuccess'))
          return data['message'];
        }),
        catchError(error => {
          //alert(error.error)
          return of(false)
        })
      )

  }

  updateCalendar(calendar, calendarId) {
    let newdataJSON = {
      "name": calendar.name,
      "startdate": calendar.startdate,
      "enddate": calendar.enddate,
      "msa_id": calendar.msaId,
      "cleanup_id": calendar.cleanupCalendarId,
      "slot_max_default": calendar.slot_max_default
    }

    let newdata = JSON.stringify(newdataJSON)


    this.http.patch<any>(`${baseUrl}/api/calendars/${calendarId}`, { newdata })
      .toPromise()
      .then(response => {
        this.toastService.sendToast(true, this.translateService.instant('services.updateCalendarSuccess'))
        return response
      })
      .catch(err => {
        //console.log(err);
        this.toastService.sendToast(false, err)
      });

  }

  updateCalendarSlotInformation(slot) {
    let disabledValue;
    if (slot.disabled == false) disabledValue = 0
    else disabledValue = 1


    let startTime = slot.timeStart.split(":")[0] + ":" + slot.timeStart.split(":")[1]
    let endTime = slot.timeEnd.split(":")[0] + ":" + slot.timeEnd.split(":")[1]

    let newdataJSON = {
      "starttime": slot.date + " " + startTime,
      "endtime": slot.date + " " + endTime,
      "disabled": disabledValue,
      "disabled_reason": slot.description,
      "slot_max": slot.slot_max
    }

    let newdata = JSON.stringify(newdataJSON)


    return this.http.patch(`${baseUrl}/api/calendarslots/${slot.id}`, { newdata })
      .pipe(
        map((data: any) => {
          return data['message'];
        }),
        catchError(() => {
          this.toastService.sendToast(false, this.translateService.instant('services.slotUpdateFailed'))
          return of(false)
        })
      )


  }

  createSlot(slot, calendarId) {
    let disabledValue;
    if (slot.disabled == false) disabledValue = 0
    else disabledValue = 1


    let data = {
      "calendar_id": calendarId,
      "starttime": slot.date + " " + slot.timeStart,
      "endtime": slot.date + " " + slot.timeEnd,
      "disabled": disabledValue,
      "disabled_reason": slot.description,
      "slot_max": slot.slot_max,
    }



    return this.http.post<any>(`${baseUrl}/api/calendarslots`, data)
      .pipe(
        map((data: any) => {
          return data['message'];
        }),
        catchError(error => {
          this.toastService.sendToast(false, this.translateService.instant('services.createSlotFailed'))
          return of(false)
        })
      )

  }

  removeSlot(slotId) {
    return this.http.delete<any>(`${baseUrl}/api/calendarslots/${slotId}`,)
      .pipe(
        map((data: any) => {
          //this.toastService.sendToast(true, 'Delete slot successful')
          return data['message'];
        }),
        catchError(error => {
          this.toastService.sendToast(false, this.translateService.instant('services.deleteSlotFailed'))
          return of(false)
        })
      )
  }

  getCalendars() {
    return this.http.get(`${baseUrl}/api/calendars`)
      .pipe(
        map((data: any) => {
          return data['message'];
        }),
        catchError(error => {
          //console.log(error)
          return of(false);
        })
      )
  }

  getCalendarById(id: number): Observable<any> {
    return this.http.get(`${baseUrl}/api/calendars/${id}`)
      .pipe(
        map((data: any) => {
          return data['message'];
        }),
        catchError(error => {
          //console.log(error)
          return of(false);
        })
      )
  }

  getCalendarsByWeekAndProjects(projects: number[], startDate: string | null, endDate: string | null): Observable<any> {
    let requestData: any = {
      projectIds: projects,
    };

    if (startDate !== null && endDate !== null) {
      requestData = {
        ...requestData,
        startDate: startDate,
        endDate: endDate,
      };
    }

    return this.http.post(`${baseUrl}/api/calendarWithWeekAndProject`, requestData)
      .pipe(
        map((response: any) => {
          if (response.status === 'success') {
            return response;
          } else {
            throw new Error(response.message);
          }
        }),
        catchError(error => {
          console.log(error);
          return of([]);
        })
      );
  }


  linkToWorkerteam(workerteamId, calendarId) {
    let data = {
      "calendar_id": calendarId,
      "workerteam_id": workerteamId,
    }

    return this.http.post<any>(`${baseUrl}/api/calendars/${calendarId}/${workerteamId}`, data)
      .pipe(
        map((data: any) => {
          this.toastService.sendToast(true, this.translateService.instant('services.linkCalendarToWorkerteamSuccess'))
          return data['message'];
        }),
        catchError(error => {
          //alert(error.error)
          return of(false)
        })
      )
  }

  unLinkToWorkerteam(workerteamId, calendarId) {
    return this.http.delete<any>(`${baseUrl}/api/calendars/${calendarId}/${workerteamId}`)
      .pipe(
        map((data: any) => {
          this.toastService.sendToast(true, this.translateService.instant('services.calendarRemovedFromWorkerteam'))
          return data['message'];
        }),
        catchError(error => {
          //alert(error.error)
          return of(false)
        })
      )
  }

  updateCalendarSlots(key, workorderId, message): any {

    if (message == 'delete') {
      return this.http.delete(`${baseUrl}/api/calendarslots/${key}/${workorderId}`)
        .pipe(
          map((data: any) => {
            this.toastService.sendToast(true, this.translateService.instant('services.deletedMarker'))
            return data;
          }),
          catchError(error => {
            return of(false)
          })
        )
    }
    else if (message == 'add') {
      let body = null
      return this.http.post(`${baseUrl}/api/calendarslots/${key}/${workorderId}`, body)
        .pipe(
          map((data: any) => {
            this.toastService.sendToast(true, this.translateService.instant('services.workOrderToSlot'))
            return data;
          }),
          catchError(error => {
            return of(false)
          })
        )
    } else if (message == 'update') {

    }

  }

  updateSlotMaxFill(id, maxFill): Observable<any> {
    if (maxFill > this.defaultmax) {
      maxFill = this.defaultmax
    } else if (maxFill == "") {
      maxFill = null
    }

    let newdataJSON = {
      "slot_max": maxFill
    }

    let newdata = JSON.stringify(newdataJSON)

    return this.http.patch(`${baseUrl}/api/calendarslots/${id}`, {
      newdata
    })
  }

  lockSlot(id, locked): Observable<any> {

    let newdataJSON = {
      "locked": locked
    }

    let newdata = JSON.stringify(newdataJSON)

    return this.http.patch(`${baseUrl}/api/calendarslots/${id}`, {
      newdata
    })
  }

  getProjectCalendars(projectId, startdate, enddate) {

    return this.http.get(`${baseUrl}/api/projectcalendars/${projectId}`,
      {
        params: {
          'startdate': startdate,
          'enddate': enddate
        }
      })

  }

  dateToYMD(date) {

    let yourDate = new Date(date)

    let month = yourDate.getMonth() + 1
    let newMonth = month.toString()

    if (month < 10) {
      newMonth = '0' + month
    }

    let day = yourDate.getDate()
    let newDay = day.toString()

    if (day < 10) {
      newDay = '0' + day
    }

    let dateString = yourDate.getFullYear() + '-' + newMonth + '-' + newDay

    return dateString.toString()
  }

  updateCalendarForwarded(calendarId, value) {
    let newdataJSON = {
      "forwarded": value
    }

    let newdata = JSON.stringify(newdataJSON)

    this.http.patch<any>(`${baseUrl}/api/calendars/${calendarId}`, { newdata })
      .toPromise()
      .then(response => {
        this.toastService.sendToast(true, this.translateService.instant('services.updateForwarded'))
        return response
      })
      .catch(err => {
        //console.log(err);
        this.toastService.sendToast(false, err)
      });

  }

  getMailingCSV(id, send = true) {
    return this.http.get(`${baseUrl}/api/mailingcsv/${id}`, { responseType: 'text', params: {
        'sendMessages': send
      } })
      .pipe(
        map((data: any) => {
          this.toastService.sendToast(true, this.translateService.instant('services.mailingFileDownloaded'))
          return data;
        }),
        catchError(this.handleError)
      )
  }

  getCallingCSV(id) {
    return this.http.get(`${baseUrl}/api/callingcsv/${id}`, { responseType: 'text' })
      .pipe(
        map((data: any) => {
          this.toastService.sendToast(true, this.translateService.instant('services.callingFileDownloaded'))
          return data;
        })
      )
  }

  getMultipleCallingCSV(ids: Array<any>) {
    return this.http.get(`${baseUrl}/api/multiplecallingcsv/${ids}`, { responseType: 'text'})
      .pipe(
        map((data: any) => {
          this.toastService.sendToast(true, this.translateService.instant('services.callingFileList'))
          return data;
        })
      )
  }

  getMultipleCallingList(ids: Array<any>, notConfirmedOnly: boolean = false) {
    let queryParams = '';
    if (notConfirmedOnly) {
      queryParams = '?notconfirmed=true';
    }

    return this.http.get(`${baseUrl}/api/multiplecallinglist/${ids}${queryParams}`, { responseType: 'json' })
      .pipe(
        map((data: any) => {
          return data;
        })
      )
  }



  getMultipleMailingCSV(ids: Array<any>, send = true) {
    return this.http.get(`${baseUrl}/api/multiplemailingcsv/${ids}`, { responseType: 'text', params: {
        'sendMessages': send
      } })
      .pipe(
        map((data: any) => {
          this.toastService.sendToast(true, this.translateService.instant('services.mailingFileList'))
          return data;
        }),
        catchError(this.handleError)
      )
  }

  getNotConfirmedCallingCSV(ids: Array<any>) {
    return this.http.get(`${baseUrl}/api/multiplecallingcsv/${ids}`,
      {
        params: {
          'notconfirmed': true,
        },
        responseType: 'text'
      }
    )
      .pipe(
        map((data: any) => {
          this.toastService.sendToast(true, this.translateService.instant('services.callingFileList'))
          return data;
        })
      )
  }

  getAlternativeTimeslots(workorderId: string): Observable<any> {
    return this.http.get(`${baseUrl}/api/calendarslots/alternativetimeslots/${workorderId}`)
      .pipe(
        map((data: any) => {
          console.log(data.message)
          return data;

        })
      );
  }

  getCalendarSpecifiedSlots(calendarId: number, startTime: string, endTime: string): Observable<SpecifiedSlot[]> {
    if (calendarId && startTime && endTime) {
      let params = new HttpParams()
        .set('start', startTime)
        .set('end', endTime);

      return this.http.get<SpecifiedSlot[]>(`${baseUrl}/api/calendarSlotsByCalendar/${calendarId}`, { params })
        .pipe(
          map(data => {
            return data['message'];
          }),
          catchError(error => {
            this.toastService.sendToast(false, 'Error: ' + error.toString())
            return throwError(() => new Error('An error occurred while fetching calendar slots; please try again later.'));
          })
        );
    } else {
      // Directly returning an Observable error if the input validation fails
      this.toastService.sendToast(false, 'Invalid input parameters')
      return throwError(() => new Error('Invalid input parameters'));
    }
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = '';
  
    if (error.error instanceof ErrorEvent) {
      // Client-side or network error
      errorMessage = `Client-side error: ${error.error.message}`;
    } else {
      // Backend error
      try {
        const errorObj = JSON.parse(error.error);
        errorMessage = `Backend error: ${errorObj.message}`;
      } catch (e) {
        errorMessage = `Backend error: ${error.message}`;
      }
    }
    return throwError(() => new Error(errorMessage));
  }
  
  








}
