import { Component, OnDestroy, OnInit } from '@angular/core';
import { WorkOrderService } from '@modules/planner/services/workorder/work-order.service';
import { ActivatedRoute } from '@testing/activated-route.stub';
import { Subject, takeUntil } from 'rxjs';
import { Params, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { FieldReportingService } from '@modules/field-reporting/services/field-reporting/field-reporting.service';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import imageCompression from 'browser-image-compression';
import { DomSanitizer } from '@angular/platform-browser';
import { QueueService } from '../../services/queue.service';
import { UserService } from '@shared/services/user/user.service';
import { InterruptService } from '@shared/services/interrupt/interrupt.service';
import { Interrupt } from '@shared/models/interrupt';


declare const L: any;
@Component({
  selector: 'app-german-electric-meter-report',
  templateUrl: './german-electric-meter-report.component.html',
  styleUrls: ['./german-electric-meter-report.component.scss']
})

export class GermanElectricMeterReport implements OnInit, OnDestroy {

  // Barcode scanner


  clickedButtonId: number = 0

  componentDestroyed$: Subject<boolean> = new Subject()

  newMeterNr: any
  newMeterReading: any
  installationTime: any
  installationDate: Date = new Date()
  installationDateToApi: any
  installHours: any
  installMinutes: any
  additionalInfo: String = ""
  switchedDate: any
  switchedTime: any
  dirtyTime = false
  timeUntouched = true
  newMeterOnSave = false;
  saveAdditional = false;
  secondTabDisabled = true;
  thirdTabDisabled = true;
  showOtherWorkType: boolean = false
  otherWorkType: string | null = null

  firstTabImgCheck = false
  secondTabImgCheck = false
  thirdTabImgCheck = false

  //german stuff
  interruptCode: Interrupt | null = null
  interruptReason: string | null = null

  // Indicates if the textarea is mandatory
  isMandatory: boolean = false;
  // Indicates if the textarea is enabled
  textareaEnabled: boolean = false;
  isOptional: boolean = false;

  imageMandatory: boolean = false; // Indicates if the image upload is mandatory
  imageOptional: boolean = false; // Indicates if the image upload is optional

  id: string | null = null
  accessTypeInfo = ''
  accessType: number | null = null
  story = ''
  placementText = ''

  images: Array<any> = [];

  saving = false;
  reporterId: number | null = null

  interruptOptions: Interrupt[] = []



  constructor(
    private workorderService: WorkOrderService,
    private route: ActivatedRoute,
    private router: Router,
    private fieldReportingService: FieldReportingService,
    private datePipe: DatePipe,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer,
    private queueService: QueueService,
    private userService: UserService,
    private interruptService: InterruptService
  ) { }

  // Get meter id from url
  ngOnInit(): void {
    this.route.paramMap.subscribe(pmap => {
      this.id = pmap.get('id')
      this.getMeterInformation(this.id)
      this.getUserInfo()
      this.getInterruptions()
    })
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }

  getUserInfo() {
    this.userService.getUserInfo().subscribe(
      data => {
        if (data.workers.length > 0) {
          this.reporterId = data.workers[0].userId
        }
      }
    )
  }


  /**
   * 15.12.2022
   * Set limitations for image compression.
   * Then call imageCompression api from browser-image-compression library.
   * Use end result in images array.
   * @param event Allows to select file with catching event parameter
   * @param index What index of image we are using
   * @author Jesse Lindholm
   */
  async handleImageUpload(event) {
    const imageFile = event.target.files[0];
    if (!imageFile) return;

    const options = {
      maxSizeMB: 3,
      maxWidthOrHeight: 800,
      useWebWorker: true
    };

    try {
      await imageCompression(imageFile, options).then(output => {
        const imageObject = {
          data: output,
          img: this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(output))
        };
        this.images.push(imageObject);
      });
    } catch (error) {
      // Handle the error as needed
    }

    event.target.value = '';  // Reset the input to ensure it triggers a change event next time even if the same image is selected
  }

  // Get meter information with correct id
  getMeterInformation(meterId) {
    this.workorderService.getWorkorderWithOptions(meterId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          if (data.notes) this.additionalInfo = data.notes

          if (data.time_window_start) {
            this.installationDateToApi = new Date(data.time_window_start)
            let starttime = data.time_window_start
            starttime = starttime.replace(' ', 'T')
            this.installationDateToApi = new Date(starttime)

            this.installHours = this.datePipe.transform(this.installationDateToApi, 'HH')
            this.installMinutes = this.datePipe.transform(this.installationDateToApi, 'mm')
          } else {
            this.installHours = '00'
            this.installMinutes = '00'
          }

          if (data.access_type) {
            this.accessType = data.access_type;
          }
          if (data.access_type_notes) {
            this.accessTypeInfo = data.access_type_notes;
          }
          if (data.story) {
            this.story = data.story;
          }
          if (data.placement) {
            this.accessTypeInfo = data.placement;
          }
        }
      )
  }

  // Back to dashboard function that we use in first tab if user presses back button.
  // Set meter id to queryParams so we can access it in dashboard component directly from url
  backToDashboard() {
    const queryParamsValue: Params = { queuedWorkOrderId: this.id };
    this.router.navigate(['field-reporting/dashboard'], {
      queryParams: queryParamsValue,
      queryParamsHandling: 'merge'
    })
  }


  interrupt() {
    let url = '/field-reporting/interrupt/' + this.id
    this.router.navigate([url])
  }

  async saveReport() {
    this.isOnline().then(isConnected => {
        this.saving = true;
        if (isConnected) {
            console.log("Internet connection is available. Sent to server!");
            this.saveWaterMeterReport();
        } else {
            if (this.id === null) {
                // Handle the null case, e.g., show an error or use a default value
                console.error('Work order ID is null');
                return;
            }
            console.log("No internet connection. Added to local queue!");

            const jsonData = this.prepareReportData();
            let reportType = this.clickedButtonId === 1 ? 'waterMeter' : 'interrupt';

            // Process images and add to queue
            const { metadata, files } = this.queueService.processWaterMeterImages(this.images, reportType);
            this.queueService.addToQueue(jsonData, this.id, reportType, this.images);
            this.backToDashboard();
            Swal.fire("Workorder added to offline queue");
        }
    });
}

  isOnline() {
    return fetch('https://www.google.com', {
      method: 'HEAD',
      mode: 'no-cors'
    })
      .then(() => true)
      .catch(() => false);
  }

  prepareReportData(): any {
    let status = 4;
    if (this.clickedButtonId == 2) {
        status = 6;
    }

    let interrupt: string | null = null
    if (this.interruptCode) interrupt = this.interruptCode.interrupt_code

    return {
        "access_type": this.accessType,
        "access_type_notes": this.accessTypeInfo,
        "story": this.story,
        "placement": this.placementText,
        "status": status,
        "notes": this.additionalInfo,
        "attend_time": this.datePipe.transform(this.installationDateToApi, 'yyyy-MM-dd HH:mm', 'UTC'),
        "interrupt_reason": this.interruptReason,
        "interrupt_code": interrupt,
        "attendee": this.reporterId
    };
}


  /**
   * Prepares formData from jsonObjects and sends it to backend
   *
   * @edit 14.09.2023
   * Added new logic to pictures
   * Now uses "images" array instead of single "img0data" and "img1data"
   * @author Victor Påwals
   */
  saveWaterMeterReport() {
    const formData = new FormData();


    let jsonData = this.prepareReportData();

    // Dynamically generate image data based on the available images.
    let imageData: ImageData[] = [];

    for (let i = 0; i < this.images.length; i++) {
      if (this.images[i] && this.images[i].data && this.images[i].data.name) {
        const imagePlacePrefix = (this.clickedButtonId === 1) ? 'reported_' :
          (this.clickedButtonId === 2) ? 'interrupted_' : '';
        imageData.push({
          "image_place": imagePlacePrefix + i,
          "title": this.images[i].data.name,
          "image_key": 'img' + i
        });
        formData.append('img' + i, this.images[i].data as any);
      }
    }


    formData.append('images', JSON.stringify(imageData) as any);
    formData.append('reportdata', JSON.stringify(jsonData) as any);

    this.fieldReportingService.reportWorkorder(this.id, formData)
      .subscribe(
        result => {
          if (result) {
            Swal.fire({
              title: this.translateService.instant('fieldReporting.shared.swal.success'),
              text: this.translateService.instant('fieldReporting.report.swal.reportSuccessText'),
              icon: 'success',
              confirmButtonColor: '#3085d6',
            }).then((result) => {
              if (result.isConfirmed || result.isDismissed) {
                this.backToDashboard();
              }
            });
          } else {
            Swal.fire(
              this.translateService.instant('fieldReporting.report.swal.error'),
              this.translateService.instant('fieldReporting.shared.swal.tryAgain'),
              'error'
            );
          }
        }
      );
  }



  canSave(): boolean {
    if (this.clickedButtonId == 1) {
      if (this.images && this.images.length > 0 && this.images[0].img) {
        return true;
      } else {
        return false;
      }
    } else {
      // First check: if no interruptCode is selected, you can't save.
      if (this.interruptCode === null) {
        return false;
      }

      // Check if comment is mandatory and is filled out
      const isCommentFilled = !this.isMandatory || (this.isMandatory && this.interruptReason && this.interruptReason.trim() !== '');

      // Check if image is mandatory and is uploaded
      const isImageUploaded = !this.imageMandatory || (this.imageMandatory && this.images && this.images.length > 0 && this.images[0].img);

      return isCommentFilled && isImageUploaded;
    }
  }


  changeButtonId(buttonId: number) {
    if (buttonId !== this.clickedButtonId) this.clickedButtonId = buttonId
    else this.clickedButtonId = 0
  }

  getInterruptions() {
    this.interruptService.getInterruptOptions().subscribe({
      next: data => {
        this.interruptOptions = data
      }
    })
  }

  setInterruptCode(item) {
    this.interruptCode = item
    if (item.comment_required) {
      this.isMandatory = true
      this.isOptional = false
      this.textareaEnabled = true
    } else {
      this.isMandatory = false
      this.isOptional = true
      this.textareaEnabled = true
    }

    if (item.image_required) {
      this.imageMandatory = true
      this.imageOptional = false
    } else {
      this.imageMandatory = false
      this.imageOptional = true
    }
  }



}

interface ImageData {
  image_place: string;  // Note the change to string type here
  title: string;
  image_key: string;
}
