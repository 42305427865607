import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseUrl } from '@environments/environment';
import { catchError, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MsasService {

  constructor(
    private http: HttpClient
    ) { }

  getFreeOrdersMsa(msaId) {
  
    return this.http.get<any>(`${baseUrl}/api/msafreeorders/${msaId}`)
    .pipe(
      map((data: any) => {
        return data['message'];
      }),
      catchError(error => {
        //alert(error.error)
        return of(false)
      })
    )
  }

}
