<div class="bg-white p-4">
    <div class="d-flex justify-content-between">
        <div>
            <button type="button" (click)="goToDashboard()" class="btn btn-secondary back-button.">
                <img class="back-icon" src="assets/icons/chevron_left_gray_24dp.svg">
                <span class="back-text">{{'basic.back' | translate }}</span>
            </button>
        </div>
        <div class="d-flex mt-auto">
            <div [ngClass]="tab == 1 ? 'blue-select' : 'gray-select'" (click)="switchTab(1)">
                {{'call-service.shared.searchCustomers' | translate }}</div>
            <div [ngClass]="tab == 2 ? 'blue-select' : 'gray-select'" class="ms-2" (click)="switchTab(2)">
                {{'basic.logOut' | translate }}</div>
        </div>
    </div>
    <div class="mt-4">
        <h5 [hidden]="tab != 1">{{'basic.selectNewTimeSlot' | translate }}</h5>
        <button *ngIf="!done" [hidden]="tab != 1" class="btn interrupt-button btn-sm float-end" data-bs-toggle="modal"
            data-bs-target="#interruptModal" (mouseenter)="changeInterruptIcon('white')"
            (mouseleave)="changeInterruptIcon('red')">
            <img [src]="interruptIcon">
            <span class="ms-1 interrupt-text">{{'basic.interrupt' | translate }}</span>
        </button>
        <div *ngIf="tab === 1 && interrupted" class="red-text small-text">
            <div class="fw-bold">{{'call-service.interrupted' | translate }}</div>
            <div><span class="capitalize">{{'planner.reportWorkorderDetail.by' | translate}}</span>:  {{interruptor}}</div>
            <div>{{interruptedDate | date: 'dd.MM.YYYY HH:mm'}}</div>
            <div *ngIf="interruptCodeDefinition">
                {{ interruptCodeDefinition }}
            </div>
            <div *ngIf="!interruptCodeDefinition">
                {{ 'call-service.singleEnduser.interruptCodeMissing' | translate }}
            </div>
            <div>{{interruptedReason}}</div>
        </div>
        <h5 [hidden]="tab != 2">{{'basic.logOut' | translate }}</h5>
        <button [hidden]="tab != 2" class="btn btn-primary mt-2" (click)="logout()">{{'basic.logOut' | translate
            }}</button>
    </div>
    <div [hidden]="tab != 1" class="small-text">
        <div class="row mt-4">
            <div class="col-3">
                <div class="gray-text">{{'call-service.customersInformation' | translate }}</div>
                <load-spinner [hidden]="!workorderSpinner"></load-spinner>
                <div id="enduserList" [hidden]="workorderSpinner" *ngFor="let enduser of enduserList; let i = index">
                    <div class="blue-text">{{enduser.person_type_string | translate }}</div>
                    <div class="fw-bold">{{enduser.contact_person_first}} {{enduser.contact_person_last}}</div>
                    <div class="fw-bold">{{enduser.phone}}</div>
                    <div class="fw-bold" *ngIf="enduser.email">{{enduser.email}}</div>
                    <div *ngIf="!enduser.email">{{'basic.emailMissing' | translate}}</div>
                    <div class="mt-1 fw-bold">{{enduser.address}}</div>
                    <hr *ngIf="enduserList.length > 1 && i < enduserList.length - 1">
                </div>
            </div>
            <div class="col-3">
                <div class="gray-text">{{'basic.currentTimeSlot' | translate }}:</div>
                <load-spinner [hidden]="!workorderSpinner"></load-spinner>
                <div class="fw-bold"
                    [hidden]="workorderSpinner || !workorder.time_window_start || !workorder.time_window_end">
                    <span>{{workorder.time_window_start | date: 'dd.MM.YYYY' }}</span>
                    <span class="ms-1">{{workorder.time_window_start | date: 'HH:mm' }}</span>
                    <span class="mx-1">-</span>
                    <span>{{workorder.time_window_end | date: 'HH:mm' }}</span>
                </div>
                <div [hidden]="workorder.time_window_start && workorder.time_window_end || workorderSpinner"
                    class="red-text fw-bold">{{'call-service.singleEnduser.timeWindowsNotFound' | translate}}</div>
            </div>
            <div class="col-3">
                <div class="gray-text">{{'basic.metersInformation' | translate }}</div>
                <load-spinner [hidden]="!workorderSpinner"></load-spinner>
                <div [hidden]="workorderSpinner">
                    <div class="fw-bold">
                        <span>{{'basic.meterNumber' | translate }}: </span>
                        <span [hidden]="!workorder.old_device_id" id="old-device">{{workorder.old_device_id}}</span>
                        <span class="red-text" [hidden]="workorder.old_device_id">{{'call-service.oldDeviceIdNotFound' |
                            translate }}</span>
                    </div>
                    <div class="fw-bold">
                        <span>{{'basic.meteringPointNumber' | translate }}: </span>
                        <span [hidden]="!workorder.location_number"
                            id="location-number">{{workorder.location_number}}</span>
                        <span class="red-text"
                            [hidden]="workorder.location_number">{{'call-service.locationNumberNotFound' | translate
                            }}</span>
                    </div>
                    <div class="fw-bold">
                        <span>{{'basic.meteringPointAddress' | translate }}: </span>
                        <span [hidden]="!workorder.address" id="meter-address">{{workorder.address}}</span>
                        <span class="red-text" [hidden]="workorder.address">{{'call-service.meterAddressNotFound' |
                            translate }}</span>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="gray-text">{{'basic.accessType' | translate }}</div>
                <load-spinner [hidden]="!workorderSpinner"></load-spinner>
                <div [hidden]="workorderSpinner">
                    <div class="fw-bold">

                        <span [hidden]="!workorder.access_type" id="access_type">
                            <img [hidden]="workorder.access_type!=1" class="top-icon"
                                src="assets/icons/key_gray_24dp.svg">
                            <img [hidden]="workorder.access_type!=2" class="top-icon"
                                src="assets/icons/person_lock_gray_24dp.svg">
                            <img [hidden]="workorder.access_type!=3" class="top-icon"
                                src="assets/icons/lock_unlocked_gray_24dp.svg">
                            {{'accessTypes.'+workorder.access_type | translate }}
                        </span>
                        <span class="red-text" [hidden]="workorder.access_type">{{'call-service.accessTypeNotFound' |
                            translate }}</span>
                    </div>
                </div>
                <div class="gray-text mt-2">{{'basic.address' | translate}}</div>
                <div *ngIf="workorder.address" class="fw-bold">{{workorder.address}}</div>
                <div *ngIf="!workorder.address">-</div>
                <div class="gray-text mt-2">{{'basic.placement' | translate}}</div>
                <div class="fw-bold" *ngIf="workorder.placement">{{workorder.placement}}</div>
                <div *ngIf="!workorder.placement">-</div>
            </div>
            <div class="mt-4 row mb-4" *ngIf="workorder.interrupt_reason">
                <div class="col-4">
                    <div>{{'call-service.oldInterruptedReason' | translate }}</div>
                    <div class="fw-bold">{{workorder.interrupt_reason}}</div>
                </div>
                <div class="col-4">
                </div>
                <div class="col-4">
                </div>
            </div>
            <div class="mt-4 row mb-4">
                <div class="col-4">
                    <div class="gray-text">{{'call-service.phoneNumberContact' | translate }}</div>
                    <input type="tel" [(ngModel)]="phoneNumber" (ngModelChange)="checkPhoneNumber()"
                        class="form-control" rows="4" placeholder="{{'basic.phoneNumber' | translate}}">
                    <div [hidden]="!invalidPhoneNumber" class="red-text">{{'call-service.phoneNumberInvalid' | translate
                        }}</div>
                </div>
                <div class="col-4">
                    <div class="gray-text">{{'basic.additionalInformation' | translate }}</div>
                    <textarea class="form-control" [(ngModel)]="additionalInformation" rows="4"
                        placeholder="{{'basic.additionalInformation' | translate}}"></textarea>
                </div>
                <div class="col-4">
                    <div class="gray-text">{{'call-service.notesForInstaller' | translate }}</div>
                    <textarea class="form-control" [(ngModel)]="notesForInstaller" rows="4"
                        placeholder="{{'call-service.notesForInstaller' | translate}}"></textarea>
                </div>
            </div>
            <div class="mt-4 mb-4">
                <button [disabled]="isSaving" class="btn btn-success btn-sm mt-2"
                        (click)="saveInformation()">{{'call-service.confirmDataButton' | translate}}</button>
            </div>
        </div>
        <hr>
        <h6 *ngIf="!done">{{'call-service.freeTimeSlots' | translate }}</h6>
        <h6 *ngIf="done">{{'call-service.isDone' | translate }}</h6>
        <div class="d-flex" *ngIf="!done">
            <div class="col-5">
                <div class="d-flex">
                    <div class="col-3 position-relative">
                        <button type="button" class="btn btn-secondary btn-sm" (mouseenter)="showMainCalendar = true"
                            (mouseout)="showMainCalendar = false" [ngClass]="activeCalendar == 1 ? 'active' : ''"
                            [disabled]="activeCalendar == 0 || onlyCleanupCalendar"
                            (click)="switchCalendar(1)">{{'call-service.mainCalendar' | translate }}</button>
                        <div *ngIf="showMainCalendar && mainCalendar" class="calendar-info">
                            <div>{{'planner.mapView.starts' | translate}}: {{mainCalendar.startdate | date: 'dd.MM.yyyy'}}</div>
                            <div>{{'planner.mapView.ends' | translate}}: {{mainCalendar.enddate | date: 'dd.MM.yyyy'}}</div>
                        </div>
                    </div>
                    <div class="col-6 position-relative">
                        <button type="button" class="btn btn-secondary btn-sm" (mouseenter)="showCleanupCalendar = true"
                            (mouseout)="showCleanupCalendar = false" [ngClass]="activeCalendar == 2 ? 'active' : ''"
                            [disabled]="activeCalendar == 0 || cleanupCalendar == null"
                            (click)="switchCalendar(2)">{{'call-service.cleanupCalendar' | translate }}</button>
                        <div *ngIf="showCleanupCalendar && mainCalendar" class="calendar-info">
                            <div *ngIf="cleanupCalendar">{{'planner.mapView.starts' | translate}}: {{cleanupCalendar.startdate | date: 'dd.MM.yyyy'}}</div>
                            <div *ngIf="cleanupCalendar">{{'planner.mapView.ends' | translate}}: {{cleanupCalendar.enddate | date: 'dd.MM.yyyy'}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="position-week-picker">
                <app-week-picker (weekChange)="setCurrentWeekPicker($event)"
                    [changeWeekPickerDate]="changeWeekPickerDate"></app-week-picker>
            </div>
        </div>
        <load-spinner [hidden]="!calendarSpinner"></load-spinner>
        <div [hidden]="calendarSpinner" *ngIf="!done">
            <div [hidden]="weekHasSlots" class=" text-center red-text fw-bold">{{'call-service.noSlotsThisWeek' |
                translate }}</div>
            <div [hidden]="!weekHasSlots">
                <div class="d-flex mt-3 fw-bold">
                    <div *ngFor="let day of currentWeek" class="ms-2 width-slot">
                        <div class="d-flex justify-content-center">
                            <div>{{day.day}}</div>
                            <div class="ms-1">{{day.date | date: 'dd.MM.yyyy'}}</div>
                        </div>
                        <div class="flex-column fw-normal">
                            <div *ngFor="let slot of day.slots | sortByDate: 'starttime'">
                                <div class="mb-2 slot d-flex justify-content-center flex-column flex-md-row flex-wrap ps-2 pe-2"
                                    [ngClass]="determineSlotClass(slot)" (click)="changeSlotIfActive(slot)">
                                    <div class="d-flex align-items-center flex-wrap">
                                        <div>{{slot.starttime | date: 'HH:mm'}}</div>
                                        <div class="mx-1">-</div>
                                        <div>{{slot.endtime | date: 'HH:mm'}}</div>
                                    </div>
                                    <div class="mx-2 d-flex align-items-center" 
                                        [ngClass]="slot.active ? 'border-left-white' : 'border-left-gray'"></div>
                                    <div class="ms-1 d-flex align-items-center" 
                                        [ngClass]="slot.active ? 'white-text' : 'gray-text'">{{(slot.fill / slot.slot_max * 100) | number:'1.0-0'}}%</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div [hidden]="workorder.time_confirmed==null" class="fw-bold"><img class="mr-3"
                    src="/assets/icons/check_green_24dp.svg"> {{'call-service.alreadyConfirmed' | translate }}</div>
            <div [hidden]="workorder.time_confirmed==null" class="green-text">{{'call-service.timeslot' | translate }}
                {{workorder.time_window_start | date: 'dd.MM.yyyy HH:mm'}} - {{workorder.time_window_end | date:
                'HH:mm'}} {{'call-service.isConfirmed' | translate }}</div>
            <button [disabled]="isSaving" class="btn btn-success btn-sm mt-2"
                (click)="saveSlot()">{{'call-service.confirmTimeButton' | translate}}</button>
        </div>
        <hr>
        <!-- New Workorder log and Calling log sections -->
        <div class="row">
            <!-- Workorder log section -->
            <div class="col-md-4">
                <h5>{{'planner.reportWorkorderDetail.log' | translate}}</h5>
                <div *ngFor="let log of workorderLogs">
                    <div class="d-flex">
                        <div class="col-3 p-1 wrap">{{log.created | date: 'dd.MM.yyyy HH:mm'}}</div>
                        <div class="col-3 p-1 fw-bold wrap">{{log.description}}</div>
                        <div class="col-3 p-1 wrap">{{'planner.reportWorkorderDetail.by' | translate}} 
                            <span *ngIf="log.user_name">{{log.user_name}}</span>
                            <span *ngIf="!log.user_name">{{'basic.noDetail' | translate}}</span>
                        </div>
                        <div class="col-3 p-1 wrap">{{'planner.reportWorkorderDetail.userID' | translate}} 
                            <span *ngIf="log.user_id">{{log.user_id}}</span>
                            <span *ngIf="!log.user_id">{{'basic.noDetail' | translate}}</span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Calling log section -->
            <div class="col-md-4 border-x">
                <h6>{{'call-service.dashboard.callLog' | translate}}</h6>
                <button *ngIf="!callStatus" class="btn btn-primary" (click)="onLogACallClick()">{{
                    'call-service.logCall' | translate }}</button>
                <div *ngIf="callStatus">
                    <button class="btn btn-success" (click)="onAnsweredButtonClick()">{{
                        'call-service.callAnswered' | translate }}</button>
                    <button class="btn btn-danger mx-2" (click)="onDidNotAnswerButtonClick()">{{
                        'call-service.callDeclined' | translate }}</button>
                    <button class="btn btn-close" (click)="onCancelLogACallClick()">
                        <!-- Custom styled squared "x" button --></button>
                </div>
            </div>
            <div class="col-md-4">
                <h6>{{'call-service.messageLog' | translate}}</h6>
                <button type="button" class="btn btn-primary" (click)="sendMsgLog()">Log message</button><br>
                <div *ngFor="let log of messageLogs">
                    <div class="d-flex">
                        <div>{{'call-service.messageSent' | translate}}:</div>
                        <div class="ms-2">{{log.date | date: 'dd.MM.yyyy'}} {{log.time}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="interruptModal" tabindex="-1" aria-hidden="true" *ngIf="!done">
        <button [hidden]="true" data-bs-dismiss="modal" id="closeModal"></button>
        <div class="modal-dialog modal-md modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <h4>{{'basic.interrupt' | translate}}</h4>

                    <div class="p-4">
                        <div class="fw-bold">{{'fieldReporting.wiringReport.interruptCode' | translate}}</div>
                        <select name="data" id="interruptCode" class="form-select" [(ngModel)]="selectedInterrupt">
                            <option *ngFor="let option of interruptOptions" [value]="option.interrupt_code">
                                {{ option.definition }}
                            </option>
                        </select>
                    </div>

                    <div class="gray-text">{{'basic.reasonForInterruption' | translate }}</div>
                    <textarea [(ngModel)]="interruptedReasonSelect" class="form-control" rows="5"
                        placeholder="{{'basic.reasonForInterruption' | translate }}"></textarea><br>
                    <button type="button" class="btn btn-primary" (click)="saveInterruption()">{{'basic.save' |
                        translate }}</button>
                    <button type="button" class="btn btn-secondary ms-2" data-bs-dismiss="modal">{{'basic.cancel' |
                        translate }}</button>
                </div>
            </div>
        </div>
    </div>



</div>
