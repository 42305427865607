import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { catchError, map, mapTo, tap } from 'rxjs/operators';
import { baseUrl, integUrl } from '@environments/environment';
import { UserService } from '@shared/services/user/user.service';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import languages from 'assets/language-list/language-list.json'

@Injectable({
  providedIn: 'root'
})

/**
 * This class handles all backend calls that have something to do with user authentication.
 * Access tokens are also managed here.
 */
export class AuthService {

  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private readonly ROLE = 'ROLE';
  private readonly INTEGRATION_JWT_TOKEN = 'INTEGRATION_JWT_TOKEN';
  private readonly INTEGRATION_REFRESH_TOKEN = 'INTEGRATION_REFRESH_TOKEN';

  private loggedUser!: string;
  private roleAs!: string;

  languageList: string[] = languages.languages

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private router: Router,
    private translateService: TranslateService
  ) { }

  /**
   * Login method.
   * Since we receive an observable, we will use pipe method to apply some rxjs operations.
   * Tap takes the values from the stream and applies some values.
   * We map the value to true when doLoginUser() succeeds and in case of an error we display it to the user.
   * @param data
   * @returns
   */
  login(url, data): Observable<boolean> {
    return this.http.post<any>(url, data)
      .pipe(
        tap(loginData => {
          let language = loginData.language
          this.setLanguage(language)
          this.doLoginUser(data.email, loginData)
          if (loginData.role === 1 || loginData.role === 5) {
            this.doLoginIntegrationUser(data)
          }

        }),
        mapTo(true),
        catchError(error => {
          //alert(error.error)
          return of(false)
        })
      )
  }

  /**
   * 7.11.2022
   * Set language to UI.
   * @param language parameter language
   * @author Jesse Lindholm
   *
   * 16.11.2022
   * Go through list of JSON-languages and if database language for user and JSON-language matches, use it
   * If parameter "language" is not valid use English
   * @author Jesse Lindholm
   */
  setLanguage(language: string | null) {
    if (language) {
      for (let i = 0; i < this.languageList.length; i++) {
        const jsonLanguage = this.languageList[i];
        if (language === jsonLanguage) {
          this.translateService.use(language)
          localStorage.setItem('userlanguage', language);
          break
        }

      }
    } else {
      this.translateService.use('en')
      localStorage.setItem('userlanguage', 'en');
    }
  }

  /**
   * Login method.
   * Since we receive an observable, we will use pipe method to apply some rxjs operations.
   * Tap takes the values from the stream and applies some values.
   * We map the value to true when doLoginUser() succeeds and in case of an error we display it to the user.
   * @param data
   * @returns
   */
  endUserLogin(url, data): Observable<boolean> {
    return this.http.post<any>(url, data)
      .pipe(
        catchError(error => {
          //alert(error.error)
          return of(false)
        })
      )
  }

  /**
   * Register mehtod.
   * How will a user register? O.o
   * @param data
   * @returns
   */
  register(url, data): Observable<any> {
    return this.http.post(url, data)
  }

  /**
   * Logout method.
   * POST method to /api/logout
   * JWT gets passed along this call.
   * We map the value to true when doLogoutUser() succeeds and in case of an error we display it to the user.
   * @returns
   */
  logout() {
    if (this.getRole() === '1' || this.getRole() === '5') {
      this.http.post<any>(`${integUrl}/api/logout`, {
        'INTEGRATION_JWT_TOKEN': this.getIntegrationJwtToken()
      })
    }
    this.doLogoutUser()
    return this.http.post<any>(`${baseUrl}/api/logout`, {
      'JWT_TOKEN': this.getJwtToken()
    })
      .pipe(
        mapTo(true),
        catchError(error => {
          alert(error.error)
          return of(false)
        })
      )
  }

  /**
   * This method gets the JWT from a users storage and calls an other method to check if it's still valid.
   * @returns
   */
  isLoggedIn(refresh, route: ActivatedRouteSnapshot | null = null) {
    let token = this.getJwtToken()
    if (!token || this.tokenExpired(token.toString())) {
      if (refresh) {
        let rToken = this.getRefreshToken()
        this.refreshToken(rToken).subscribe(
          data => {
            this.storeToken(data)
            this.setRole(() => {
              if (route && route['_routerState'].url) this.router.navigate([route['_routerState'].url])
              else this.directByRole()
            })
            return true
          }
        )
      }

      // token expired
      return false
    } else if (token) {
      this.userService.getUserInfo().subscribe(
        userData => {
          this.setLanguage(userData.language)
        }
      )
      return true;
    } else {
      // token valid
      return true
    }
  }

  /**
   * A method for getting JWT from local storage.
   * @returns
   */
  getJwtToken() {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  /**
   * A method for getting REFRESH token from local storage.
   * @returns
   */
  getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  /**
   * A method for getting JWT from local storage.
   * @returns
   */
  getIntegrationJwtToken() {
    return localStorage.getItem(this.INTEGRATION_JWT_TOKEN);
  }

  /**
   * A method for getting REFRESH token from local storage.
   * @returns
   */
  getIntegrationRefreshToken() {
    return localStorage.getItem(this.INTEGRATION_REFRESH_TOKEN);
  }

  /**
   * A method for getting user role from local storage
   * @returns
   */
  getRole() {
    return localStorage.getItem(this.ROLE);
  }

  directByRole() {
    //TODO: Ohjataan käyttäjä takaisin urliin missä hän navigoi aiemmin
    let role = this.getRole() || ''
    if (role == '1') this.router.navigate(['/planner/human-resources'])
    else if (role == '2') this.router.navigate(['/field-reporting/dashboard'])
    else if (role == '3') this.router.navigate(['/call-service/dashboard'])
    else if (role == '5') this.router.navigate(['/planner/dashboard'])
  }

  /**
   * Called when we need to confirm the role of an authenticated user
   * @param cb
   */
  setRole(cb) {
    this.userService.getUserInfo().subscribe(
      data => {
        this.roleAs = data.role.toString()
        localStorage.setItem(this.ROLE, this.roleAs)

        // cb() = callback to let the other function know that this one is finished
        cb()
      }
    )
  }

  /**
   * Contains functionalities that we want after the user clicks logout.
   */
  doLogoutUser() {
    this.roleAs = ''
    this.loggedUser = ''
    this.removeToken()
  }

  /**
   * Contains functionalities that we want after the user authenticates with credentials.
   * @param email
   * @param token
   */
  private doLoginUser(username: string, loginData) {
    this.loggedUser = username
    this.roleAs = loginData.role
    this.storeToken(loginData)
  }

  /**
   * Contains functionalities that we want after the user authenticates with credentials.
   * @param email
   * @param token
   */
  private doLoginIntegrationUser(data) {
    return this.http.post(`${integUrl}/api/login`, data)
      .pipe(
        map((loginData) => {
          this.storeIntegrationToken(loginData)
        }),
        catchError(error => {
          //alert(error.error)
          return of(false)
        })
      ).subscribe()
  }

  private tokenExpired(token: string) {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    //TODO: Refresh token before token expires if user is active
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }

  /**
   * Stores the token in the users local storage.
   * @param token
   */
  storeToken(loginData) {
    if (loginData.access_token) {
      localStorage.setItem(this.JWT_TOKEN, loginData.access_token)
      localStorage.setItem(this.REFRESH_TOKEN, loginData.access_token)
      localStorage.setItem(this.ROLE, loginData.role)
    } else {
      this.router.navigate(['/'])
    }
  }

  /**
   * Stores the token in the users local storage.
   * @param token
   */
  storeIntegrationToken(integrationLoginData) {
    if (integrationLoginData.access_token) {
      localStorage.setItem(this.INTEGRATION_JWT_TOKEN, integrationLoginData.access_token)
      localStorage.setItem(this.INTEGRATION_REFRESH_TOKEN, integrationLoginData.access_token)
    } else {
      throw new Error("Error storing integration token")
    }
  }

  /**
   * A method for removing token from local storage.
   */
  private removeToken() {
    localStorage.removeItem(this.JWT_TOKEN)
    localStorage.removeItem(this.REFRESH_TOKEN)
    localStorage.removeItem(this.ROLE)
    localStorage.removeItem(this.INTEGRATION_JWT_TOKEN)
    localStorage.removeItem(this.INTEGRATION_REFRESH_TOKEN)
    localStorage.clear()
  }

  /**
   *
   * @returns
   */
  refreshToken(rToken) {
    // we only refresh the token if user clicked 'remember me'
    let remember = localStorage.getItem('remember');
    if (remember) {
      return this.http.post<any>(`${baseUrl}/api/refresh`, {
        Authorization: `Bearer ${rToken}`
      })
        .pipe(
          map((token) => {
            this.storeToken(token)
            return token
          }),
          catchError(error => {
            return of(false)
          })
        )
    } else {
      return of(false)
    }
  }
  /**
   *
   * @returns
   */
  refreshIntegrationToken() {
    return this.http.post<any>(`${integUrl}/api/refresh`, {
      Authorization: `Bearer ${this.getIntegrationRefreshToken()}`
    })
      .pipe(
        map((token) => {
          this.storeIntegrationToken(token)
          return token
        }),
        catchError(error => {
          return of(false)
        })
      )
  }

  forgotPassword(data): Observable<boolean> {
    return this.http.post<any>(`${baseUrl}/api/forgot`, data)
  }
}
