 <div id="map-nav" class="bg-light">
    <button type="button" id="map-nav-close-btn" class="btn-close right mt-3 mr-1" aria-label="Close" (click)="sendCloseNavClickToParent()"></button>
    <!-- Default mode START -->
    <div *ngIf="!msaMode" class="pt-3">
        <div [hidden]="!groupping" class="selected-group selected-child">
            <div *ngIf="identifier">
                <p class="gray-text">{{'planner.mapNav.selectedGroup' | translate }}:</p>
                <h3>{{'planner.mapNav.group' | translate }} {{identifier}}</h3>
            </div>
            <div *ngIf="!identifier">
                <h3>{{'planner.mapNav.notInGroup' | translate }}</h3>
            </div>
        </div>
    
        <div class="selected-group selected-child selected-h">
            <p class="gray-text">{{'planner.mapNav.selectedMeter' | translate }}:</p>
        </div>
        <div class="selected-meter selected-child">
            <div [hidden]="meterId == null" [ngStyle]="{'border': (groupStatus) ? '2px solid #0058ff' : '0px solid blue'}" class="meter-content">
                <div class="d-flex in-group-or-not">
                    <div class="nav-item-container">
                        <div [hidden]="groupStatus"><embed src="assets/icons/bolt_gray_24dp.svg" class="map-nav-icon"><div class="map-nav-item gray-text">{{'planner.mapNav.notInGroup' | translate }}</div></div>
                        <div [hidden]="!groupStatus"><embed src="assets/icons/bolt_blue_24dp.svg" class="map-nav-icon"><div class="map-nav-item gray-text">{{'planner.mapNav.group' | translate }} {{ groupId }}</div></div>
                    </div>
                </div>
                <div class="m-c-child">
                    <h5 id="meter-id">{{'basic.meter' | translate }} {{ meterId }}</h5>
                    <div id="edit-button" class="icon-right" (click)="switchEditMode()">
                        <img class="edit-btn" src="assets/icons/edit_gray_24dp.svg">
                    </div>
                </div>
                <div class="m-c-child nav-item-container">
                    <embed src="assets/icons/location_on_blue_24dp.svg" class="map-nav-icon">
                    <div *ngIf="!editMode" class="m-c-child-content edit-input map-nav-item">
                        <div *ngIf="address" class="gray-text">{{ address }}</div>
                        <div *ngIf="!address" class="gray-text">{{'basic.noInformation' | translate }}</div>

                    </div>
                    <div *ngIf="editMode"><span class="gray-text edit-input">{{'basic.address' | translate }}</span>
                        <br>
                        <textarea id="edit-address" class="m-c-child-content edit-input form-control" [(ngModel)]="address" (ngModelChange)="onChange($event, 'address')" placeholder="Address">
                        </textarea>
                    </div>
                </div>
                <div id="contact-list" class="m-c-child nav-item-container" *ngFor="let user of contactList | keyvalue; let last = last;">
                    <embed src="assets/icons/person_blue_24dp.svg" class="map-nav-icon">
                    <div *ngIf="!editMode" class="m-c-child-content edit-input gray-text">
                        <span class="blue-text">{{ user.value[0] | translate }}</span>
                        <br>
                        {{ user.value[1] }}
                        <br>
                        {{ user.value[2] }}
                        <br>
                        {{ user.value[3] }}
                        <br> 
                        {{ user.value[4] }}
                        
                        <div *ngIf="!ownerExists && last">
                            <br>
                            <embed src="assets/icons/person_blue_24dp.svg" class="map-nav-icon margin-left-fix">
                            <span class="blue-text">{{'persontype.0' | translate}}</span><br>
                            <span class="gray-text">{{'basic.noInformation' | translate }}</span>
                            </div>  
                        <div *ngIf="!residentExists && last">
                            <br>
                            <embed src="assets/icons/person_blue_24dp.svg" class="map-nav-icon margin-left-fix">
                            <span class="blue-text">{{'persontype.2' | translate}}</span><br>
                            <span class="gray-text">{{'basic.noInformation' | translate }}</span>
                            </div>        
                    </div>
                    <div *ngIf="editMode">
                        <span class="gray-text edit-input">{{'basic.contact' | translate }} ({{ user.value[0] | translate }})</span>
                        <br>
                        <input id="edit-contact-first" class="m-c-child-content edit-input contact-input form-control" [(ngModel)]="user.value[1]" (ngModelChange)="onChange($event, 'enduserFirstName')">
                        <input id="edit-contact-last" class="m-c-child-content edit-input contact-input form-control" [(ngModel)]="user.value[2]" (ngModelChange)="onChange($event, 'enduserLastName')">
                        <input id="edit-phone" class="m-c-child-content edit-input contact-input form-control" [(ngModel)]="user.value[3]" (ngModelChange)="onChange($event, 'enduserNumber')">
                        <input id="edit-address" class="m-c-child-content edit-input contact-input form-control" [(ngModel)]="user.value[4]" (ngModelChange)="onChange($event, 'enduserAddress')">
                        <div *ngIf="!ownerExists && last">
                            <br>
                            <embed src="assets/icons/person_blue_24dp.svg" class="map-nav-icon">
                            <span class="gray-text edit-input">{{'basic.contact' | translate}} ({{'persontype.0' | translate}})</span>
                            <input class="m-c-child-content edit-input contact-input form-control" [(ngModel)]="owner.firstName" (ngModelChange)="onChange($event, null)" placeholder="First name">
                            <input class="m-c-child-content edit-input contact-input form-control" [(ngModel)]="owner.lastName" (ngModelChange)="onChange($event, null)" placeholder="Last name">
                            <input class="m-c-child-content edit-input contact-input form-control" [(ngModel)]="owner.phoneNumber" (ngModelChange)="onChange($event, null)" placeholder="Phone number">
                            <input class="m-c-child-content edit-input contact-input form-control" [(ngModel)]="owner.address" (ngModelChange)="onChange($event, null)" placeholder="Address">
                        </div>
                        <div *ngIf="!residentExists && last">
                            <br>
                            <embed src="assets/icons/person_blue_24dp.svg" class="map-nav-icon">
                            <span class="gray-text edit-input">{{'basic.contact' | translate}} ({{'persontype.2' | translate}})</span>
                            <input class="m-c-child-content edit-input contact-input form-control" [(ngModel)]="resident.firstName" (ngModelChange)="onChange($event, null)" placeholder="First name">
                            <input class="m-c-child-content edit-input contact-input form-control" [(ngModel)]="resident.lastName" (ngModelChange)="onChange($event, null)" placeholder="Last name">
                            <input class="m-c-child-content edit-input contact-input form-control" [(ngModel)]="resident.phoneNumber" (ngModelChange)="onChange($event, null)" placeholder="Phone number">
                            <input class="m-c-child-content edit-input contact-input form-control" [(ngModel)]="resident.address" (ngModelChange)="onChange($event, null)" placeholder="Address">
                        </div>
                    </div>
                </div>
                <div *ngIf="contactList.length == 0">
                    <ng-container *ngIf="!editMode">
                        <div class="m-c-child-content nav-item-container mt-3">
                            <embed src="assets/icons/person_blue_24dp.svg" class="map-nav-icon">
                            <div class="edit-input">
                                <span class="blue-text">{{'persontype.0' | translate}}</span><br>
                                <span class="gray-text">{{'basic.noInformation' | translate }}</span>
                            </div>
                        </div>
                        <div class="m-c-child-content nav-item-container mt-3">
                           <embed src="assets/icons/person_blue_24dp.svg" class="map-nav-icon">
                            <div class="edit-input">
                                <span class="blue-text">{{'persontype.2' | translate}}</span><br>
                                <span class="gray-text">{{'basic.noInformation' | translate }}</span>
                            </div> 
                        </div>
                    </ng-container>
                    <div *ngIf="editMode">
                        <embed src="assets/icons/person_blue_24dp.svg" class="map-nav-icon">
                        <span class="edit-input gray-text">{{'basic.contact' | translate}} ({{'persontype.0' | translate}})</span>
                        <br>
                        <input id="owner-first-no-contact" class="m-c-child-content edit-input contact-input form-control" [(ngModel)]="owner.firstName" (ngModelChange)="onChange($event, null)" placeholder="First name">
                        <input id="owner-last-no-contact" class="m-c-child-content edit-input contact-input form-control" [(ngModel)]="owner.lastName" (ngModelChange)="onChange($event, null)" placeholder="Last name">
                        <input id="owner-phone-no-contact" class="m-c-child-content edit-input contact-input form-control" [(ngModel)]="owner.phoneNumber" (ngModelChange)="onChange($event, null)" placeholder="Phone number">
                        <input id="owner-address-no-contact" class="m-c-child-content edit-input contact-input form-control" [(ngModel)]="owner.address" (ngModelChange)="onChange($event, null)" placeholder="Address">
                        <br>
                        <embed src="assets/icons/person_blue_24dp.svg" class="map-nav-icon">
                        <span class="edit-input gray-text">{{'basic.contact' | translate}} ({{'persontype.2' | translate}})</span>
                        <br>
                        <input id="resident-first-no-contact" class="m-c-child-content edit-input contact-input form-control" [(ngModel)]="resident.firstName" (ngModelChange)="onChange($event, null)" placeholder="First name">
                        <input id="resident-last-no-contact" class="m-c-child-content edit-input contact-input form-control" [(ngModel)]="resident.lastName" (ngModelChange)="onChange($event, null)" placeholder="Last name">
                        <input id="resident-phone-no-contact" class="m-c-child-content edit-input contact-input form-control" [(ngModel)]="resident.phoneNumber" (ngModelChange)="onChange($event, null)" placeholder="Phone number">
                        <input id="resident-address-no-contact" class="m-c-child-content edit-input contact-input form-control" [(ngModel)]="resident.address" (ngModelChange)="onChange($event, null)" placeholder="Address">
                    </div>
                </div>
                <div class="m-c-child nav-item-container">
                    <embed src="assets/icons/info_blue_24dp.svg" class="map-nav-icon">
                    <div *ngIf="!editMode" class="m-c-child-content map-nav-item gray-text">
                        <span *ngIf="notes">{{ notes }}</span>
                        <span *ngIf="!notes">{{'basic.noInformation' | translate }}</span>
                    </div>
                    <div *ngIf="editMode"><span class="gray-text edit-input">{{'basic.info' | translate }}</span><br>
                        <textarea id="edit-notes" class="m-c-child-content edit-input form-control" [(ngModel)]="notes" (ngModelChange)="onChange($event, 'notes')">
                        </textarea>
                    </div>
                </div>
                <div class="m-c-child nav-item-container">
                    <embed src="assets/icons/numbers_blue_24dp.svg" class="map-nav-icon">
                    <div *ngIf="!editMode" class="m-c-child-content map-nav-item gray-text">
                        <div *ngIf="locationNumber">{{ locationNumber }}</div>
                        <div *ngIf="!locationNumber">{{'basic.noInformation' | translate }}</div>
                    </div>
                    <div *ngIf="editMode"><span class="gray-text edit-input">{{'basic.locationNumber' | translate}}</span><br>
                        <input class="m-c-child-content edit-input form-control" type="text" placeholder="Location number" [(ngModel)]="locationNumber" (ngModelChange)="onChange($event, 'locationNumber')">
                    </div>
                </div>
                <div class="m-c-child nav-item-container">
                    <embed src="assets/icons/old_meter_icon.svg" class="map-nav-icon">
                    <div *ngIf="!editMode" class="m-c-child-content map-nav-item gray-text">
                        <span class="blue-text">{{'planner.reportWorkorderDetail.oldMeterID' | translate}}</span><br>
                        <div *ngIf="oldMeterId">{{ oldMeterId }}</div>
                        <div *ngIf="!oldMeterId">{{'basic.noInformation' | translate }}</div>
                    </div>
                    <div *ngIf="editMode"><span class="gray-text edit-input">{{ oldMeterId }}</span><br>
                        <textarea id="edit-notes" class="m-c-child-content edit-input form-control" [(ngModel)]="notes" (ngModelChange)="onChange($event, 'notes')">
                        </textarea>
                    </div>
                </div>
                <hr>
                <div class="m-c-child">
                    <div class="dual-container">
                        <div class="left dual-container">
                            <embed src="assets/icons/schedule_blue_24dp.svg" class="map-nav-icon">
                                <h6 class="map-nav-item fw-600 mb-0 install-time left">{{'basic.installTime' | translate }}</h6>
                                <div *ngIf="!editMode" class="blue-text right order-time">({{singleOrderTime}} min)</div>
                        </div>
                        <div (click)="expandEstimates()" *ngIf="!estimateExpand" class="right time-dropdown"><img class="edit-btn" src="assets/icons/expand_more_black_24dp.svg"></div>
                        <div (click)="expandEstimates()" *ngIf="estimateExpand" class="right time-dropdown"><img class="edit-btn" src="assets/icons/expand_less_black_24dp.svg"></div>
                    </div>
                </div>
                <div *ngIf="estimateExpand && !editMode" class="map-nav-item estimate-containter">
                    <ng-container *ngFor="let item of orderTimeEstimate">
                        <div *ngIf="item.title">
                           <div class="w-100 fw-600 rowtop">{{item.title}}</div>
                            <div class="dual-container gray-text">
                                <div class="left install-time">{{item.name}}</div>
                                <div *ngIf="item.time" class="right">({{item.time}} min)</div>
                                <div *ngIf="!item.time" class="right red-text">{{'planner.mapNav.noDefault' | translate}}</div>
                            </div> 
                        </div>
                        
                    </ng-container>
                </div>
                <form *ngIf="editMode" class="estimate-containter" #f="ngForm" (ngSubmit)="saveChangesToSingleOrder(f)">
                    <div *ngFor="let item of orderTimeEstimate; let i = index" [attr.data-index]="i" class="mt-3">
                        <div *ngIf="item.title">
                            <div class="dual-container gray-text w-100">
                                <div class="left w-70 primary-name">{{item.title}}</div>
                                <span class="right text-right w-30">{{'basic.minutes' | translate }}</span>
                            </div>
                            
                            <div class="dual-container gray-text w-100">
                                <div class="w-10">
                                    <button type="button" class="default-setter" (click)="setNull(i)"></button>
                                </div>
                                <select class="left install-time form-select w-60 mr-1 ml-1" name="{{ item.categoryid + '-' + item.estimateid }}" [(ngModel)]="item.estimateid">
                                    <option *ngFor="let option of item.options" [ngValue]="option.estimateid" [selected]="option.estimateid===item.estimateid">{{ option.name }}</option>
                                </select>
                                <div class="right row w-30 mr-0 ml-0">
                                    <input class="form-control" value="{{item.time}}" type="number" min="0" placeholder="-" [(ngModel)]="item.time" name="{{ item.categoryid + '_' + item.estimateid }}">
                                </div>
                            </div>  
                        </div>
                    </div>
                    <hr>
                    <div class="map-nav-item">
                        <h6>{{'basic.overallTime' | translate }}</h6>
                        <h6 class="blue-text">{{singleOrderTime}} min</h6>  
                    </div>
                    <button id="save-button" class="btn btn-primary map-btn edit-save-btn" type="submit">{{'basic.save' | translate }}</button>
                    <button class="btn btn-secondary map-btn edit-save-btn" (click)="cancelEditMode()">{{'basic.cancel' | translate}}</button> 
                </form>
                
                
            </div>
            <div [hidden]="meterId != null" class="meter-content">
                {{'planner.mapNav.notSelected' | translate }}
            </div>
        </div>
        <div [hidden]="meterId == null || groupping == false" *ngIf="!editMode" class="selected-child">
            <button [hidden]="groupStatus" class="btn btn-primary map-btn" (click)="updateOrderClickToParent()">+ {{'planner.mapNav.add' | translate }}</button>
            <button [hidden]="!groupStatus" class="btn btn-primary map-btn" (click)="updateOrderClickToParent()">- {{'planner.mapNav.remove' | translate }}</button>
            <div [hidden]="groupStatus">
                {{'basic.meter' | translate }} {{ meterId }} > {{'planner.mapNav.notInGroup' | translate }} {{ groupId }}
            </div>
            <div [hidden]="!groupStatus">
                {{'basic.meter' | translate }} {{ meterId }} > {{'planner.mapNav.group' | translate }} {{groupId}}
            </div>
        </div>
    </div>
    <!-- Default mode END -->

    <!-- MSA mode START -->
    <div *ngIf="msaMode" class="pt-3">
        <div *ngIf="mapNavSpinner" class="justify-content-center d-flex mt-5">
            <load-spinner></load-spinner>
        </div>
        <div *ngIf="!mapNavSpinner">
            <div class="align-center ms-4 black-bold-text font-size-large">{{msaTitle}}</div>
            <div *ngIf="msaStartDate && msaEndDate" class="align-center">{{msaStartDate | date: 'dd.MM.yyyy'}} - {{msaEndDate | date: 'dd.MM.yyyy'}}</div>
            <div *ngIf="msaStartDate && !msaEndDate" class="align-center">{{msaStartDate | date: 'dd.MM.yyyy'}} - <span class="red-text">{{'planner.mapNav.endDateMissing' | translate}}</span></div>
            <div *ngIf="!msaStartDate && msaEndDate" class="align-center"><span class="red-text">{{'planner.mapNav.startDateMissing' | translate}}</span> - {{msaEndDate | date: 'dd.MM.yyyy'}}</div>
            <div *ngIf="!msaStartDate && !msaEndDate" class="align-center red-text">{{'planner.mapNav.bothDatesMissing' | translate}}</div>
            <div class="align-center black-bold-text mt-2">{{getTransformerCount()}} {{'basic.transformers' | translate | lowercase}}</div>
            <div class="align-center black-bold-text">{{getWorkorderCount()}} {{'basic.meters' | translate | lowercase}}</div>
            <div *ngFor="let item of markersInsidePolygon">
                <div class="selected-meter selected-child">
                    <div class="meter-content">
                        <div *ngIf="item.workorders">
                            <div class="black-bold-text">{{'basic.transformer' | translate}} {{item.feature.properties.id}}</div>
                            <span class="gray-text">({{item.workorders.length}} meters)</span>
                            <div class="blue-text">{{getTimeTransformer(item)}} {{'basic.minutes' | translate}}</div>

                            <div class="m-c-child">
                                <div class="gray-text">{{getTransformerTimeStart(item)}}</div>
                            </div>
                        </div>
                        <div *ngIf="!item.workorders">
                            <div class="black-bold-text">
                                <div>{{'basic.meter' | translate}} {{item.feature.properties.oldMeterId}}</div>
                                <div class="blue-text" *ngIf="item.time">{{item.time}} {{'basic.minutes' | translate}}</div>
                            </div>

                            <div class="m-c-child">
                                <div class="gray-text">
                                    <span *ngIf="!item.earliest" class="red-text">{{'planner.mapNav.dateNotSet' | translate}}</span>
                                    <span *ngIf="item.earliest">{{item.earliest | date: 'dd.MM.YYYY HH:mm'}}</span>
                                    <span> - </span>
                                    <span *ngIf="!item.latest" class="red-text">{{'planner.mapNav.dateNotSet' | translate}}</span>
                                    <span *ngIf="item.latest">{{item.latest | date: 'dd.MM.YYYY HH:mm'}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="markersInsidePolygon.length == 0" class="selected-meter selected-child red-text">
                <div class="meter-content align-center">{{'planner.mapNav.noTransformers' | translate}}</div>
        </div>
    </div>
    <!-- MSA mode END -->
 </div> 
