import { Component, EventEmitter, Output, Input, OnChanges, SimpleChange } from '@angular/core';
import { ToastService } from '@shared/services/toast/toast.service';
import { WorkOrderService } from '@modules/planner/services/workorder/work-order.service';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-map-nav',
  templateUrl: './map-nav.component.html',
  styleUrls: ['./map-nav.component.scss']
})
export class MapNavComponent implements OnChanges {

  contactList = Array()
  totalMeters = 0;
  totalTimeTransformer;
  estimateExpand = false
  dirtyForm = false
  singleOrderTime = 0
  getUnusedValuesDisabled = false
  extraTime = 0

  projectDefaultEstimates: any
  residentExists = false;
  ownerExists = false;
  owner = {firstName: null, lastName: null, phoneNumber: null, address: null}
  resident = {firstName: null, lastName: null, phoneNumber: null, address: null}
  enduserChanged = false;
  enduserInformationChanged = false;
  residentAddedAlso = false;
  createdEnduser = false;
  endusersCreated = 0;

  constructor(
    private workOrderService: WorkOrderService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private datePipe: DatePipe
  ) { }

  @Output() closeNavToParent = new EventEmitter<String>()
  @Output() updateOrderToParent = new EventEmitter<String>()
  @Output() clickShowAllMeters = new EventEmitter<any>();
  @Output() notesChanged = new EventEmitter<any>();
  @Output() addressChanged = new EventEmitter<any>();
  @Output() locationNumberChanged = new EventEmitter<any>();

  @Input() groupStatus
  @Input() meterId
  @Input() groupId
  @Input() identifier
  @Input() address
  @Input() notes
  @Input() groupping
  @Input() msaMode
  @Input() markersInsidePolygon
  @Input() totalTimeMarkers
  @Input() orderTimeEstimate
  @Input() projectEstimates
  @Input() msaEstimates
  @Input() allEstimates
  @Input() totalMetersCount
  @Input() msaTitle: string | null = null
  @Input() msaStartDate: Date | null = null
  @Input() msaEndDate: Date | null = null
  @Input() mapNavSpinner: boolean = false;
  @Input() locationNumber: string | null = null
  @Input() oldMeterId

  fieldsHaveChanged = false

  editMode = false

  ngOnChanges(changes: { [propName: string]: SimpleChange}) {
    if (changes['meterId'] && changes['meterId'].currentValue != changes['meterId'].previousValue) {
      this.contactList = Array()
      if (this.meterId != null) this.getEndusers(this.meterId)
    }

    if(this.orderTimeEstimate) {
      this.calculateTimeForSingleOrder(this.orderTimeEstimate)
    } else {
      this.singleOrderTime = 0
    }
  }

  getEndusers(meterId) {
    this.workOrderService.getWorkOrder(meterId).subscribe(
      data => {
        this.ownerExists = false
        this.residentExists = false
        // Reset values after getting enduser values 
        this.owner.address = null, this.owner.firstName = null, this.owner.lastName = null, this.owner.phoneNumber = null
        this.resident.address = null, this.resident.firstName = null, this.resident.lastName = null, this.resident.phoneNumber = null
        for (let i = 0; i < data.enduser_id.length; i++) {
          let personType = 'persontype.' + data.enduser_id[i].person_type.toString()
          if (data.enduser_id[i].person_type == 0) this.ownerExists = true
          if (data.enduser_id[i].person_type == 2) this.residentExists = true
          this.contactList.push([personType, data.enduser_id[i].contact_person_first, data.enduser_id[i].contact_person_last, data.enduser_id[i].phone, data.enduser_id[i].address, data.enduser_id[i].id])
        }
      }
    )
  }

  /**
   * Must have unused parameter so that (ngModelChange)="onChange($event)" works
   * @param event 
   */
  onChange(event, changed) {
    this.fieldsHaveChanged = true
    // Check if notes or address has been changed in interface
    if (changed ==  "notes" || changed == "address" || changed == 'locationNumber') this.enduserChanged = true;
    // Check if enduser information has been changed in interface
    if (changed == "enduserFirstName" || changed == 'enduserLastName' || changed == "enduserAddress" || changed == "enduserNumber") this.enduserInformationChanged = true
  }

  /**
   * Triggers event to parent component
   */
  sendCloseNavClickToParent() {
    if (this.editMode) this.editMode = !this.editMode
    this.closeNavToParent.emit()
  }

  /**
   * Triggers event to parent component
   */
  updateOrderClickToParent() {
    this.updateOrderToParent.emit()
    //this.groupStatus = !this.groupStatus
  }

  cancelEditMode() {
    this.editMode = false
    this.getUnusedValuesDisabled = false

    if(this.orderTimeEstimate) {
      this.calculateTimeForSingleOrder(this.orderTimeEstimate)
    }
  }

  switchEditMode() {
    this.editMode = !this.editMode
  }
  
  saveChanges() {
    if(this.meterId && this.fieldsHaveChanged) {
      // Check if enduser data has changed and update data to database with api call
      if (this.enduserChanged == true) {
        if (this.notes != null)  {
          let tempNotes = this.notes;
          this.notesChanged.emit({event, tempNotes})
          
        }
        if (this.address != null)  {
          let tempAddress = this.address;
          this.addressChanged.emit({event, tempAddress})
          
        }
        if (this.locationNumber != null) {
          let tempLocationNumber = this.locationNumber
          this.locationNumberChanged.emit({tempLocationNumber})
        }
        // Make the api call updating values and resetting enduserChanged afterwards
        this.workOrderService.updateWorkOrderData(this.meterId, this.address, this.notes, this.locationNumber)
        this.enduserChanged = false;
      }

      // If owner is empty and has not been set we perform this function
      // Create new enduser for added resident
      if (this.owner.firstName === null && this.owner.lastName === null && this.owner.address === null && this.owner.phoneNumber === null) {
       if (!this.residentExists) {
        if (this.resident.firstName != null || this.resident.lastName != null || this.resident.phoneNumber != null || this.resident.address != null) {
          this.workOrderService.createEnduser(2, this.resident.firstName, this.resident.lastName, this.resident.phoneNumber, this.resident.address , this.meterId).subscribe(
            id => {
              let personType = 'persontype.' + 2
              this.contactList.push([personType, this.resident.firstName, this.resident.lastName, this.resident.phoneNumber, this.resident.address, id])
              this.resetResident()
              // Set resident to true so that it doesn't appear in interface as empty
              this.residentExists = true;
            
            }
          )
        
        }
       } 
      }

      // Check if owner information has been changed and owner does not exist
      if (this.owner.firstName != null || this.owner.lastName != null || this.owner.address != null || this.owner.phoneNumber != null && !this.ownerExists) {
        // Creates added owner to database
        // Also checks if resident has been added also
        this.createOwner(() => {
          // Checks if resident has been added and perform this function if it has been set
          if (this.residentAddedAlso == true) {
            this.workOrderService.createEnduser(2, this.resident.firstName, this.resident.lastName, this.resident.phoneNumber, this.resident.address , this.meterId).subscribe(
              id => {
               let personType = 'persontype.' + 2
               this.contactList.push([personType, this.resident.firstName, this.resident.lastName, this.resident.phoneNumber, this.resident.address, id])
               this.resetResident()
               // Set resident to true so that it doesn't appear in interface as empty
               this.residentExists = true;
               this.residentAddedAlso = false;
                
              }
            )
            
          }
        })
      }

      // Check if resident has been changed
      
      // Loop through all contacts in contactList and update each entry
      if (this.enduserInformationChanged == true) {
        for(let i = 0; i < this.contactList.length; i++) {
          //id, name, phone, address
          this.workOrderService.updateEnduserContacts(this.contactList[i][5], this.contactList[i][1], this.contactList[i][2], this.contactList[i][3], this.contactList[i][4])
        }
        this.enduserInformationChanged = false;
      }
      this.editMode = false
      this.fieldsHaveChanged = false
    } else if (this.meterId && !this.fieldsHaveChanged) {
      this.toastService.sendToast(false, this.translateService.instant('planner.mapNav.sideNavError'))
      this.cancelEditMode()
    }
    this.cancelEditMode()
  }

  showAllMeters(clickedId) {
    this.clickShowAllMeters.emit({event, clickedId})
  }

  expandEstimates() {
    this.estimateExpand = !this.estimateExpand
  }

  calculateTimeForSingleOrder(estimates) {

    let defaultTimesProject: any = null
    let defaultTimesMsa: any = null
    let timeParams = estimates
    let orderTime = 0
    this.singleOrderTime = 0

    if (this.projectEstimates) {
      defaultTimesProject = Object.values(JSON.parse(this.projectEstimates))
      this.projectDefaultEstimates = defaultTimesProject
    }

    if (this.msaEstimates && this.msaEstimates !== '[]') {
      defaultTimesMsa = Object.values(JSON.parse(this.msaEstimates))
    }

    for (let x = 0; x < timeParams.length; x++) {
      const element = timeParams[x];

      // is default timeparam replaced by custom_minutes?
      if (Number(element['custom_minutes'])) {

        // yes, use custom_minutes
        this.orderTimeEstimate[x].time = element['custom_minutes']
        orderTime = Number(orderTime) + Number(element['custom_minutes'])

        this.setEstimateValuesToSingleOrder(x, estimates, defaultTimesProject, true, defaultTimesMsa)

      } else {
        // no, check msa/project defaults
        if(Number(this.setEstimateValuesToSingleOrder(x, estimates, defaultTimesProject, false, defaultTimesMsa))){
          orderTime = Number(orderTime) + Number(this.setEstimateValuesToSingleOrder(x, estimates, defaultTimesProject, false, defaultTimesMsa))
        }
      }
    }
    this.singleOrderTime = Number(orderTime)
  }

  setEstimateValuesToSingleOrder(index, orderEstimates, defaultTimesProject, isCustom, defaultTimesMsa) {

    let theEstimate = []
    let allProjectEstim = this.allEstimates

    const element = orderEstimates[index]
    try {
      allProjectEstim = JSON.parse(this.allEstimates)
    } catch (error) {
    }

    // This loop gets title names for time estimates
    for (let x = 0; x < this.orderTimeEstimate.length; x++) {
      const ordEstim = this.orderTimeEstimate[x];

      for (let i = 0; i < Object.keys(allProjectEstim).length; i++) {
        const allEstim = allProjectEstim[i];

        if(allEstim.categoryid == ordEstim.categoryid) {
          this.orderTimeEstimate[x].title = allEstim.type

          this.orderTimeEstimate[x].options = allEstim.estimates
          

          for (let y = 0; y < Object.keys(allEstim.estimates).length; y++) {
            const estimates = allEstim.estimates[y];

            if(estimates.estimateid == ordEstim.estimateid) {
              this.orderTimeEstimate[x].name = estimates.name
            } else {
              this.orderTimeEstimate[x].options[y].name = estimates.name
            }
          }
        }
      }
    }
    // Run for MSA time estimates
    if (defaultTimesMsa) {
      for (let x = 0; x < defaultTimesMsa.length; x++) {
        const theType = defaultTimesMsa[x];
        for (let i = 0; i < theType['estimates'].length; i++) {
          if (theType['estimates'][i]['estimateid'] == element['estimateid'] && theType['categoryid'] === element['categoryid']) {
            theEstimate = theType['estimates'][i]
            if(!isCustom) {
              this.orderTimeEstimate[index].time = theEstimate['time']
              this.orderTimeEstimate[index].og_time = theEstimate['time']
              if (theEstimate['time']) return theEstimate['time']
            }
          }
        }
      }
    }
    // Run for projects if MSA time did not match or MSA time estimates is not given
    for (let x = 0; x < defaultTimesProject.length; x++) {
      const theType = defaultTimesProject[x];
      for (let i = 0; i < theType['estimates'].length; i++) {
        if (theType['estimates'][i]['estimateid'] == element['estimateid'] && theType['categoryid'] === element['categoryid']) {
          theEstimate = theType['estimates'][i]
          if(!isCustom) {
            this.orderTimeEstimate[index].time = theEstimate['time']
            this.orderTimeEstimate[index].og_time = theEstimate['time']
            return theEstimate['time']
          }
        }
      }
    }
  }

  saveChangesToSingleOrder(f: NgForm) {

    let allowApiCall = true

    if (f.pristine && !this.dirtyForm) {
      this.saveChanges()
    } else {

      let defaultTimes

      if (this.projectEstimates) {
        defaultTimes = Object.values(JSON.parse(this.projectEstimates))
        this.projectDefaultEstimates = defaultTimes
      }
  
      if (this.msaEstimates) defaultTimes = Object.values(JSON.parse(this.msaEstimates))

      let formValue = f.value
      let estimate = this.orderTimeEstimate

      // Loop through time estimates of the time estimate form of a single work order
      for (let [key, value] of Object.entries(formValue)) {
        // primary = otsikko
        // secondary = alaotsikko
        // value = syötetty aika
        let primary = key.substring(0, key.indexOf("_"))
        //let secondary = key.split('_')[1]

        if(Number(value) < 0) {
          this.toastService.sendToast(false, this.translateService.instant('planner.mapNav.negativeNumbersError'))
          allowApiCall = false
          break
        } 

        // Loop through time estimates of the work_order we are making the changes to
        for (let i = 0; i < estimate.length; i++) {
          const element = estimate[i];

          if(element['categoryid'] == primary) {

            if((element['custom_minutes'] == null) && (element['og_time'] == value)) {
              estimate[i]['custom_minutes'] = null
            } else {
              estimate[i]['custom_minutes'] = value
            }
          }
        }
      }

      for (let index = 0; index < estimate.length; index++) {
        delete estimate[index]['time']
        delete estimate[index]['name']
        delete estimate[index]['title']
        delete estimate[index]['options']
        delete estimate[index]['og_time']
      }

      if(allowApiCall) {
        this.workOrderService.updateOrderEstimate(this.meterId, estimate)
      }
      this.calculateTimeForSingleOrder(this.orderTimeEstimate)
      this.editMode = false
      this.dirtyForm = false
      this.estimateExpand = true
    }
  }

  setNull(index) {
    this.orderTimeEstimate[index].time = null
    this.dirtyForm = true
  }

  /**
   * Loop through contacts and set ownerExists and residentExists to true if they appear
   * in contact list. After setting any of the exists to true we won't that in interface.
   */
  setContact() {
    for (let i = 0; i < this.contactList.length; i++) {
      if (this.contactList[i][0] == "persontype.0") {
        this.ownerExists = true;
      }
      if (this.contactList[i][0] == "persontype.2") { 
        this.residentExists = true;
      }
    }
  }

  /**
   * Create owner from scratch and check if resident is also added at the same time. If resident is not set update interface 
   * also. If resident is set make variable residentAddedAlso true
   * @param cb 
   */
  createOwner(cb) {
    this.workOrderService.createEnduser(0, this.owner.firstName, this.owner.lastName, this.owner.phoneNumber, this.owner.address, this.meterId).subscribe(
      id => {
        let personType = 'persontype.' + 0
        this.contactList.push([personType, this.owner.firstName, this.owner.lastName, this.owner.phoneNumber, this.owner.address,  id])
        //this.contactList.push([personType, data['message'].contact_person_first, data['message'].contact_person_last, data['message'].phone, data['message'].address, data['message'].id])
        // Check if resident has been also changed
        // Reset values for owner
        this.resetOwner()
        
        if (this.resident.firstName == null && this.resident.lastName == null && this.resident.address == null && this.resident.phoneNumber == null) {
          this.ownerExists = true;
          cb()
          
        } else {
          // Reset values and remove empty owner from list
          this.ownerExists = true;
          // Set resident to true for further usage
          this.residentAddedAlso = true;
          cb()
        }
      }
    )
  }
  resetOwner() {
    this.owner.firstName = null, this.owner.lastName = null, this.owner.address = null, this.owner.phoneNumber = null
  }

  resetResident() {
    this.resident.firstName = null, this.resident.lastName = null, this.resident.address = null, this.resident.phoneNumber = null
  }

  getTransformerCount() {
    return this.markersInsidePolygon.filter(marker => marker.workorder === false).length
  }

  getWorkorderCount() {
    return this.markersInsidePolygon.filter(marker => marker.workorder === true).length
  }

  getTimeTransformer(transformer) {
    if (transformer.workorders) {
      let totalTime = 0
      totalTime = transformer.workorders.reduce((acc, element) => acc + element.time, 0);
      return totalTime
    } else return 0
  }

  getTransformerTimeStart(transformer) {
    if (transformer.workorders) {
      let earliestDate = new Date(Math.min(...transformer.workorders
        .filter(wo => wo.earliest !== null)  // Filter out entries where 'earliest' is null
        .map(wo => new Date(wo.earliest).getTime())  // Map remaining entries to their timestamp
      ));
      let latestDate = new Date(Math.min(...transformer.workorders
        .filter(wo => wo.latest !== null)  // Filter out entries where 'earliest' is null
        .map(wo => new Date(wo.latest).getTime())  // Map remaining entries to their timestamp
      ));
      try {
        let time = this.datePipe.transform(earliestDate, 'dd.MM.yyyy HH:mm') + " - " + this.datePipe.transform(latestDate, 'dd.MM.yyyy HH:mm')
        return time
      } catch {
        return this.translateService.instant('planner.mapNav.dateNotSet')
      }
    } else return this.translateService.instant('planner.mapNav.dateNotSet')

  }
}


