<div class="page-container h-100 pb-5">
    <div class="page-content h-100">

        <div class="bottom-container">
            <div class="row week-picker d-flex justify-content-center">
                <div class="col-4 mt-2">
                    <div class="text-center">
                        <img src="assets/icons/house_gray_24dp.svg" (click)="moveToToday()" class="clickable-image">
                    </div>
                    <div class="d-flex justify-content-center mt-2 mb-5">

                        <img class="chevron p-2" src="assets/icons/chevron_left_gray_24dp.svg" (click)="lastWeek()">

                        <div class="text-center px-3">
                            <span class="gray-text">{{'basic.week' | translate }} {{weekNr}}</span>
                            <br>
                            <span *ngIf="firstDay">{{ firstDay.getDate() }}.{{ firstDay.getMonth() + 1 }}.{{ firstDay.getFullYear() }} - {{ lastDay.getDate() }}.{{ lastDay.getMonth() + 1 }}.{{ lastDay.getFullYear() }}</span>

                        </div>
                        <img class="chevron p-2" src="assets/icons/chevron_right_gray_24dp.svg" (click)="nextWeek()">
                    </div>
                </div>
            </div>
            <!--<load-spinner *ngIf="tableSpinner" class="spinner-table"></load-spinner>-->
            <div *ngIf="moveMarkersToggle">
                <div>All selected workorders</div>
                <div *ngFor="let item of selectedMarkers" class="mb-1">
                    <div>{{item.markers.length}} markers selected from: ({{item.slot.starttime | date: 'dd.MM.yyyy HH:ss'}} - {{item.slot.endtime | date: 'HH:ss'}})</div>
                </div>
                <button type="button" class="btn btn-primary me-1" (click)="toggleSlotSelection()">Select a new slot for markers</button>
                <button type="button" class="btn btn-secondary" (click)="clearSelectedMarkers()">Clear selected markers</button>
            </div>
            <div class="calendar-container" *ngFor="let calendar of calendarData">
                <div class="fw-bold text-center mb-1">{{calendar.name}}</div>
                <table class="table table-bordered text-center mb-5">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <th *ngFor="let day of calendar.days; let i = index" scope="col">{{ getDayName(day) }} {{
                                day.getDate() }}.{{ day.getMonth() + 1 }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let row of calendar.slotRows; let i = index">
                            <th>{{'basic.slot' | translate}} {{i + 1}}</th>
                            <td *ngFor="let slot of row; let index = index" class="relative"
                                [ngClass]="{'slot-disabled': slot.disabled, 'slot-selected': slot.selected}">
                                <div class="slot-container" [style.background]="slot.color"
                                    [style.cursor]="selectSlotMode ? 'pointer' : null"
                                    (mouseenter)="selectSlotMode ? toggleFill(slot) : null"
                                    (click)="selectSlot(slot.id, i)">
                                    <div class="mt-1 w-100 d-flex justify-content-center">
                                        <div class="left text-small">
                                            {{getTime(slot.starttime, slot.endtime)}}
                                        </div>
                                        <div *ngIf="!slot.disabled && getTime(slot.starttime, slot.endtime)"
                                            class="right ml-1">
                                        </div>
                                    </div>
                                    <div class="d-table h-100 w-100" style="min-height: 30px;">
                                        <div class="slot-stuff" *ngIf="getTime(slot.starttime, slot.endtime)">
                                            <div class="d-inline-block wrap-anywhere" *ngIf="slot.disabled">
                                                ({{slot.disabled_reason}})
                                            </div>
                                            <div class="d-inline-block"
                                                *ngIf="!slot.disabled && bypassMode && !selectSlotMode">
                                                <input type="checkbox"> {{'calendar.bypass' | translate }}
                                            </div>
                                            <div class="d-flex align-items-center justify-content-center"
                                                *ngIf="!slot.disabled && !bypassMode && selectSlotMode">
                                                <img height="30px" src="/assets/icons/add_gray_24dp.svg">
                                                <span *ngIf="slot.startHere === true"
                                                    class="start-here">{{'planner.weeklyPlanningMap.startHere' |
                                                    translate}}</span>
                                            </div>

                                            <details #detailsRef
                                                *ngIf="!slot.disabled && !bypassMode && !selectSlotMode"
                                                [attr.open]="openedSlotId === slot.id ? '' : null"
                                                id="{{'slot-' + slot.id}}" [style.background]="slot.color"
                                                [style.cursor]="!selectSlotMode ? 'pointer' : null" class="slot-open">
                                                <summary class="slot-items">
                                                    <div *ngFor="let marker of slot.counts" class="slot-marker">
                                                        <div class="svg-inside left" [innerHtml]="marker.icon | safe">
                                                        </div>
                                                        <p class="right">{{marker.count}}</p>
                                                    </div>
                                                </summary>
                                                <div class="slot-details">
                                                    <div class="mt-3">{{getTime(slot.starttime, slot.endtime)}}</div>
                                                    <div class="progress-bars mt-2"
                                                        [style.background-color]="slot.color">
                                                        <div class="dual-container mt-1">
                                                            <div class="ml-1 left d-flex">
                                                                <img
                                                                    src="/assets/icons/calendar_available_black_24dp.svg">
                                                                <span *ngIf="slot.notFree"
                                                                    class="small">{{slot.notFree}}%</span>
                                                                <span *ngIf="!slot.notFree" class="small">0%</span>
                                                            </div>
                                                            <div class="progress progress-primary right">
                                                                <div class="progress-bar" role="progressbar"
                                                                    [ngStyle]="setProgress(slot.notFree)"
                                                                    aria-valuenow="0" aria-valuemin="0"
                                                                    aria-valuemax="100">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="dual-container mt-1 mb-1">
                                                            <div class="ml-1 left d-flex">
                                                                <img src="/assets/icons/calendar_month_black_24dp.svg">
                                                                <span *ngIf="slot.freeAccess"
                                                                    class="small">{{slot.freeAccess}}%</span>
                                                                <span *ngIf="!slot.freeAccess" class="small">0%</span>
                                                            </div>
                                                            <div class="progress progress-primary right">
                                                                <div class="progress-bar" role="progressbar"
                                                                    [ngStyle]="setProgress(slot.freeAccess)"
                                                                    aria-valuenow="0" aria-valuemin="0"
                                                                    aria-valuemax="100">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="spacer"></div>
                                                    <div class="dual-container justify-content-center mt-1 mb-1">
                                                        <span class="pill pill-1"
                                                            [ngClass]="slot.slot_max != null && calendar.slot_max_default != slot.slot_max ? 'slot_max_pill' : 'default_pill'">
                                                            {{slot.slot_max != null ? slot.slot_max :   
                                                            calendar.slot_max_default}}%
                                                        </span>
                                                    </div>
                                                    <div class="mt-2 mb-2 break-word">
                                                        {{'basic.installTime' | translate }}
                                                        <br>
                                                        <span class="blue-text">{{getSlotTime(slot.workorders)}}</span>
                                                    </div>
                                                    <div class="meter-details" *ngFor="let meter of slot.markers"
                                                        data-bs-toggle="tooltip" data-bs-placement="top"
                                                        title="{{meter.time + ' min'}}">
                                                        <div class="form-check mx-1">
                                                            <input class="form-check-input margin-top-checkbox"
                                                                type="checkbox" [(ngModel)]="meter.checked" (click)="selectMarker(meter, slot)">
                                                            <label class="form-check-label d-flex text-small">
                                                                <div class="svg-inside" [innerHtml]="meter.icon | safe">
                                                                </div>
                                                                <div class="align-self-center ms-1 break-word">
                                                                    <span
                                                                        [hidden]="!meter.old_device_id">{{'basic.meter'
                                                                        | translate }} {{meter.old_device_id}}</span>
                                                                    <span [hidden]="meter.old_device_id"
                                                                        class="red-text">{{'basic.noID' |
                                                                        translate}}</span>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div (click)="closeDetailsWithId('slot-' + slot.id)">
                                                        <img src="/assets/icons/expand_less_black_24dp.svg">
                                                    </div>
                                                </div>
                                            </details>
                                        </div>
                                    </div>
                                    <div *ngIf="!slot.disabled && !bypassMode && getTime(slot.starttime, slot.endtime)"
                                        class="progress-bars">
                                        <div *ngIf="slot.notFree || slot.freeAccess">
                                            <div class="dual-container mt-1">
                                                <div class="ml-1 left d-flex">
                                                    <img src="/assets/icons/calendar_available_black_24dp.svg">
                                                    <span *ngIf="slot.notFree" class="small">{{slot.notFree}}%</span>
                                                    <span *ngIf="!slot.notFree" class="small">0%</span>
                                                </div>
                                                <div class="progress progress-primary right">
                                                    <div class="progress-bar" role="progressbar"
                                                        [ngStyle]="setProgress(slot.notFree)" aria-valuenow="0"
                                                        aria-valuemin="0" aria-valuemax="100">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="dual-container mt-1 mb-1">
                                                <div class="ml-1 left d-flex">
                                                    <img src="/assets/icons/calendar_month_black_24dp.svg">
                                                    <span *ngIf="slot.freeAccess"
                                                        class="small">{{slot.freeAccess}}%</span>
                                                    <span *ngIf="!slot.freeAccess" class="small">0%</span>
                                                </div>
                                                <div class="progress progress-primary right">
                                                    <div class="progress-bar" role="progressbar"
                                                        [ngStyle]="setProgress(slot.freeAccess)" aria-valuenow="0"
                                                        aria-valuemin="0" aria-valuemax="100">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="spacer"></div>
                                            <div class="border border-gray editSingleSlot"
                                                [hidden]="slotToEdit != slot.id">
                                                <div class="display-flex justify-content-center mb-2 mx-2">
                                                    <div class="calendar-name mt-2">
                                                        {{'weeklyPlanningDetails.calendarSlotFill_1' | translate }}
                                                    </div>
                                                    <div class="mt-2">
                                                        <input type="text" min="0" max="{{defaultmax}}"
                                                            placeholder="{{calendar.slot_max_default}}%"
                                                            class="form-control max-content "
                                                            [(ngModel)]="slot.slot_max">
                                                        <div class="red-text small-text"
                                                            [hidden]="slot.slot_max <= defaultmax">
                                                            {{'weeklyPlanningDetails.overdefault' | translate }}
                                                            {{defaultmax}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="dual-container justify-content-center mt-1 mb-1">
                                                <span class="pill pill-2"
                                                    [ngClass]="slot.slot_max != null && calendar.slot_max_default != slot.slot_max ? 'slot_max_pill' : 'default_pill'">
                                                    {{slot.slot_max != null ? slot.slot_max :
                                                    calendar.slot_max_default}}%
                                                </span>
                                            </div>
                                        </div>
                                        <div class="progress-bars-empty" *ngIf="!slot.notFree && !slot.freeAccess">
                                            <div class="align-center justify-content-center gray-text">
                                                <div class="dual-container mt-1">
                                                    <div class="ml-1 left d-flex">
                                                        <img src="/assets/icons/calendar_available_black_24dp.svg">
                                                        <span class="small">0%</span>
                                                    </div>
                                                    <div class="progress progress-primary right">
                                                        <div class="progress-bar" role="progressbar"
                                                            [ngStyle]="setProgress(0)" aria-valuenow="0"
                                                            aria-valuemin="0" aria-valuemax="100">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="dual-container mt-1 mb-1">
                                                    <div class="ml-1 left d-flex">
                                                        <img src="/assets/icons/calendar_month_black_24dp.svg">
                                                        <span class="small">0%</span>
                                                    </div>
                                                    <div class="progress progress-primary right">
                                                        <div class="progress-bar" role="progressbar"
                                                            [ngStyle]="setProgress(0)" aria-valuenow="0"
                                                            aria-valuemin="0" aria-valuemax="100">
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="spacer"></div>
                                                <div class="border border-gray editSingleSlot"
                                                    [hidden]="slotToEdit != slot.id">
                                                    <div class="display-flex justify-content-center mb-2 mx-2">
                                                        <div class="calendar-name mt-2">
                                                            {{'weeklyPlanningDetails.calendarSlotFill_1' | translate }}
                                                        </div>
                                                        <div class="mt-2">
                                                            <input type="text" min="0" max="{{defaultmax}}"
                                                                placeholder="{{calendar.slot_max_default}}%"
                                                                class="form-control max-content "
                                                                [(ngModel)]="slot.slot_max">
                                                            <div class="red-text small-text"
                                                                [hidden]="slot.slot_max <= defaultmax">
                                                                {{'weeklyPlanningDetails.overdefault' | translate }}
                                                                {{defaultmax}}</div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                                <div class="dual-container justify-content-center mt-1 mb-1">

                                                    <span class="pill pill-3"
                                                        [ngClass]="slot.slot_max != null && calendar.slot_max_default != slot.slot_max ? 'slot_max_pill' : 'default_pill'">
                                                        {{slot.slot_max != null ? slot.slot_max :
                                                        calendar.slot_max_default}}%
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>