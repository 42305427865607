<!-- QR Scanner View -->
<div id="qr-reader"></div>

<!-- Scanning Message and Stop Scanning Button -->
<div *ngIf="!isCodeScanned" class="scanning-container">
  <p class="scanning-message">Scanning for QR code...</p>
  <button (click)="stopScanning()" class="stop-scanning-button">Stop Scanning</button>
</div>

<!-- Button Container -->
<div *ngIf="isCodeScanned" class="button-container">
  <!-- Post Scan View -->
  <div class="scan-result-container">
    <p class="scanned-code-text">Scanned QR Code:</p>
    <p class="scanned-code">{{ scannedCode }}</p>
    <div class="button-group">
      <button (click)="stopScanning()">Cancel</button>
      <button (click)="confirmScan()">Confirm</button>      
    </div>
  </div>
</div>