import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@shared/services/user/user.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-human-resources',
  templateUrl: './human-resources.component.html',
  styleUrls: ['./human-resources.component.scss']
})
export class HumanResourcesComponent implements OnInit {

  componentDestroyed$: Subject<boolean> = new Subject()
  customerId;

  constructor(private router: Router,
    private userService: UserService) { }

  ngOnInit(): void {
    this.getCustomer();
  }

  getCustomer() {
    this.userService.getUserInfo()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.customerId = data.customer_id;
      })
  }

  /**
   * 12.12.2022
   * Navigate to workers or teams page
   * @param destination name of landing page
   * @author Jesse Lindholm
   */
  navigate(destination: string) {
    if (destination === 'workers' || destination === 'teams') this.router.navigate([`/planner/${destination}`])
  }

}
