import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ngx-circle-custom',
  templateUrl: './ngx-circle-custom.component.html',
  styleUrls: ['./ngx-circle-custom.component.scss']
})
export class NgxCircleCustomComponent implements OnInit {

  constructor() { }

  @Input() efficiency: number = 0

  colorInner: string = ""
  colorOuter: string = ""
  efficiencyTitle

  ngOnInit(): void {
    this.efficiencyTitle = this.efficiency
    if (this.efficiency >= 1) {
      this.colorOuter = "#78C000"
      this.colorInner = "#C7E596"
      this.efficiency = (this.efficiency - 1) * 100
    } else {
      this.colorOuter = '#FF0000'
      this.colorInner = '#ffcccb'
      this.efficiency = this.efficiency * 100
    }
  
    
  }

}
