import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseUrl } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { WorkOrder } from '@shared/models/work-order';
import { ToastService } from '@shared/services/toast/toast.service';
import { catchError, map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EndUserService {

  constructor(
    private http: HttpClient,
    private toastService: ToastService,
    private translateService: TranslateService
    ) { }

    /**
   * Makes a http get request to backend and returns one work order by id
   * @param {number} id Takes id and requests work order by it
   * @returns one work order
   *
   */
  getWorkOrder(id: number, userValues): Observable<WorkOrder> {
    let queryParams = new HttpParams()

    queryParams = queryParams.append("location_number", userValues.location_number) //VERY IMPORTANT
    queryParams = queryParams.append("password", userValues.password) //VERY IMPORTANT
    queryParams = queryParams.append("enduser", true) //VERY IMPORTANT

    const url = `${baseUrl}/api/workorders/${id}`;
    return this.http.get<WorkOrder>(url, {params: queryParams})
      .pipe(
        map((data: WorkOrder) => {
          return data['message']
        }),
        //tap(_ => console.log(`fetched workorder id=${id}`)),
        catchError(this.handleError<WorkOrder>(`getWorkOrder id=${id}`))
      );
  }

  /**
   * This function gets all the slots that the user is able to change his time to. Also returns startdate and enddate for the calendar.
   * @param id
   * @param userValues
   * @returns
   */
  getCalendarByWorkOrder(id: number, userValues): Observable<any> {
    let queryParams = new HttpParams()

    queryParams = queryParams.append("location_number", userValues.location_number) //VERY IMPORTANT
    queryParams = queryParams.append("password", userValues.password) //VERY IMPORTANT
    queryParams = queryParams.append("enduser", true) //VERY IMPORTANT

    return this.http.get(`${baseUrl}/api/calendarbyworkorder/${id}`, {params: queryParams})
      .pipe(
        map((data: any) => {
          return data['message'];
        }),
        catchError(error => {
          //console.log(error)
          return of(false);
        })
      )
  }

  /**
   * This function is called when the user wants to confirm the original time slot that he/she was given.
   * Phone number is optional.
   * @param id
   * @param phone
   * @param userValues
   */
  confirmWorkOrder(id, phone, userValues): any {

    let newdataJSON = {}

    if(phone) {
      newdataJSON = {
        "time_confirmed": true,
        "contact_info": phone
      }
    } else {
      newdataJSON = {
        "time_confirmed": true,
      }
    }

    let queryParams = new HttpParams()

    queryParams = queryParams.append("location_number", userValues.location_number) //VERY IMPORTANT
    queryParams = queryParams.append("password", userValues.password) //VERY IMPORTANT
    queryParams = queryParams.append("enduser", true) //VERY IMPORTANT

    let newdata = JSON.stringify(newdataJSON)

    this.http.patch(`${baseUrl}/api/workorders/${id}`, {
        newdata
      }, {params: queryParams})
      .toPromise()
      .then(response => {
        this.toastService.sendToast(true, this.translateService.instant('services.confirmed'))
        return response
      })
      .catch(err => {
        //console.log(err);
        this.toastService.sendToast(false, err)
      }
    )
  }

  /**
   * This function is called when the user wants to change the original time that has been given to him/her.
   * Phone number is optional.
   * @param slot_id
   * @param workorder_id
   * @param phone
   * @param userValues
   * @returns
   */
  confirmWithChange(slot_id, workorder_id, phone, userValues) {

    let queryParams = new HttpParams()

    queryParams = queryParams.append("location_number", userValues.location_number) //VERY IMPORTANT
    queryParams = queryParams.append("password", userValues.password) //VERY IMPORTANT
    queryParams = queryParams.append("enduser", true) //VERY IMPORTANT

    let data = {}

    if(phone) {
      data = {
        "time_confirmed": true,
        "contact_info": phone
      }
    } else {
      data = {
        "time_confirmed": true,
      }
    }

    return this.http.post(`${baseUrl}/api/calendarslots/${slot_id}/${workorder_id}`, data, {params: queryParams})
    .pipe(
      map((data) => {
        this.toastService.sendToast(true, this.translateService.instant('services.slotChanged'))
        return data['message']
      }),
      catchError(error => {
        this.handleError(`Confirming time`)
        this.toastService.sendToast(false, this.translateService.instant('services.errorConfirmingTime'))
        return of(false)
      })
   );
  }

  /**
   * Handles Http operations that failed.
   * Lets the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
   private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      //console.log(error)
      //console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
