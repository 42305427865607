<div class="page-container">
  <div class="page-heading">
    <h2>{{'basic.userManagement' | translate}}</h2>
  </div>
  <div class="page-content">
    <div class="card">
      <div class="card-body">
<!--        <button type="button" class="btn btn-primary btn-lg me-2" (click)="navigate('planners')">{{'userManagement.planners' | translate}}</button>-->
<!--        <button type="button" class="btn btn-primary btn-lg" (click)="navigate('callService')">{{'userManagement.callService' | translate}}</button>-->
<!--        <button type="button" class="btn btn-primary btn-lg" (click)="navigate('admins')">{{'userManagement.admins' | translate}}</button>-->
        <h3>{{'planner.userManagement.userTypes' | translate}}</h3>

        <div class="row pe-4">
          <table class="mt-5 ms-3 min-width">
            <thead>
            </thead>
            <tbody class="min-width">
            <tr class="border-bottom border-top border-gray my-10">
              <td class="col-2 py-2">
                {{'planner.userManagement.planners' | translate}}
              </td>
              <td class="col-1">
                {{plannerCount}}
              </td>
              <td class="col-2 text-success">
                {{activePlannerCount}} {{'planner.userManagement.activeUsers' | translate}}
              </td>
              <td class="col"></td>
            </tr>
            <tr class="border-bottom border-top border-gray my-1">
              <td class="col-2 py-2">
                {{'planner.userManagement.callService' | translate}}
              </td>
              <td class="col-1">
                {{callServiceCount}}
              </td>
              <td class="col-2 text-success">
                {{activeCallServiceCount}} {{'planner.userManagement.activeUsers' | translate}}
              </td>
              <td class="col"></td>
            </tr>
            <tr class="border-bottom border-top border-gray my-2">
              <td class="col-2 py-2">
                {{'planner.userManagement.admins' | translate}}
              </td>
              <td class="col-1">
                {{adminCount}}
              </td>
              <td class="col-2 text-success">
                {{activeAdminCount}} {{'planner.userManagement.activeUsers' | translate}}
              </td>
              <td class="col"></td>
            </tr>
            </tbody>
          </table>
        </div>



      </div>

    </div>
  </div>
</div>
