import { AfterViewInit, Component, ElementRef, OnDestroy, Output, ViewChild } from '@angular/core';
//import * as L from 'leaflet';
import 'leaflet.markercluster';
import { Icon, icon } from 'leaflet';
import { WorkOrderService } from '@modules/planner/services/workorder/work-order.service';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from '@shared/services/toast/toast.service';
import 'leaflet-draw';
import { MapNavComponent } from '@modules/planner/components/map-nav/map-nav.component';
import { finalize, takeUntil } from "rxjs/operators";
import { Observable, Subject, Subscription, forkJoin } from 'rxjs';
import { UserService } from '@shared/services/user/user.service';
import { PlannerProjectService } from '@modules/planner/services/planner-project/planner-project.service';
import 'leaflet-editable';
import Swal from 'sweetalert2';
import { MapService } from '@modules/planner/services/map/map.service';
import { TranslateService } from '@ngx-translate/core';

// We declare leaflet "L" like this to enable leaflet-draw
declare const L: any;

@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.scss']
})

/**
 * We extend the component to implement AfterViewInit.
 * During its lifecycle hook we can reference the map div to create a map.
 */
export class MapViewComponent implements AfterViewInit, OnDestroy {

  componentDestroyed$: Subject<boolean> = new Subject()

  @ViewChild(MapNavComponent) MapNav!:MapNavComponent;

  // Values for markers
  boltSize = 32
  boltShadowSize = 35
  boltAnchor = 16
  orderSize = 28
  orderShadowSize = 32
  orderAnchor = 14

  // Variables that we use when working with the map
  map: any
  ordergroupGroup: any
  //workorderGroup: any
  //workorderNoIdGroup: any
  allWorkorderGroup: any
  positionEditor = false
  grouppingEditor = false
  mapNavOpen = false
  workorderUpdateQueue = Array()
  ordergroupUpdateQueue = Array()
  addToGroupQueue = Array()
  selectedOrderGroupId?: number
  mapInitialized = false
  selectedOrder: any
  showAllToggle = true
  msaMode
  leafletId
  confirmSaveInput = false;
  editableLayers
  currentLayer: any
  drawControl
  currentMSALayers = Array()
  markersInsidePolygon = Array()
  MSAColors = [
    '#AD2102',
    '#F5222D',
    '#FA8C16',
    '#FAD814',
    '#A0D911',
    '#52C41A',
    '#08979C',
    '#32E8E5',
    '#1890FF',
    '#1D39C4',
    '#722ED1',
    '#D51EF9',
    '#EB2F96',
    '#F8A5C2',
    '#D3BC82',
    '#FFC076',
    '#B0FA14',
    '#00FFA7',
    '#90B3B7',
    '#43A8FF',
    '#002BFF',
    '#B590C9',
    '#FF00EB',
    '#966C00',
  ]
  msaName: string | null = null;
  finalMSAPoints = Array();
  msaTitle: string | null = null;
  editMsaMode = false;
  editingMode = false;
  installationTime;
  showAllMetersClickedId = Array();
  editAreaBoolean = false;
  allOrdergroups: any[] = [];
  msaIds = Array();
  totalTimeMarkers = 0;
  decisionForSavingMsaTransformers = false;
  previousLayerLatLngs;
  transformersInsidePolygonPreviousLayer = Array();
  subscriptionsLayer: Subscription[] = [];
  currentWorkorder;
  createdEnduser = false;
  multipleMsas = false;
  multipleMsasColor;
  tempAddedLayers = Array();
  allMsaEstimates = {}
  totalMetersCount = 0;
  mapWidth = '100%';
  allMsas: Array<any> = Array();
  selectedMsaCoordinates: string | null = null;
  originalLayersEdit: Array<any> = Array()
  msaModeOn: boolean = false
  drawCreatedCalled: boolean = false;
  showCancelCreate: boolean = false;
  workorderSubscription = new Subscription()

  // Values that are sent to map-nav
  groupStatus?: boolean
  meterId: any
  identifier: any
  oldMeterId: any
  groupId: any
  address?: any
  contact?: any
  phone?: any
  notes?: any
  groupping?: boolean
  orderTimeEstimate: any
  projectId: any
  projectEstimates: any
  msaEstimates: any
  allEstimates: any
  mapNavSpinner = false;
  @Output() locationNumber: string | null = null

  //msa groups
  msaGroup1: any
  msaGroup2: any
  msaGroup3: any
  msaGroup4: any
  msaGroup5: any
  msaGroup6: any
  msaGroup7: any
  msaGroup8: any
  msaGroup9: any
  msaGroup10: any
  msaGroup11: any
  msaGroup12: any
  msaGroup13: any
  msaGroup14: any
  msaGroup15: any

  // Msa dates
  msaStartDateToMapNav: Date | null = null
  msaEndDateToMapNav: Date | null = null
  MSADateStartCreate: Date | null = null
  MSADateEndCreate: Date | null = null
  msaDateStartEdit: Date | null = null
  msaDateEndEdit: Date | null = null
  showEditDateRange: boolean = false
  showInformationDateRange: boolean = false
  datesWrong: boolean = false
  drawEnabled

  filteringOptions: FilteringOption[] = [
    {description: 'showAll', amount: 0, checkboxValue: true, status: 0},
    {description: 'scheduled', amount: 0, checkboxValue: false, status: 2},
    {description: 'started', amount: 0, checkboxValue: false, status: 3},
    {description: 'done', amount: 0, checkboxValue: false, status: 4},
    {description: 'doneExtraWork', amount: 0, checkboxValue: false, status: 5},
    {description: 'interrupted', amount: 0, checkboxValue: false, status: 6}
  ]
  markersForFiltering: any = []
  isHoveringFilteringButton: boolean = false
  manyFilteringOptions: number = 0

  constructor(
    private workOrderService: WorkOrderService,
    private elementRef: ElementRef,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private userService: UserService,
    private plannerProjectService: PlannerProjectService,
    private mapService: MapService,
    private translateService: TranslateService
  ) { }

  // Icon initializing

  private orderWithIdIcon: Icon = icon({
    iconUrl: 'assets/leaflet/workorder/workorder.svg',
    shadowUrl: 'assets/leaflet/marker-shadow.png',
    iconSize: [this.orderSize, this.orderSize],
    shadowSize: [this.orderShadowSize, this.orderShadowSize],
    iconAnchor: [this.orderAnchor, this.orderAnchor]
  });

  private orderNoIdIcon: Icon = icon({
    iconUrl: 'assets/leaflet/workorder/gray-ring.svg',
    shadowUrl: 'assets/leaflet/marker-shadow.png',
    iconSize: [this.orderSize, this.orderSize],
    shadowSize: [this.orderShadowSize, this.orderShadowSize],
    iconAnchor: [this.orderAnchor, this.orderAnchor]
  });

  // Sets initial values to some of our variables and checks that the map-Toii nav is closed
  ngOnInit() {
    this.filteringOptions.forEach(option => {
      this.translateService.get('planner.mapView.filteringList.' + option.description).subscribe((translation: string) => {
        option.description = translation;
      });
    });
    this.getProjectInfo()
    this.getMsaEstimates()
    this.positionEditor = false
    this.grouppingEditor = false
    this.groupping = this.grouppingEditor
    this.mapNavOpen = false
    this.mapNavHandler()
    this.msaMode = this.route.snapshot.data['msa']
    if (this.msaMode) this.msaModeOn = true;
  }

  /**
   * Initializes all map methods that we need when the component loads
   */
  ngAfterViewInit(): void {
    this.initMap()
    this.getOrderGroups()
    this.showAllMeters()
    if (this.msaMode) this.getMsas()

  }



  ngOnDestroy() {
    this.elementRef.nativeElement.remove();
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }

  /**
   * Sets the initial map canvas and settings for the map.
   * Sets the initial clusterGroups.
   * Sets a couple of listeners to events on the map.
   */
  private initMap(): void {

    // Sets initial canvas
    this.map = L.map('map', {editable: true})

    // Sets the zoom +/- controller to topright corner of the map
    this.map.zoomControl.setPosition('topright')

    // OpenStreetMap integration
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      maxZoom: 19,
      minZoom: 5,
      detectRetina: true,
    }).addTo(this.map)

    // Centers on top of finland if user denies location permission
    this.map.on("locationerror", () => {
      this.map.panTo({lat:60.19, lon:24.94})
    });

    // Center on user on default
    this.map.locate({
      setView : true,
      maxZoom: 9
    });

    this.mapInitialized = true
    this.allWorkorderGroup = L.markerClusterGroup({
      //disableClusteringAtZoom: 7,
      maxClusterRadius: (mapZoom) => {
      if (mapZoom < 14) return 80;
      if (mapZoom < 17) return 60
      else return 10;
    }}).addTo(this.map)
    this.ordergroupGroup = L.layerGroup().addTo(this.map)

    // MSA clustergroup initialization
    this.msaGroup1 = L.markerClusterGroup().addTo(this.map)
    this.msaGroup2 = L.markerClusterGroup().addTo(this.map)
    this.msaGroup3 = L.markerClusterGroup().addTo(this.map)
    this.msaGroup4 = L.markerClusterGroup().addTo(this.map)
    this.msaGroup5 = L.markerClusterGroup().addTo(this.map)
    this.msaGroup6 = L.markerClusterGroup().addTo(this.map)
    this.msaGroup7 = L.markerClusterGroup().addTo(this.map)
    this.msaGroup8 = L.markerClusterGroup().addTo(this.map)
    this.msaGroup9 = L.markerClusterGroup().addTo(this.map)
    this.msaGroup10 = L.markerClusterGroup().addTo(this.map)
    this.msaGroup11 = L.markerClusterGroup().addTo(this.map)
    this.msaGroup12 = L.markerClusterGroup().addTo(this.map)
    this.msaGroup13 = L.markerClusterGroup().addTo(this.map)
    this.msaGroup14 = L.markerClusterGroup().addTo(this.map)
    this.msaGroup15 = L.markerClusterGroup().addTo(this.map)


    // Right click opens a suggestion to view target location in google street view
    this.map.on('contextmenu',(e) => {
      let url = "http://maps.google.com/maps?q=&layer=c&cbll="
      let lat = e.latlng.lat
      let lng = e.latlng.lng

      url = url + lat + ',' + lng

      L.popup()
      .setLatLng(e.latlng)
      .setContent(`<a href="${url}" target="_blank" rel="noreferrer noopener">Open in Street View</a>`)
      .addTo(this.map)
      .openOn(this.map);
    });

    // Cant set markers dragging property if they are behind a clustergroup
    // We listen to two kinds of events that open clustergroups
    // Zooming in and opening by clicking a cluster
    this.map.on('zoomend', () => {
      if (this.positionEditor) this.makeMarkerDraggable()
    });

    // Calls makeMarkerDraggable after clustergroup is opened so that we can drag those markers
    this.allWorkorderGroup.on('animationend', () => {
      if (this.positionEditor) this.makeMarkerDraggable()
    })
    this.editableLayers = new L.featureGroup();
    this.editableLayers.on('click', this.msaClick);
    this.map.addLayer(this.editableLayers);

    let drawPluginOptions = {
      position: 'topright',
      draw: {
        polygon: {
          allowIntersection: false, // Restricts shapes to simple polygons
          drawError: {
            color: '#e1e100', // Color the shape will turn when intersects
            message: '<strong>Oh snap!<strong> you can\'t draw that!' // Message that will show when intersect
          },
          shapeOptions: {
            color: '#97009c'
          }
        },
        // disable toolbar item by setting it to false
        polyline: false,
        circle: false, // Turns off this drawing tool
        rectangle: false,
        marker: false,
        circlemarker: false
        },

      edit: {
        featureGroup: this.editableLayers, //REQUIRED!!
        remove: false,
        edit: false
      }

    };

    // Initialise the draw control and pass it the FeatureGroup of editable layers
    this.drawControl = new L.Control.Draw(drawPluginOptions);

  }

  /**
   * Handles the sideNav opening/closing logic.
   */
  mapNavHandler() {
    if (this.mapNavOpen) {
      document.getElementById('map')!.classList.add('map_right')
      document.getElementById('map-sidebar')!.classList.add('sidebar_width')
      document.getElementById('map-header')!.classList.add('header_padding')
      this.mapWidth = '80%';
    } else {
      this.meterId = null
      this.groupId = null
      document.getElementById('map')!.classList.remove('map_right')
      document.getElementById('map-sidebar')!.classList.remove('sidebar_width')
      document.getElementById('map-header')!.classList.remove('header_padding')

      this.grouppingEditor = false
      this.mapWidth = '100%'
    }
  }

  /**
   * User clicks close on right hand size of map. Closing the info component (map-nav).
   * Reset values that needs to be reset. Cancel edit of area for MSA if such is present.
   */
  closeNavBtn() {
    this.mapNavOpen = false
    this.mapNavHandler()
    this.markersInsidePolygon = Array();
    this.editingMode = false;
    this.editMsaMode = false;
    this.showAllMetersClickedId = Array();
    if (this.msaMode && this.currentLayer) this.currentLayer.disableEdit()
  }

  showAllMeters() {
      this.allWorkorderGroup.clearLayers()
      this.filteringOptions.forEach(element => {
        if (element.status !== 0) element.checkboxValue = false
      });
      this.mapNavOpen = false
      this.mapNavHandler()
      if (!this.msaMode) this.showAllToggle = true
      this.getWorkOrders(null, false)

  }

  /**
   * This method subscribes to an http request method in our service that fetches all ordergroups and sets them as markers.
   * This method gets and sets all ordergroups.
   */
  getOrderGroups(): any {

    this.showAllToggle = false
    this.clearAllMarkers()
    this.mapNavOpen = false
    this.mapNavHandler()
    // subscribe to service
    this.workOrderService.getOrderGroups()
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(
      data => {
        if (data) {
          if(data.length > 0) {
                let lonCent = ''
                let latCent = ''

            try {
                lonCent = JSON.parse(data[0].coordinates!).lon
                latCent = JSON.parse(data[0].coordinates!).lat
              } catch (error) {
                this.map.locate({
                  setView : true,
                  maxZoom: 9
                });
              }

            // TODO: DOES NOT DRAW MAP IF BACKEND FAILS OR DOESNT RETURN ANY ORDERS
            if(!this.mapInitialized) this.map.setView([latCent, lonCent], 6)

            this.mapInitialized = true

            let msaIds = Array();
            let colors = Array();
            for (let x = 1; x <= 15; x++) {
              colors.push([x, null])
            }


            // Loop through data to set markers
            for (let i=0; i < data.length; i++) {

              let lon = ''
              let lat = ''

              // Try catch coordinates, every point in data might not have these
              try {
                lon = JSON.parse(data[i].coordinates!).lon
                lat = JSON.parse(data[i].coordinates!).lat
              } catch (error) {
                continue
              }

              let markerId = data[i].id;
              let markerIdentifier = data[i].identifier;
              let markerLocation = new L.LatLng(lat, lon);
              let marker
              let msaId = '';
              if(!data[i].msa_id) {
                marker = new L.Marker(markerLocation, {icon: this.mapService.boltIcon}).on('click', this.orderGroupClick)
              } else {
                let correctColor;
                msaId = data[i].msa_id!.toString();
                let correctMsaId;
                // If we find msaId from msaIds array save it to correctMsaId variable
                for (let j = 0; j < msaIds.length; j++) {
                  if (msaIds[j] == msaId) correctMsaId = msaIds[j];
                }
                msaIds.push(msaId);
                let iconName;
                // If we found msaId from msaIds array perform this
                if (correctMsaId) {
                  // Loop through colors array to check if msaId is set some color alreydy
                  // If it is then save iconName as colors number
                  for (let z = 0; z < colors.length; z++) {
                    if (colors[z][1] == correctMsaId) {
                      iconName = "boltIcon"+colors[z][0]
                      correctColor = colors[z][0];
                    }
                  }
                }
                // If we didn't find msaId from msaIds array perform this
                else {
                  let boolean = false;
                  // Loop through colors array to set msaId to first available color
                  for (let n = 0; n < colors.length; n++) {
                    if (colors[n][1] == null && boolean == false) {
                      colors[n][1] = msaId;
                      boolean = true;
                      iconName = "boltIcon"+colors[n][0];
                      correctColor = colors[n][0];
                    }
                  }
                }
                let orderGroupName = "msaGroup" + correctColor + ".addLayer(marker)"
                this[orderGroupName]
                //msaId = data[i].msa_id!.toString()

                //let iconName = "boltIcon"+msaId

                /*
                if(Number(msaId) > 15) {
                  iconName = "boltIcon10"
                }
                */
                //TODO: {icon: this[iconName]} <--- vanha tyyli ei toimi enään ??
                //marker = new L.Marker(markerLocation, {icon: this[iconName]}).on('click', this.orderGroupClick)
                marker = new L.Marker(markerLocation, {icon: this.mapService.boltIcon}).on('click', this.orderGroupClick)
              }

              // Adds custom feature to our marker
              marker.feature = {
                  type: 'Feature',
                  properties: {
                    id: markerId,
                    msa: msaId,
                    tag: 'ordergroup',
                    identifier: markerIdentifier
                  },
                  geometry: undefined
              }
              marker.on('dragend', this.onDragEnd)

              this.ordergroupGroup.addLayer(marker)
            }
          }
        } else {
          console.error(data)
        }
      }
    )
  }

  /**
   * This method subscribes to an http request method in our service that fetches all ordergroups and sets them as markers.
   * This method will set all workorders.
   */
  getWorkOrders(id, makeDraggable): any {
    if(id) this.groupId = id
    this.markersForFiltering = []
    this.filteringOptions[0].amount = 0
    this.filteringOptions[1].amount = 0
    this.filteringOptions[2].amount = 0
    this.filteringOptions[3].amount = 0
    this.filteringOptions[4].amount = 0
    this.filteringOptions[5].amount = 0
    this.workorderSubscription.unsubscribe()
    this.workorderSubscription = this.workOrderService.getWorkOrders('ALL')
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(
      data => {
        // Declare msa ids array and colors array and add values to colors array
        let msaIds = Array();
        let colors = Array();
        for (let x = 1; x <= 15; x++) {
          colors.push([x, null])
        }
        let iconName;

        for (let index = 1; index < this.filteringOptions.length; index++) {
          const element = this.filteringOptions[index];
          element.amount = 0
        }
        // Loop through data to set markers
        for (let i=0; i < data.length; i++) {
          if(data[i]['state'] == 5) {
            continue // do not show on map
          }

          let lon = ""
          let lat = ""

          // Try catch coordinates, every point in data might not have these
          try {
            lon = JSON.parse(data[i].coordinates!).lon
            lat = JSON.parse(data[i].coordinates!).lat
          } catch (error) {
            continue
          }

          let timeEstim = []
          let msaTimeEstim: any = []

          if (data[i].time_parameters) {
            timeEstim = JSON.parse(data[i].time_parameters!)
          }

          if (data[i].msa_id) {
            if (this.allMsaEstimates[data[i].msa_id] != null && this.allMsaEstimates[data[i].msa_id] != '') {
              msaTimeEstim = JSON.parse(this.allMsaEstimates[data[i].msa_id])
              msaTimeEstim = msaTimeEstim[0]
            }
          }

          let projectDefaultEstimates

          try {
            if (this.projectEstimates) {
              projectDefaultEstimates = JSON.parse(this.projectEstimates)
            }
          } catch (error) {
            continue
          }

          let markerShape = null
          let markerColor = null
          let markerDots = null
          // This loop goes through project default time estimates to get possible shapes/colors of the marker
          for (let i in timeEstim) {
            const categoryid = timeEstim[i]['categoryid']
            const estimateid = timeEstim[i]['estimateid']

            for (let x in projectDefaultEstimates) {
              const element = projectDefaultEstimates[x];

              if (element['categoryid'] == categoryid) {
                const highlighted = projectDefaultEstimates[x]['highlighted']

                for (let index = 0; index < projectDefaultEstimates[x]['estimates'].length; index++) {
                  const timeEstimate = projectDefaultEstimates[x]['estimates'][index];

                  if (timeEstimate['estimateid'] == estimateid) {

                    if (highlighted == 'shape') {
                      markerShape = timeEstimate['attribute']

                    } else if (highlighted == 'color') {
                      markerColor = timeEstimate['attribute']

                    } else if (highlighted == 'dots') {
                      markerDots = timeEstimate['attribute']
                    }
                  }
                }
              }
            }
          }

          // This  goes through msa time estimates to get possible shapes/colors of the marker, and overwrites any shapes and colors that we set above
          if (data[i].msa_id && msaTimeEstim !== undefined && !Array.isArray(msaTimeEstim) && typeof msaTimeEstim === 'object') {
              for (let i in timeEstim) {
                const categoryid = timeEstim[i]['categoryid']
                const estimateid = timeEstim[i]['estimateid']

                if (msaTimeEstim['categoryid'] == categoryid) {
                  const highlighted = msaTimeEstim['highlighted']
                  if (msaTimeEstim['estimates']) {
                  for (let index = 0; index < msaTimeEstim['estimates'].length; index++) {
                    const timeEstimate = msaTimeEstim['estimates'][index];

                    if (timeEstimate['estimateid'] == estimateid) {

                      if (highlighted == 'shape') {
                        markerShape = timeEstimate['attribute']

                      } else if (highlighted == 'color') {
                        markerColor = timeEstimate['attribute']

                      } else if (highlighted == 'dots') {
                        markerDots = timeEstimate['attribute']
                      }
                    }
                  }
                  }
                }
              }

          }

          // Gets our marker icon from getGeneratedSvg method, returns default icon if null values are passed
          let svgString = this.mapService.getGeneratedSvg(markerShape, markerColor, markerDots)
          const markerIcon = L.divIcon({
            html: svgString,
            className: "",
            iconSize: [this.orderSize, this.orderSize],
            iconAnchor: [this.orderAnchor, this.orderAnchor],
            shadowSize: [this.orderShadowSize, this.orderShadowSize]
          });

          let markerId = data[i].id;
          let oldMeterId = data[i].old_device_id;
          let markerLocation = new L.LatLng(lat, lon)
          let marker
          let inGroup
          let groupId
          let msaId: any
          let ordergroupId;
          let identifier;

          // Sets marker specific data into the markers
          if(data[i].ordergroup_id == null) {
            marker = new L.Marker(markerLocation, {icon: markerIcon}).on('click', this.workOrderClick)
            inGroup = false
            groupId = null

          } else if (data[i].msa_id == null && data[i].ordergroup_id == id) {
            marker = new L.Marker(markerLocation, {icon: markerIcon}).on('click', this.workOrderClick)
            inGroup = true
            groupId = data[i].ordergroup_id

          } else if (id && data[i].ordergroup_id == id) {
            groupId = data[i].ordergroup_id
            let correctColor;
            // kyseiselle orderille kuuluvat setit
            if (data[i].ordergroup_id != null) ordergroupId = data[i].ordergroup_id!.toString()
            else ordergroupId = null;
            let correctMsaId;
            // If we find msaId from msaIds array save it to correctMsaId variable
            for (let j = 0; j < msaIds.length; j++) {
              if (msaIds[j] == ordergroupId) correctMsaId = msaIds[j];
            }
            msaIds.push(ordergroupId);

            // If we did find msaId from msaIds array perform this
            if (correctMsaId) {
              // Loop through colors array to check if msaId is set some color alreydy
              // If it is then save iconName as colors number
              for (let z = 0; z < colors.length; z++) {
                if (colors[z][1] == correctMsaId) {
                  iconName = "orderWithIdIcon"+colors[z][0]
                  correctColor = colors[z][0]
                }
              }
            }
            // If we didn't find msaId from msaIds array perform this
            else {
              let boolean = false;
              let correctColor;
              // Loop through colors array to set msaId to first available color
              for (let n = 0; n < colors.length; n++) {
                if (colors[n][1] == null && boolean == false) {
                  colors[n][1] = ordergroupId;
                  boolean = true;
                  correctColor = colors[n][0]
                  iconName = "orderWithIdIcon"+colors[n][0];

                }
              }

            }
            let orderGroupName = "msaGroup" + correctColor + ".addLayer(marker)"
            this[orderGroupName]
            marker = new L.Marker(markerLocation, {icon: markerIcon}).on('click', this.workOrderClick)
            inGroup = true
            groupId = data[i].ordergroup_id

          } else if (id && data[i].ordergroup_id != id) {
            // skips current loop iteration if we are looking for orders  with a specific id
            continue

          } else {

            if (data[i].msa_id) {
              let orderGroupName;

              msaId = data[i].msa_id.toString()
              let correctMsaId;
              // If we find msaId from msaIds array save it to correctMsaId variable
              for (let j = 0; j < msaIds.length; j++) {
                if (msaIds[j] == msaId) correctMsaId = msaIds[j];
              }
              msaIds.push(msaId);
              // If we found msaId from msaIds array perform this
              if (correctMsaId) {
                // Loop through colors array to check if msaId is set some color alreydy
                // If it is then save iconName as colors number
                for (let z = 0; z < colors.length; z++) {
                  if (colors[z][1] == correctMsaId) iconName = "orderWithIdIcon"+colors[z][0]
                }
              }
              // If we didn't find msaId from msaIds array perform this
              else {
                let boolean = false;
                // Loop through colors array to set msaId to first available color
                for (let n = 0; n < colors.length; n++) {
                  if (colors[n][1] == null && boolean == false) {
                    colors[n][1] = msaId;
                    boolean = true;
                    iconName = "orderWithIdIcon"+colors[n][0];
                    orderGroupName = "msaGroup" + colors[n][0] + ".addLayar(marker)"
                    this[orderGroupName]
                  }
                }
              }
              marker = new L.Marker(markerLocation, {icon: markerIcon}).on('click', this.workOrderClick)

            } else {
              marker = new L.Marker(markerLocation, {icon: markerIcon}).on('click', this.workOrderClick)

            }

            inGroup = true
            groupId = data[i].ordergroup_id
          }
          // adds identifier if exists
          if (data[i].identifier) {
            identifier = data[i].identifier
          }
          // Adds custom feature to our marker
          marker.feature = {
            type: 'Feature',
            properties: {
              id: markerId,
              oldMeterId: oldMeterId,
              groupId: groupId,
              identifier: identifier,
              address: data[i].address,
              contact: data[i].contact_info,
              notes: data[i].notes,
              locationNumber: data[i].location_number,
              inGroup: inGroup,
              tag: 'workorder',
              time_estimate: timeEstim,
              msaId: data[i].msa_id,
              status: data[i].status
            },
        }
        let status = data[i].status
        if (status === 2) this.filteringOptions[1].amount++
        else if (status === 3) this.filteringOptions[2].amount++
        else if (status === 4) this.filteringOptions[3].amount++
        else if (status === 5) this.filteringOptions[4].amount++
        else if (status === 6) this.filteringOptions[5].amount++

        this.filteringOptions[0].amount++
        this.allWorkorderGroup.addLayer(marker)
        /*
        if(data[i].ordergroup_id == null) {
          this.workorderNoIdGroup.addLayer(marker)
        } else {
          this.workorderGroup.addLayer(marker)
        }
        */
        this.markersForFiltering.push(marker)


          marker.on('dragend', this.onDragEnd)


        } // For loop ends


        if(makeDraggable) this.makeMarkerDraggable()
        if(this.positionEditor) this.makeMarkerDraggable()
      }
    )
  }

  /**
   * This method handles user click when he clicks on a ordergroup marker.
   * Gets the markers custom properties such as id which we can use to fetch workorders that belong to that ordergroup id.
   * @param e
   */
  orderGroupClick = (e) => {
     if (!this.msaMode && !this.positionEditor) {
      this.msaMode = false
      this.clearWorkOrders()
      this.filteringOptions[0].checkboxValue = true
      this.clearFilter(true)
      this.meterId = null
      this.positionEditor = false
      this.grouppingEditor = true
      this.groupping = true
      this.mapNavOpen = true
      this.mapNavHandler()
      let selectedId = e.target.feature.properties.id
      this.getWorkOrders(selectedId, false)
      this.identifier = e.target.feature.properties.identifier
      this.selectedOrderGroupId = selectedId
     }
  }

  /**
   * This method handles user click when he clicks on a workorder marker.
   * Gets all the properties from the workorder and sets them to these variables so that the map-nav can be injected with them.
   * @param e
   */
  workOrderClick = (e) =>  {
    this.currentWorkorder = e;


    this.emptyNavItems()



    if (this.mapNavOpen) {
      this.MapNav.cancelEditMode()
    }

    if (!this.msaMode && !this.positionEditor) {

      this.ordergroupGroup.eachLayer((layer) => {
        if (layer.feature.properties.id === e.target.feature.properties.groupId) {
          this.identifier = layer.feature.properties.identifier
          this.selectedOrderGroupId = layer.feature.properties.id
        }
      });

        // Map sidebar child component stuff
      this.meterId = e.target.feature.properties.id
      //this.identifier = e.target.feature.properties.identifier
      this.oldMeterId = e.target.feature.properties.oldMeterId
      this.address = e.target.feature.properties.address
      this.contact = e.target.feature.properties.contact
      this.phone = e.target.feature.properties.phone
      this.locationNumber = e.target.feature.properties.locationNumber
      if (e.target.feature.properties.notes != null) this.notes = e.target.feature.properties.notes
      else this.notes = null;
      this.groupStatus = e.target.feature.properties.inGroup

      if(e.target.feature.properties.time_estimate) {
        let entries = Object.values(e.target.feature.properties.time_estimate)
        this.orderTimeEstimate = entries
      } else {
        this.orderTimeEstimate = null
      }
  if (e.target.feature.properties.msaId) {
        this.getMsaInfo(e.target.feature.properties.msaId, () =>  {
          this.mapNavOpen = true
          this.mapNavHandler()
        })
      } else {
        this.msaEstimates = null;
        this.mapNavOpen = true;
        this.mapNavHandler()
      }
      this.selectedOrder = e.target



    }

    if(e.target.feature.properties.groupId && !this.grouppingEditor) {
      this.groupId = e.target.feature.properties.groupId
      this.groupping = false
    } else if (!this.grouppingEditor) {
      this.groupping = false
    }
    //this.addOrRemoveFromGroup(e.target.feature.properties.id, e.target.feature.properties.inGroup, e)
  }

  /**
   * This is a method that is called from the map-nav.
   * It is called when a user wishes to group/ungroup a certain workorder.
   * Changes the workorder icon to make the change visual.
   */
  updateOrdergroupId () {
    if (!this.selectedOrder.feature.properties.inGroup) {
      Swal.fire({
        title: this.translateService.instant('planner.mapView.updateMsaConfirmation'),
        text: this.translateService.instant('planner.mapView.updateMsaText'),
        showCancelButton: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.workOrderService.updateOrdergroupInOrder(this.meterId, this.groupId, this.groupStatus)
          if(this.selectedOrder.feature.properties.inGroup) {
            this.selectedOrder.feature.properties.inGroup = false
            this.groupStatus = false
          } else {
            this.selectedOrder.feature.properties.inGroup = true
            this.groupStatus = true
          }
        }
      })
    } else {
      this.workOrderService.updateOrdergroupInOrder(this.meterId, this.groupId, this.groupStatus)
      if(this.selectedOrder.feature.properties.inGroup) {
        this.selectedOrder.feature.properties.inGroup = false
        this.groupStatus = false
      } else {
        this.selectedOrder.feature.properties.inGroup = true
        this.groupStatus = true
      }
    }
  }

  /**
   * Toggles the editor mode which enables dragging of map markers.
   * positionEditor boolean determines if the editor mode is enabled.
   * @param e
   */
  toggleEditor = (e) => {
    this.grouppingEditor = false
    this.mapNavOpen = false
    this.closeNavBtn()
    if(e == 'edit') {
      this.positionEditor = !this.positionEditor
      this.clearAllMarkers()
      this.getOrderGroups()
    } else if (e == 'cancel') {
      this.positionEditor = !this.positionEditor
      this.clearAllMarkers()
      this.workorderUpdateQueue = []
      this.ordergroupUpdateQueue = []
    }
  }

  /**
   * Checks if user is editing positions.
   * Goes through each layer of markers and sets dragging accordingly.
   */
  makeMarkerDraggable() {
    if (this.positionEditor) {

      // Enables dragging for all 3 clustergroups
      this.ordergroupGroup.eachLayer((layer) => {
        if (layer instanceof L.Marker && layer.dragging) {
          layer.dragging.enable();
        }
      })
      this.allWorkorderGroup.eachLayer((layer) => {
        if (layer instanceof L.Marker && layer.dragging) {
          layer.dragging.enable();
        }
      })
    } else if (!this.positionEditor) {

      // Disables dragging for all clustergroups
      this.ordergroupGroup.eachLayer((layer) => {
        if (layer instanceof L.Marker && layer.dragging) {
          layer.dragging.disable();
        }
      })
      this.allWorkorderGroup.eachLayer((layer) => {
        if (layer instanceof L.Marker && layer.dragging) {
          layer.dragging.disable();
        }
      })
    }
  }

  /**
   * Fires each time a map marker drag event ends.
   * Gets the drag target properties and passes them to positionUpdater.
   * positionUpdater sets the markers new location.
   * @param e
   */
  onDragEnd = (e) => {
      let targetId = e.target.feature.properties.id
      let targetTag = e.target.feature.properties.tag
      let latlng = e.target.getLatLng();
      if (!this.msaMode) this.positionUpdater(latlng, targetId, targetTag)

      let targetIcon = e.target.options.icon.options.iconUrl
      let targetShadow = e.target.options.icon.options.shadowUrl
      let targetHtml = e.target.options.icon.options.html
      let size = 42
      let shadowSize = 45

      if (targetIcon) {
        e.target.setIcon(icon({
          iconUrl: targetIcon,
          shadowUrl: targetShadow,
          iconSize: [size, size],
          shadowSize: [shadowSize, shadowSize],
          iconAnchor: [size/2, size/2]
        }))
      } else {
        var myIcon = L.divIcon({
          html: targetHtml,
          className: "divIconMoved",
          iconSize: [size, size],
          iconAnchor: [size/2, size/2],
          shadowSize: [shadowSize, shadowSize]
        })
        e.target.setIcon(myIcon)
      }

  };

  /**
   * If statement sorts the updatable marker by its tag.
   * Calls searchAndRemoveExisting() to check if the marker has been dragged multiple times so that we get the most recent change.
   * Pushes the change to UpdateQueue.
   * @param latlng
   * @param id
   * @param tag
   */
  positionUpdater(latlng, id, tag) {
    let arrey = [id, latlng]
    if (tag == 'workorder') {
      this.searchAndRemoveExisting(id, this.workorderUpdateQueue, tag)
      this.workorderUpdateQueue.push(arrey)
    } else if (tag == 'ordergroup') {
      this.searchAndRemoveExisting(id, this.ordergroupUpdateQueue, tag)
      this.ordergroupUpdateQueue.push(arrey)
    }
  }

  /**
   * Checks if the corresponding id exists in the array and removes it from there if it exists.
   * This way we only have the most recent onDragEnd change in the array which we send to backend.
   * @param nameKey
   * @param myArray
   * @param tag
   */
  searchAndRemoveExisting(nameKey, myArray, tag) {
    for (var i=0; i < myArray.length; i++) {
      if (myArray[i][0] === nameKey) {
        if (tag == 'workorder') {
          this.workorderUpdateQueue.splice(i,1)
        } else if (tag == 'ordergroup') {
          this.ordergroupUpdateQueue.splice(i,1)
        }
      }
    }
  }

  // Clear only workorders
  // And reset map nav values
  clearWorkOrders() {
    this.allWorkorderGroup.clearLayers()
    this.notes = null;
    this.address = null;
    this.locationNumber = null
  }

  // Clear all markers
  clearAllMarkers() {
    this.allWorkorderGroup.clearLayers()
    this.ordergroupGroup.clearLayers()
  }

  /**
   * When the user is ready with position changes, 'save changes' button calls this method.
   * This method loops through the position changes (a user could have made multiple position changes) and makes patch requests to the backend.
   */
  async savePositionUpdateQueue() {
    this.positionEditor = !this.positionEditor
    this.clearAllMarkers()

    let workQueue = this.workorderUpdateQueue.length
    let groupQueue = this.ordergroupUpdateQueue.length

    for (var i=0; i < this.workorderUpdateQueue.length; i++) {
      if (!--workQueue){
        this.workOrderService.updateWorkOrderPosition(this.workorderUpdateQueue[i][0], this.workorderUpdateQueue[i][1])
        this.getOrderGroups()
      } else {
        this.workOrderService.updateWorkOrderPosition(this.workorderUpdateQueue[i][0], this.workorderUpdateQueue[i][1])
      }
    }
    for (var i=0; i < this.ordergroupUpdateQueue.length; i++) {
      if (!--groupQueue) {
        this.workOrderService.updateOrderGroupPosition(this.ordergroupUpdateQueue[i][0], this.ordergroupUpdateQueue[i][1])
        this.getOrderGroups()
      } else {
        this.workOrderService.updateOrderGroupPosition(this.ordergroupUpdateQueue[i][0], this.ordergroupUpdateQueue[i][1])
      }

    }
    this.ordergroupUpdateQueue = []
    this.workorderUpdateQueue = []
  }


  /**
   * Function that gets called upon when createNewMSA button is clicked in interface.
   * Make event handler for when MSA-area is created. In event handler save current MSA-area in
   * currentLayer variable. Give it MSA id. Add it to editableLayers adding it to map.
   */
  createNewMSA() {
    this.showCancelCreate = true
    this.drawEnabled = new L.Draw.Polygon(this.map, this.drawControl.options.polygon);
    this.drawEnabled.enable()
    if (this.drawCreatedCalled == false) {
    this.map.on('draw:created', (e) => {
      this.showCancelCreate = false
      this.currentLayer = e.layer
      this.tempAddedLayers.push(this.currentLayer)
      if (this.multipleMsas == true) {this.currentLayer.color = this.multipleMsasColor}
      else {
      let color = this.MSAColors[Math.floor(Math.random() * this.MSAColors.length)]
      this.multipleMsasColor = color;
      this.currentLayer.color = color;
      for (let i = 0; i < this.MSAColors.length; i++) {
        if (color == this.MSAColors[i]) this.MSAColors.splice(i, 1)
      }
      }
      e.layer.options.color = this.currentLayer.color;
      this.editableLayers.addLayer(this.currentLayer, {color:this.currentLayer.color});
      document.getElementById('saveMsa')?.click()
      this.drawCreatedCalled = true
     });
    }
  }

  cancelDrawing() {
    this.drawEnabled.disable()
    this.showCancelCreate = false
  }



  /**
   * Function that is used to determine if a point resides inside given polygon
   * @param point location of point in map in coordinates
   * @param vs polygon area that we use to check if it has point inside it
   * @returns boolean value true/false depending is the point inside polygon
   */
  inside(point, vs) {
    // ray-casting algorithm based on
    // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html/pnpoly.html

    let x = point[0], y = point[1];

    let inside = false;
    for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
        let xi = vs[i][0], yi = vs[i][1];
        let xj = vs[j][0], yj = vs[j][1];

        let intersect = ((yi > y) != (yj > y))
            && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }
    return inside;
  };

  // Return ordergroups's layers
  getAllOrderGroups():any[] {
    return this.ordergroupGroup.getLayers()
  }

  // Return workorders's layers
  getAllWorkorders() {
    //let workorders = {...this.workorderGroup._featureGroup._layers, ...this.workorderNoIdGroup._featureGroup._layers}
    return this.allWorkorderGroup.getLayers()
  }

  /**
   * Function that does something depending on user button press on pop up window
   * "Yes" sends MSA points to database
   * "No" removes MSA area
   * "Continue" sends current layer to variable currentMSALayers
   * @param decision input to determine what action to take in function
   */
  overlay(decision) {
    // Save MSA to database when clicked "Yes"
    if (decision == 'yes' && this.editableLayers.hasLayer(this.currentLayer)) {
        if (this.currentMSALayers.length > 0) {
          this.currentMSALayers.push(this.currentLayer);
          // let finalMSAPoints = Array()
          for (let j = 0; j < this.currentMSALayers.length; j++) {
            for (let i = 0; i < this.currentMSALayers[j].getLatLngs()[0].length; i++) {
              this.finalMSAPoints.push(this.currentMSALayers[j].getLatLngs()[0][i].lat)
              this.finalMSAPoints.push(this.currentMSALayers[j].getLatLngs()[0][i].lng)
            }
            if (j < this.currentMSALayers.length - 1) this.finalMSAPoints.push(":");
          }
          document.getElementById('nameForMsa')?.click()
          this.currentMSALayers = Array()
        }
        else {
          this.finalMSAPoints = Array();
          document.getElementById('nameForMsa')?.click()
        }
        this.multipleMsas = false;
    }
    // Remove MSA when pressed "No"
    if (decision == 'no') {
      this.MSAColors.push(this.currentLayer.color)
      if (this.currentMSALayers.length > 0) {
        this.currentMSALayers.push(this.currentLayer);

        for (let i = 0; i < this.currentMSALayers.length; i++) {
          this.editableLayers.removeLayer(this.currentMSALayers[i]._leaflet_id);
        }
        this.currentMSALayers = Array();
      }
      else {
        this.editableLayers.removeLayer(this.currentLayer._leaflet_id);
      }
      this.multipleMsas = false;
    }
    // Add drawn MSA area to variable currentMSALayers
    if (decision == 'continue') {
      this.currentMSALayers.push(this.currentLayer);
      this.multipleMsas = true;
      new L.Draw.Polygon(this.map, this.drawControl.options.polygon).enable();
    }
 }

 /**
  * Sets correct values to variable markersInsidePolygon to show them at sidebar
  * @param e clicked msa area
  */
 msaClick = (e) => {
  this.mapNavSpinner = true;
  if (this.subscriptionsLayer.length > 0) this.subscriptionsLayer = [];
  // Cancel editing before continuing onClick function
  if (this.editingMode) this.editingMode = false
  if (this.editAreaBoolean) this.cancelEditMsa()
  if (this.showEditDateRange) this.showEditDateRange = false
  this.currentLayer = e.layer;
  this.getMsaInfo(this.currentLayer.msaId, () => {
      this.mapNavOpen = true
      this.mapNavHandler()
      this.editMsaMode = true
  })



}

/**
 * Get MSAs from database with api call and draw them on the map if they have coordinates properly assigned
 */
 getMsas() {

  this.workOrderService.getMsas()
  .pipe(takeUntil(this.componentDestroyed$))
  .subscribe(
    data => {
      let polygons = Array()
      this.allMsas = data;
      // Loop through data occurences
      for (let i = 0; i < data.length; i++) {
        // Data has ":" inside if it has more than one MSA-areas

        if (data[i].coordinates && data[i].coordinates.includes(':')) {
          let msaAreaColor;
          if (data[i].color) msaAreaColor = data[i].color;
          else msaAreaColor = '#808080'
          let msaArea = data[i].coordinates.split(':')
          // Loop through every MSA-area
          for (let z = 0; z < msaArea.length; z++ ) {
            let correctFormMsaArea;
            // If last character of msaArea is ',' and first character is not ','
            // Then remove last character
            if (msaArea[z].substring(msaArea[z].length - 1, msaArea[z].length) == ',' && msaArea[z].substring(0, 1) != ',') {
              correctFormMsaArea = msaArea[z].slice(0, -1)
            }
            // If first character is ',' and last character is not ','
            // Then remove first character
            if (msaArea[z].substring(0, 1) == ',' && msaArea[z].substring(msaArea[z].length - 1, msaArea[z].length) != ',') {
              correctFormMsaArea = msaArea[z].slice(1, msaArea[z].length);
            }
            // If both first and last character of string is ','
            // Then remove both characters
            if (msaArea[z].substring(0, 1) == ',' && msaArea[z].substring(msaArea[z].length - 1, msaArea[z].length) == ',') {
              let firstSlice = msaArea[z].slice(1, msaArea[z].length);
              correctFormMsaArea = firstSlice.slice(0, -1);
            }
            let arrayOfCoordinates = correctFormMsaArea.split(',');
            let latLngs = Array()
            for (let j = 0; j < arrayOfCoordinates.length; j++) {
              let coordinates = [arrayOfCoordinates[j], arrayOfCoordinates[j + 1]]
              latLngs.push(coordinates)
              j++;
            }
            // Add one polygon to polygons variable
            let currentPolygon = L.polygon(latLngs, {color:msaAreaColor})
            currentPolygon.msaId = data[i].id;
            currentPolygon.hasMultipleMsaAreas = true;
            currentPolygon.color = msaAreaColor
            polygons.push(currentPolygon)
          }
          // Remove used color from colors list
          for (let y = 0; y < this.MSAColors.length; y++) {
            if (this.MSAColors[y] == msaAreaColor) this.MSAColors.splice(y, 1);
          }
        }

        // Perform if data.coordinates has only one MSA-area
        if (data[i].coordinates && !data[i].coordinates.includes(":")) {
          let string = data[i].coordinates;
          let arrayOfCoordinates = string.split(',');
          let latLngs = Array()
          let msaAreaColor;
          if (data[i].color) msaAreaColor = data[i].color;
          else msaAreaColor = '#808080'
          // Loop through occurrences of coordinates
          for (let j = 0; j < arrayOfCoordinates.length; j++) {
            let coordinates = [arrayOfCoordinates[j], arrayOfCoordinates[j + 1]]
            latLngs.push(coordinates)
            j++;
          }
          // Add polygon to polygons variable
          let currentPolygon = L.polygon(latLngs, {color:msaAreaColor})
          currentPolygon.msaId = data[i].id;
          currentPolygon.hasMultipleMsaAreas = false;
          currentPolygon.color = msaAreaColor
          polygons.push(currentPolygon)
          // Remove used color from colors list
          for (let k = 0; k < this.MSAColors.length; k++) {
            if (this.MSAColors[k] == msaAreaColor) this.MSAColors.splice(k, 1);
          }
        }
      }
      // Loop through polygons to draw them all to map
      for (let layer of polygons) {
        this.editableLayers.addLayer(layer);
      }
    }
  )

 }

 /**
  * Builds a key/value pair object from msa id:s and their default time-parameters
  */
 getMsaEstimates() {
  this.workOrderService.getMsas().subscribe(
    data => {
      for (let i = 0; i < data.length; i++) {
        this.allMsaEstimates[data[i].id] = data[i].default_time_parameters
      }
    })
 }

 /**
  * Open up a dialog to ask for name and upload msaArea to database when clicked save on dialog
  * If clicked 'cancel', close dialog
  * @param action input for right action in function, if it's 'save' perform msaArea uploading to database
  */
 confirmOverlay(action) {

   if (action == 'save') {
     this.datesWrong = false
     // If it has multiple msa areas use this for uploading
     if (this.finalMSAPoints.length > 0) {
      let msaIdFromApiCall: null | number = null
      this.workOrderService.createMsaArea(this.finalMSAPoints, this.currentLayer.color, this.msaName, this.MSADateStartCreate, this.MSADateEndCreate)
      .pipe(
        finalize(() => {
          if (msaIdFromApiCall) {
            this.workOrderService.getMsa(msaIdFromApiCall).subscribe(
              data => this.allMsas.push(data)
            )
          }
        }),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(
        msaId => {
          msaIdFromApiCall = msaId
          for (let i = 0; i < this.tempAddedLayers.length; i++) {
            this.tempAddedLayers[i].hasMultipleMsaAreas = true;
            this.tempAddedLayers[i].msaId = msaId;
          }
          this.updateTransformersWorkordersMsa(msaId, true, false)
          this.tempAddedLayers = Array();
        }
      )


     }
     // If msa area has only one msa area inside it use this statement to upload
     else {
      let latLngs = Array()
      // Loop through points of current msa area and save them to variable latLngs
      for (let i = 0; i < this.currentLayer.getLatLngs()[0].length; i++) {
          latLngs.push(this.currentLayer.getLatLngs()[0][i].lat)
          latLngs.push(this.currentLayer.getLatLngs()[0][i].lng)
      }
      let startDate = new Date(this.MSADateStartCreate!)
      let endDate = new Date(this.MSADateEndCreate!)
      if (startDate.getTime() >= endDate.getTime()) this.datesWrong = true
      if (!this.datesWrong) {
        this.workOrderService.createMsaArea(latLngs, this.currentLayer.color, this.msaName, this.MSADateStartCreate, this.MSADateEndCreate)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(
          msaId => {
            this.currentLayer.hasMultipleMsaAreas = false;
            this.currentLayer.msaId = msaId;
            this.updateTransformersWorkordersMsa(msaId, true, false)
          }
        )
      }
      this.tempAddedLayers = Array();
    }
    this.finalMSAPoints = Array()
    if (!this.datesWrong)this.msaName = null;
    this.MSADateStartCreate = null;
    this.MSADateEndCreate = null
    if (!this.datesWrong) document.getElementById('closeModal')?.click()
  }

  if (action == 'cancel') {
    if (this.tempAddedLayers.length > 0) {
      this.map.eachLayer((layer) => {
        for (let i = 0; i < this.tempAddedLayers.length; i++) {
          if (layer == this.tempAddedLayers[i]) {
            this.editableLayers.removeLayer(layer)
          }
        }
      });
    } else {
      this.editableLayers.removeLayer(this.currentLayer)
    }
    this.finalMSAPoints = Array()
    this.tempAddedLayers = Array();
    this.currentLayer = null;

  }



  }

  /**
   * Update MSA ID for workorders and ordergroups (transformers), when this is called.
   * @param msaId MSA ID, which is to be used in update
   */
  updateTransformersWorkordersMsa(msaId: number | null, askForPermission: boolean, loadMsaInfo: boolean) {
    const observables: Observable<boolean>[] = []
    const ordergroups = this.getAllOrderGroups()
    const workorders = this.getAllWorkorders();
    this.setMarkersInsidePolygon(this.currentLayer.hasMultipleMsaAreas, ordergroups, true, () => {
      this.markersInsidePolygon.map(ordergroup => {
        observables.push(this.workOrderService.updateTransformerMsaId(ordergroup.feature.properties.id, msaId))
        ordergroup.feature.properties.msa = msaId
      })
    })

    this.markersInsidePolygon = []
    this.setMarkersInsidePolygon(this.currentLayer.hasMultipleMsaAreas, workorders, true, () => {
      this.markersInsidePolygon.map(workorder => {
        observables.push(this.workOrderService.updateWorkorderMsaId(workorder.feature.properties.id, msaId))
        workorder.feature.properties.msa = msaId
      })
    })
    if (askForPermission && observables.length > 0) {
      Swal.fire({
        title: this.translateService.instant('planner.mapView.confirmOverrideSaveTitle'),
        text: this.translateService.instant('planner.mapView.confirmOverrideSaveText'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.translateService.instant('basic.yes'),
        cancelButtonText: this.translateService.instant('basic.no')
      }).then(result => {
        if (result.isConfirmed) {
          forkJoin(observables).subscribe({
            next: () => {
              if (loadMsaInfo) this.getMsaInfo(this.currentLayer.msaId, () => {})
              this.toastService.sendToast(true, this.translateService.instant('basic.success'))
            },
            error: error => {
              this.toastService.sendToast(false, this.translateService.instant('basic.failed'))
              console.log('Error occurred:', error)
            }
          });
        }
      });
    } else if(observables.length > 0) {
      forkJoin(observables).subscribe({
        next: data => this.toastService.sendToast(true, this.translateService.instant('basic.success')),
        error: error => {
          this.toastService.sendToast(false, this.translateService.instant('basic.failed'))
          console.log('Error occurred:', error)
        }
      });
    } else this.getMsaInfo(this.currentLayer.msaId, () => {})
  }

  showAllMetersFromMapNav(value) {
    this.clearWorkOrders()
    let selectedId = value.clickedId
    this.getWorkOrders(selectedId, false)
  }

  editMSA() {
    this.editingMode = true;
  }

  /**
   * Handle cancel clicking on interface on MSA-page
   */
  cancelEditMsa() {
    this.editingMode = false
    this.msaDateStartEdit = null
    this.msaDateEndEdit = null
    if (this.editAreaBoolean == true) {
      this.editAreaBoolean = false;
      this.currentLayer.disableEdit();
    }
  }

  /**
   * Delete MSA when clicking 'Delete MSA' button in interface.
   * Send 'delete' api call to backend and remove MSA from database.
   * Then remove it from map with removeLayer method.
   */
  deleteMsa() {
    document.getElementById('showMsaCalendarButton')?.click()
    this.closeNavBtn()
    this.editingMode = false;
    this.editMsaMode = false;

  }
  /**
   * Update transformers and workorders msa ids to NULL and remove MSA.
   * Ask user before updating transformers and workorders
   * Remove MSA from map.
   * Push used color to available colors list.
   */
  deleteMsaFinal() {
    this.getMsaInfo(this.currentLayer.msaId, () => {
      this.updateTransformersWorkordersMsa(null, false, false)
    })

    this.MSAColors.push(this.currentLayer.color)

    if (this.currentLayer.hasMultipleMsaAreas) {
      this.map.eachLayer((layer) => {
        if (layer.msaId == this.currentLayer.msaId) {
          this.editableLayers.removeLayer(layer)
        }
      });
    } else this.editableLayers.removeLayer(this.currentLayer);

    this.workOrderService.removeMsa(this.currentLayer.msaId);
  }


  /**
   * Function that is called when user modifies MSA's dates and presses save
   * Checks first if end date is same or less than start date, if it is show error message
   * If it is not save changes to database. Can save null values to date for MSA
   */
  saveChangesMsaDates() {
    let dateStart;
    let dateEnd;
    if (this.msaDateStartEdit) {
      dateStart = new Date(this.msaDateStartEdit)
    }
    if (this.msaDateEndEdit) {
      dateEnd = new Date(this.msaDateEndEdit)
    }
    if (this.msaDateEndEdit && this.msaDateStartEdit) {
      if (dateStart.getTime() >= dateEnd.getTime()) this.toastService.sendToast(false, this.translateService.instant('planner.mapView.scheduleError'))
      else {
        this.saveChangesFinalMsaDates()
      }
    } else {
      this.saveChangesFinalMsaDates()
    }
  }

  /**
   * Actual saving function of saveChangesMsaDates. Is called when dates have been checked for validation
   */
  saveChangesFinalMsaDates() {
    if (this.msaDateEndEdit != this.msaEndDateToMapNav || this.msaDateStartEdit != this.msaStartDateToMapNav) {
      this.workOrderService.updateMsaDates(this.msaDateStartEdit, this.msaDateEndEdit, this.currentLayer.msaId)
      this.msaStartDateToMapNav = this.msaDateStartEdit
      this.msaEndDateToMapNav = this.msaDateEndEdit
    } else {
      this.toastService.sendToast(false, this.translateService.instant('planner.mapView.datesAreNotDifferent'))
    }
    if (this.showEditDateRange) this.showEditDateRange = false

  }

  cancelChangesMsaDates() {
    this.msaDateStartEdit = this.msaStartDateToMapNav
    this.msaDateEndEdit = this.msaEndDateToMapNav
    this.showEditDateRange = false
  }

  /**
   * Enable editing for correct MSA-area
   */
  editArea() {
    this.editAreaBoolean = true;
    this.editableLayers.eachLayer((layer) => {
      if (this.currentLayer.msaId == layer.msaId) this.originalLayersEdit.push(layer)
    })
    for (let i = 0; i < this.allMsas!.length; i++) {
      if (this.allMsas![i].id == this.currentLayer.msaId) this.selectedMsaCoordinates = this.allMsas![i].coordinates
    }
    this.currentLayer.enableEdit();
    this.previousLayerLatLngs = this.currentLayer.getLatLngs();
    this.setTransformersInsidePolygonToPreviousLayer();
  }

  /**
   * Get layers coordinates and check all markers of map through that are they inside give polygon with
   * inside() function
   * Upgrade:
   * Could be useful to get only markers that are inside polygon so we wouldnt have to check
   * for every marker are they inside polygon which is big job
   */
  setMarkersInsidePolygon(hasMultipleMsaAreas, markers, reset: boolean,  cb) {
    // Run if clicked msa area has only one msa area inside it
    if (hasMultipleMsaAreas == false) {
      let polygonLatLngs = this.currentLayer.getLatLngs()
      let polygon = Array()
      if (reset) this.markersInsidePolygon = Array();

      // Loop through coordinates of msa area
      for (let i = 0; i < polygonLatLngs[0].length; i++ ) {
        polygon.push([polygonLatLngs[0][i].lat, polygonLatLngs[0][i].lng])
      }


        // Loop through all markers to check if marker is inside polygon
        let keys = Object.keys(markers)
        keys.forEach((key, index) => {
          let marker = [markers[key]._latlng.lat, markers[key]._latlng.lng]
          if (this.inside(marker, polygon)) {
            this.markersInsidePolygon.push(markers[key])
          }
        });
      cb()
    }
    // Run if msa area has multiple msa areas inside it
    if (hasMultipleMsaAreas == true) {
      let multipleMsaLayers = Array();
      this.markersInsidePolygon = Array();
      // Loop through map layers and check if msa id is same as layers msa id
      // If it is push layer to multiple msa layers
      this.editableLayers.eachLayer ((layer) => {
        if (layer.msaId == this.currentLayer.msaId) {
          multipleMsaLayers.push(layer.getLatLngs())
        }
      })
      // Loop through every msa area inside msa
      for (let x = 0; x < multipleMsaLayers.length; x++) {
        let polygonLatLngs = multipleMsaLayers[x];
        let polygon = Array();

        // Loop through coordinates of single msa area
        for (let z = 0; z < polygonLatLngs[0].length; z++ ) {
          polygon.push([polygonLatLngs[0][z].lat, polygonLatLngs[0][z].lng])
        }
        // Loop through markers to check if marker is inside single msa area
        let keys = Object.keys(markers)
        keys.forEach((key, index) => {
          let marker = [markers[key]._latlng.lat, markers[key]._latlng.lng]
          if (this.inside(marker, polygon)) {
            this.markersInsidePolygon.push(markers[key])
          }
        });

      }
      cb()
    }
  }

  setMsaName() {
    this.workOrderService.getMsa(this.currentLayer.msaId)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(
      msaData => {
        this.msaTitle = msaData.name
        this.msaStartDateToMapNav = msaData.schedule_start
        this.msaEndDateToMapNav = msaData.schedule_end
        this.msaDateEndEdit = this.msaEndDateToMapNav
        this.msaDateStartEdit = this.msaStartDateToMapNav
      }
    )
  }



  /**
   * Get project time window's before setting time window's for workorders
   * Fetch only project estimates if they don't exist
   * @param marker workorder to set time to
   */
  setTimeWindow(marker) {
    if (this.projectEstimates) {
      this.setProjectTime(marker)
    }
    else {
      this.plannerProjectService.getProjectById(this.projectId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          this.projectEstimates = data.default_time_parameters;
          this.setProjectTime(marker)
        }
      )
    }
  }


  // TODO: Better naming of the function
  /**
   * Fires when user clicks done button after editing MSA-area.
   * Disable editing for current layer(MSA-area)
   * Go through markers and add them to markersFromOtherMSA variable if they are from other MSA-area.
   * Use sweetalert to fire pop up for user to make a decision.
   * -------
   * SAVE CHANGES TO DATABASE
   * Save changes button clicked in interface on MSA-page.
   * Send api call to updateMsaArea with correct coordinates and msa id. Updating coordinates to MSA-area
   * Set editing mode to false so that header bar displays correct items after saving
   * Loop through transformers that are inside msa-area and save their new msa ids to database if user decided to do that.
   *
   *
   * -------
   * 7.5.2024
   * Renamed with more descripted function name. Called when user selects MSA area then proceeds to edit it's bounds (area).
   * After completing area modifications, area is saved in MSA coordinates. Then user is asked to save transformers inside it to edited MSA.
   */
  msaAreaReadjustedAndClickedDone() {
    this.mapNavSpinner = true;
    this.editAreaBoolean = false;
    this.currentLayer.disableEdit();
    this.saveCoordinatesReadjustingMSA()
  }

  saveCoordinatesReadjustingMSA() {
    let multipleMsas = false
    if (this.selectedMsaCoordinates?.includes(":")) multipleMsas = true
    if (multipleMsas) {
      let foundLayers = ""
      this.editableLayers.eachLayer((layer) => {
        if (layer.msaId == this.currentLayer.msaId) {
          for (let i = 0; i < layer.getLatLngs()[0].length; i++) {
            foundLayers = foundLayers.concat(layer.getLatLngs()[0][i].lat + ",")
            foundLayers = foundLayers.concat(layer.getLatLngs()[0][i].lng + ",")
            if (i == layer.getLatLngs()[0].length - 1) foundLayers = foundLayers.concat(":,")
          }
        }
      })
      foundLayers = foundLayers.slice(0, foundLayers.length - 3)
      this.workOrderService.updateMsaArea(foundLayers, this.currentLayer.msaId)
      this.updateTransformersWorkordersMsa(this.currentLayer.msaId, true, true)
      this.editingMode = false;
    } else {
      let latLngs = Array()
      // Loop through points of current msa area and save them to variable latLngs
      for (let i = 0; i < this.currentLayer.getLatLngs()[0].length; i++) {
          latLngs.push(this.currentLayer.getLatLngs()[0][i].lat)
          latLngs.push(this.currentLayer.getLatLngs()[0][i].lng)
      }
      this.workOrderService.updateMsaArea(latLngs, this.currentLayer.msaId)
      this.updateTransformersWorkordersMsa(this.currentLayer.msaId, true, true)
      this.editingMode = false;
    }

  }

  /**
   * Update all msaIds for markers that are outside of MSA-area that are inside newly drawn area. Depending on decision we change
   * only undefined to correct msaId or all msaIds to correct msaId
   * @param decision decision that we use to make correct code path. Options are Yes and No
   */
  saveDecision(decision) {
    if (decision == 'Yes') this.decisionForSavingMsaTransformers = true;
    if (decision == 'No') this.decisionForSavingMsaTransformers = false;

  }

  calculateTimeEstimates(project_default_times, time_parameters, msa_default_times) {
    let time = 0
    if (msa_default_times != null) {
      for (let i = 0; i < time_parameters.length; i++) {
        for (let index = 0; index < time_parameters[i].length; index++) {
          const timeParamsMsa = time_parameters[i]
          for (let dex = 0; dex < msa_default_times.length; dex++) {
            for (let x = 0; x < timeParamsMsa.length; x++){
              const elementMsa = timeParamsMsa[x];

              if (elementMsa.primary = msa_default_times[dex].type) {
                for (let y = 0; y < msa_default_times[dex].estimates.length; y++) {
                  const estimateMsa = msa_default_times[dex].estimates[y]

                  if (elementMsa.secondary == estimateMsa.name) {
                    time = time + estimateMsa.time
                  }
                }
              }
            }
          }
        }
      }
    }

    if (msa_default_times == null) {
      for (let i = 0; i < time_parameters.length; i++) {
        // Go through workorders
        for (let index = 0; index < time_parameters[i].length; index++) {
          // Go through time estimates of each work order
          const timeParams = time_parameters[i]
          for (let dex = 0; dex < project_default_times.length; dex++) {
            // Go through project default time estimates
            for (let x = 0; x < timeParams.length; x++) {
              // Go through the length of a time estimate of one work order
              const element = timeParams[x];

              if (element.primary == project_default_times[dex].type) {

                for (let y = 0; y < project_default_times[dex].estimates.length; y++) {
                  // Go through the lenght of default_time_estimates estimates so we can match workorder and project time estimates
                  const estimate = project_default_times[dex].estimates[y];

                  if(element.secondary == estimate.name) {
                    // If we have a match add it to time
                    time = time + estimate.time
                  }
                }
              }
            }
          }
        }
      }
    }
    return time;
  }

  setTransformersInsidePolygonToPreviousLayer() {
    let polygonLatLngs = this.previousLayerLatLngs
    let polygon = Array()
    this.transformersInsidePolygonPreviousLayer = Array();

    for (let i = 0; i < polygonLatLngs[0].length; i++ ) {
      polygon.push([polygonLatLngs[0][i].lat, polygonLatLngs[0][i].lng])
    }


    for (let i = 0; i < this.allOrdergroups.length; i++) {
      let marker = [this.allOrdergroups[i]._latlng.lat,  this.allOrdergroups[i]._latlng.lng];
      if (this.inside(marker, polygon)) {
        this.transformersInsidePolygonPreviousLayer.push(this.allOrdergroups[i]);
      }
    }
  }

  /**
   * Gets all information of current project and inserts values to variables.
   * After the subscribe completes, calls other methods to run.
   */
  getProjectInfo() {
    this.getCurrentProjectId(() =>
      this.plannerProjectService.getProjectById(this.projectId)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe({
          next: data => {
            this.projectEstimates = data.default_time_parameters
            this.allEstimates = data.all_estimates
          },
          error: error => {}
        })
    )
  }

  /**
   * Gets current project id and calls a callback method to tell that it has finished
   * @param cb
   */
     getCurrentProjectId(cb) {
      this.userService.getUserInfo()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          this.projectId = data.current_project
          // cb() = callback to let the other function know that this one is finished
          cb()
        }
      );
    }

    /**
     * Get msa information and set msa estimates for further usage. Get msa time parameters if they exist
     * @param id given msa id
     */
  getMsaInfo(id, callback) {
    this.workOrderService.getMsa(id)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          this.msaTitle = data.name
          this.msaStartDateToMapNav = data.schedule_start
          this.msaEndDateToMapNav = data.schedule_end
          this.msaDateEndEdit = this.msaEndDateToMapNav
          this.msaDateStartEdit = this.msaStartDateToMapNav

          this.searchAllMarkers(data.ordergroups, data.work_orders)

          if (data.default_time_parameters) {
            this.msaEstimates = data.default_time_parameters;
          }
          callback()
        }
      )
  }

  searchAllMarkers(ordergroups, workorders) {

    const allOrdergroups = this.getAllOrderGroups()
    const allWorkorders = this.getAllWorkorders()

    this.markersInsidePolygon = []
    let ordergroupKeys = Object.keys(allOrdergroups)
    ordergroupKeys.forEach((key, index) => {
      if (ordergroups.includes(allOrdergroups[key].feature.properties.id)) {
        allOrdergroups[key].workorder = false
        allOrdergroups[key].workorders = []
        this.markersInsidePolygon.push(allOrdergroups[key])
      }
    });

    let workorderKeys = Object.keys(allWorkorders)
    workorderKeys.forEach((key, index) => {
      if (workorders.includes(allWorkorders[key].feature.properties.id)) {
        allWorkorders[key].workorder = true
        if (allWorkorders[key].feature.properties.groupId) {
          let ordergroup = this.markersInsidePolygon.find(marker => marker.feature.properties.id === allWorkorders[key].feature.properties.groupId)
          if (ordergroup && ordergroup.feature.properties.tag === 'ordergroup') {
            ordergroup.workorders.push(allWorkorders[key])
          }
        }
        this.setTimeWindow(allWorkorders[key]);
        this.markersInsidePolygon.push(allWorkorders[key])
      }
    })
    this.mapNavSpinner = false
  }

  setProjectTime(marker) {
    this.workOrderService.getWorkOrder(marker.feature.properties.id).subscribe({
      next: data =>  {
          marker.earliest = data.time_window_start
          marker.latest = data.time_window_end
          marker.time = data.time_estimate
      }
    })
  }

  emptyNavItems() {
    this.meterId = null
    this.identifier = null
    this.address = null
    this.contact = null
    this.phone = null
    this.notes = null
    this.locationNumber = null
    this.groupStatus = false
    this.orderTimeEstimate = null
  }


  /**
   * Function that has been emitted from map nav component
   * We change current work orders notes to match saved notes
   * @param notes Current notes that has been saved by user in map nav editing mode for meter
   */
  notesChanged(notes) {
    this.currentWorkorder.target.feature.properties.notes = notes.tempNotes;
    this.notes = notes.tempNotes;
  }

  /**
   * Function that has been emitted from map nav component
   * We change current work orders location number to match temp location number
   * @param locationNumber Current locationNumber that has been saved by user in map nav editing mode for meter
   */
   locationNumberChanged(locationNumber) {
    this.currentWorkorder.target.feature.properties.locationNumber = locationNumber.tempLocationNumber;
    this.locationNumber = locationNumber.tempLocationNumber;
  }

  /**
   * Function that has been emitted from map nav component
   * We change current work orders address to match saved address
   * @param address Current address that has been saved by user in map nav editing mode for meter
   */
   addressChanged(address) {
    this.currentWorkorder.target.feature.properties.address = address.tempAddress;
    this.address = address.tempAddress;
  }

  // Toggle edit date range according to it being open or closed
  editDateRange() {
    if (!this.showEditDateRange) this.showEditDateRange = true
    else this.showEditDateRange = false
  }


  filterMap(number: number, init: boolean) {
    if (!init) this.filteringOptions[number].checkboxValue = !this.filteringOptions[number].checkboxValue;

    // Creates statuses array, where checkboxValue is true
    let statuses: number[] = this.filteringOptions
      .filter(option => option.checkboxValue && option.status !== 0)
      .map(option => option.status);

    // Assuming `workorderGroup` and `workorderNoIdGroup` are your MarkerClusterGroups
    // Clear the current markers from the cluster groups
    //this.workorderGroup.clearLayers();
    //this.workorderNoIdGroup.clearLayers();
    this.clearWorkOrders()
    if (number === 0) {
      if (this.filteringOptions[0].checkboxValue) {
        this.markersForFiltering.forEach(marker => {
          this.allWorkorderGroup.addLayer(marker)
          /*
          if (marker.ordergroup_id === null) { // Assuming you can distinguish markers somehow
            this.workorderNoIdGroup.addLayer(marker);
          } else {
            this.workorderGroup.addLayer(marker);
          }
          */
        });
      }
      this.filteringOptions.forEach(element => {
        if (element.status !== 0) element.checkboxValue = false
      });
    } else {
      this.filteringOptions[0].checkboxValue = false
      // Iterate over all markers to decide which to add back based on the filter
      this.markersForFiltering.forEach(marker => {
        if (statuses.includes(marker.feature.properties.status)) {
          // Conditionally add markers back to their respective cluster groups based on some property
          /*
          if (marker.ordergroup_id === null) { // Assuming you can distinguish markers somehow
            this.workorderNoIdGroup.addLayer(marker);
          } else {
            this.workorderGroup.addLayer(marker);
          }
          */
         this.allWorkorderGroup.addLayer(marker)
        }
      });
    }

  }

  hasMultipleCheckboxesSelected(): boolean {
    const selectedOptions = this.filteringOptions.filter(option => option.checkboxValue);
    return selectedOptions.length > 1;
  }

  clearFilter(resetMarkers: boolean) {
    if (resetMarkers) this.markersForFiltering = []
    this.filteringOptions.forEach(element => {
      if (element.status !== 0) element.checkboxValue = false
    });
    this.clearWorkOrders()
  }

  checkTotalAmount(): number {
    const amount = this.filteringOptions
      .filter(option => option.checkboxValue) // Filter options where checkboxValue is true
      .map(option => option.amount) // Extract the amount of each filtered option
      .reduce((acc, current) => acc + current, 0); // Sum up all the amounts

    return amount;
  }


}

interface FilteringOption {
  amount: number
  description: string
  checkboxValue: boolean
  status: number
}

