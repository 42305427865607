<div class="page-container">
    <div class="page-content p-3">
        <load-spinner *ngIf="spinner"></load-spinner>
        <div *ngIf="spinner" class="d-flex">{{'basic.loading' | translate}}</div>
        <div *ngIf="!spinner" [ngSwitch]="true">

            <!-- Valokuitunen -x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x- -->
            <!-- Danish water meters -x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x- -->
            <!-- Danish heat meters -x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x- -->
            <!-- Electric meters -x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x-x- -->
            <div>
                <button type="button" class="btn btn-sm btn-secondary"
                        (click)="navigateToReports()">{{'planner.reportWorkorderDetail.backToReports' | translate}}</button>
                <h4 class="mt-2">{{'basic.workorder' | translate}} {{workorder.old_device_id}}</h4>

                <!-- First square - Report details -->
                <div class="row">
                    <div class="col-md-6 border-right">
                        <h5>{{'planner.reportWorkorderDetail.report' | translate}}</h5>

                        <!-- Check for interruption reason or if any images exist -->
                        <div *ngIf="interrupted" class="red-text">
                            <div *ngIf="interruptDefinition">
                                <div class="fw-bold">{{'planner.reports.interruptCode' | translate}}</div>
                                <div>{{interruptDefinition}} ({{workorder.interrupt_code}})</div>
                            </div>
                            <div class="fw-bold">{{'basic.reasonForInterruption' | translate}}</div>
                            <div [hidden]="!workorder.interrupt_reason">{{workorder.interrupt_reason}}</div>
                            <div [hidden]="workorder.interrupt_reason" class="red-text">
                                {{'planner.reportWorkorderDetail.noInterruptReason' | translate}}</div>
                        </div>

                        <!-- Check for work order images -->
                        <div *ngIf="workorderImages.length > 0" class="fw-bold">
                            {{'planner.reportWorkorderDetail.workorderPictures' | translate}}
                        </div>
                        <div>
                            <load-spinner *ngIf="imageSpinner"></load-spinner>
                            <div class="row row-cols-auto" *ngIf="!imageSpinner">
                                <div class="col" *ngFor="let image of workorderImages">
                                    {{image.image_place}}
                                    <br>
                                    <img alt="{{image.title}}" [src]="image.url" class="thumbnail" (click)="selectedImage = image.url">
                                </div>
                            </div>
                        </div>

                        <!-- Display if there's no interruption reason or images, and report time is set -->
                        <div *ngIf="!interrupted && workorderImages.length === 0 && !workorder.report_time" class="text-muted">
                            {{'planner.reportWorkorderDetail.notReportedYet' | translate}}
                        </div>
                    </div>
                    <!-- Second square -->
                    <div class="col-md-6">
                        <div class="fw-bold">{{'calendar.calendar' | translate}}</div>
                        <div>{{calendarName}}</div>
                        <div class="col-4">
                            <div class="fw-bold">{{'basic.timeConfirmed' | translate}}</div>
                            <div *ngIf="currentSlot">{{currentSlot.starttime | date: 'dd.MM.YYYY HH:mm'}} - {{currentSlot.endtime |
                                date: 'dd.MM.YYYY HH:mm'}} </div>
                            <div *ngIf="!currentSlot">{{'planner.reportWorkorderDetail.noSlot' | translate}}</div>
                            <div *ngIf="workorder.time_confirmed" >
                                <img class="dp24" src="assets/icons/Time confirmed.svg">
                                <span class="green-text">{{'fieldReporting.dashboard.timeConfirmed' | translate }}</span>
                            </div>
                            <div *ngIf="!workorder.time_confirmed">
                                <img class="dp24" src="assets/icons/Time not confirmed.svg">
                                <span class="red-text">{{'fieldReporting.dashboard.timeNotConfirmed' | translate }}</span>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="fw-bold">{{'basic.address' | translate}}</div>
                            <div [hidden]="!workorder.address">{{workorder.address}}</div>
                            <div class="red-text" [hidden]="workorder.address">{{'workers.noAddress' | translate}}</div>
                        </div>
                        <div class="col-8 row">
                            <div class="col-6" *ngFor="let enduser of workorder.enduser_id">
                                <div class="fw-bold">{{'persontype.' + enduser.person_type | translate }}</div>
                                <div>{{enduser.contact_person_first}} {{enduser.contact_person_last}}</div>
                                <div>{{enduser.phone}}</div>
                                <div>{{enduser.email}}</div>
                            </div>
                            <div class="col-6" *ngIf="workorder.enduser_id.length == 0">
                                <div class="fw-bold">{{'planner.reportWorkorderDetail.endusers' | translate}}</div>
                                <div class="red-text">{{'planner.reportWorkorderDetail.noEndusers' | translate}}</div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="fw-bold">{{'basic.accessType' | translate}}</div>
                            <div [hidden]="!workorder.access_type">{{'accessTypes.'+workorder.access_type | translate }}</div>
                            <div [hidden]="workorder.access_type" class="red-text">
                                {{'planner.reportWorkorderDetail.noAccessType' | translate}}</div>
                        </div>
                        <div class="col-4">
                            <div class="fw-bold">{{'basic.accessTypeNotes' | translate}}</div>
                            <div [hidden]="!workorder.access_type_notes">{{workorder.access_type_notes}}</div>
                            <div [hidden]="workorder.access_type_notes" class="red-text">--</div>
                        </div>
                        <div class="col-4">
                            <div class="fw-bold">{{'basic.meterModel' | translate}}</div>
                            <div [hidden]="!workorder.model">{{workorder.model}}</div>
                            <div [hidden]="workorder.model" class="red-text">{{'planner.reportWorkorderDetail.noMeterModel' |
                                translate}}</div>
                        </div>
                        <div class="col-4">
                            <div class="fw-bold">{{'planner.reportWorkorderDetail.meterID' | translate}}</div>
                            <div [hidden]="!workorder.old_device_id">{{workorder.old_device_id}}</div>
                            <div [hidden]="workorder.old_device_id" class="red-text">{{'planner.reportWorkorderDetail.noID' |
                                translate}}</div>
                        </div>

                        <div class="fw-bold">{{'basic.additionalInformation' | translate}}</div>
                        <div [hidden]="!workorder.notes">{{workorder.notes}}</div>
                        <div [hidden]="workorder.notes" class="red-text">{{'planner.reportWorkorderDetail.noAdditionalInformation' |
                            translate}}</div>

                        <div class="fw-bold">{{'planner.reportWorkorderDetail.extraInfoCallingService' | translate}}</div>
                        <div [hidden]="!workorder.worker_notes">{{workorder.worker_notes}}</div>
                        <div [hidden]="workorder.worker_notes" class="red-text">{{'planner.reportWorkorderDetail.noExtraInfo' |
                            translate}}</div>
                    </div>
                </div>

                <hr style="border-top: 2px solid rgba(100, 100, 100, 1); width: 100%; margin: 20px 0;">

                <!-- Third and fourth Squares -->
                <div class="row">
                    <!-- Third square - Log details -->
                    <div class="border-right " [ngClass]="projectType === 6 ? 'col-md-6' : 'col-md-12'">
                        <h5>{{'planner.reportWorkorderDetail.workorderLog' | translate}}</h5>
                        <div *ngFor="let log of workorderLogs">
                            <div class="d-flex">
                                <div class="col-3">{{log.created | date: 'dd.MM.yyyy HH:mm'}}</div>
                                <div class="col-3 fw-bold">{{log.description}}</div>
                                <div class="col-3">{{'planner.reportWorkorderDetail.by' | translate}} 
                                    <span *ngIf="log.user_name">{{log.user_name}}</span>
                                    <span *ngIf="!log.user_name">{{'basic.noDetail' | translate}}</span>
                                </div>
                                <div class="col-3">{{'planner.reportWorkorderDetail.userID' | translate}} 
                                    <span *ngIf="log.user_id">{{log.user_id}}</span>
                                    <span *ngIf="!log.user_id">{{'basic.noDetail' | translate}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Fourth square (optional, only for alternative time slots) -->
                    <div class="col-md-6"  *ngIf="this.projectType === 6">
                        <h5>{{'planner.reportWorkorderDetail.alternativeTimeSlots' | translate}}</h5>
                        <!-- Show spinner when loading -->
                        <div *ngIf="isLoading" class="spinner-container">
                            <div class="loading-spinner"></div> <!-- Customize with your spinner -->
                        </div>

                        <div *ngIf="!isLoading">
                            <div *ngIf="timeSlots.length > 0; else noTimeSlotsTemplate" class="timeslot-container">
                                <div *ngFor="let slot of timeSlots; let i = index" (click)="selectSlot(i)"
                                     [class.selected]="slot.selected" class="timeslot">
                                    {{ slot.date | date: 'yyyy-MM-dd' }} {{ slot.time }}
                                </div>
                            </div>
                            <ng-template #noTimeSlotsTemplate>
                                <p>{{'planner.reportWorkorderDetail.noAlternativeTimeSlots' | translate}}</p>
                            </ng-template>
                        </div>

                        <!-- Confirm button -->
                        <button class="btn btn-primary mt-2" [disabled]="!isAnySlotSelected()"
                                (click)="confirmSelection()">Confirm</button>
                    </div>
                </div>
                <!-- Full-size Image Modal -->
                <div class="modal" *ngIf="selectedImage" (click)="selectedImage = null">
                    <div class="modal-content" (click)="$event.stopPropagation()">
                        <span class="close" (click)="selectedImage = null">&times;</span>
                        <img [src]="selectedImage" class="image-size-full">
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
