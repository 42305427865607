<div class="page-content">
    <div class="card">
        <div class="header">
            <div class="d-flex bottom-border">
                <div class="w-20 justify-content-center d-flex">
                    <img (click)="backToDashboard()" src="assets/icons/chevron_left_gray_24dp.svg">
                </div>
                <div class="w-80 d-flex justify-content-center">
                    <img class="image-size-edit" src="assets/icons/close_red_24dp.svg"><h6 class="page-title">{{'basic.interrupt' | translate}}</h6>
                </div>
                <div class="w-20"></div>
            </div>
        </div>
        <div class="card-body">
            <div id="tab-container">
                <div class="w-100">
                    <h6 class="w-100">{{'basic.reasonForInterruption' | translate}}</h6>
                    <input type="text" [ngClass]="!interruptReason && saveAdditional ? 'red-borders' : ''" class="form-control" [(ngModel)]="interruptReason">
                </div>
                <hr>
                <div class="w-100 mt-3">
                    <div class="w-100 d-flex cursor-pointer" (click)="interruptImage.click()">
                        <img src="assets/icons/kamera_blue.svg" class="w-10 camera-icon">
                        <p class="w-90 camera-text">{{'fieldReporting.interrupt.picture' | translate}}</p>
                        <input #interruptImage type="file" accept="image/*" (change)="onFileInput($event)" style="display:none;" />
                    </div>
                    <img class="thumbnail-img" *ngIf="interruptImgUrl" [src]="interruptImgUrl" height="50">
                </div>
                <hr>
                <div class="w-100">
                    <h6 class="w-100">{{'basic.additionalInformation' | translate}}</h6>

                    <textarea class="w-100 form-control" [(ngModel)]="additionalInfo"></textarea>
                </div>
                <hr>
                <div class="w-100 mt-3">
                    <div class="d-flex w-100">
                        <h6 class="left mr-3">{{'fieldReporting.shared.location' | translate}}</h6>
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" role="switch" id="locationSwitch" [(ngModel)]="location" (click)="locationCheck()">
                        </div>
                    </div>
                </div>
                <div id="interruptmap" [hidden]="!location"></div>
                <div *ngIf="!location && saveAdditional && ((latitude == undefined) || (longitude == undefined))" class="red-text">{{'fieldReporting.shared.locationError' | translate}}</div>
                <hr>
                <div class="w-100 mb-5">
                    <h6 class="w-100">{{'basic.installTime' | translate}}</h6>
                    <div class="d-flex" *ngIf="timeUntouched" (click)="makeTimeDirty()">
                        <div class="top-icon top-icon-chosen justify-content-center d-flex mr-3"><img style="height: 30px;" src="assets/icons/schedule_white_24dp.svg"></div>
                        <div class="top-icon mr-3 gray-text"><p class="install-time">{{installationDate.getDate()}}.{{installationDate.getMonth()+1}}.{{installationDate.getFullYear()}}</p></div>
                        <div class="top-icon gray-text"><p class="install-time">{{installHours}}:{{installMinutes}}</p></div>
                    </div>
                    <div class="d-flex" *ngIf="!timeUntouched">
                        <div class="timeTouched d-flex">
                            <div class="top-icon top-icon-chosen justify-content-center d-flex mr-3"><img style="height: 30px;" src="assets/icons/schedule_white_24dp.svg"></div>
                            <input type="date" [(ngModel)]="switchedDate" class="form-control dateHeight mr-3">
                        </div>

                        <input type="time" class="input-switched-time form-control" [(ngModel)]="switchedTime">

                    </div>
                    <p class="blue-text w-100 mt-3" *ngIf="!timeUntouched">
                        Reported at: <br>
                        {{installationDate.getDate()}}.{{installationDate.getMonth()+1}}.{{installationDate.getFullYear()}} {{installHours}}:{{installMinutes}}
                    </p>


                </div>
                <hr>
                <div class="dual-container w-100">
                    <button class="btn btn-secondary left w-40" (click)="backToDashboard()">{{'fieldReporting.shared.previous' | translate}}</button>
                    <div class="w-20"></div>
                    <button class="btn btn-primary left w-40" (click)="checkValues()">{{'basic.save' | translate}}</button>
                </div>

            </div>
        </div>
    </div>
</div>
