<footer>
    <div class="footer clearfix mb-0">
      <div class="float-start">
        <p>2021 &copy; Toinen Veli</p>
      </div>
      <div class="float-end">
        <!-- <p>STAR <span class="text-danger"><i class="bi bi-heart"></i></span> by <a href="https://toinenveli.fi/">Toinen Veli</a></p> -->
      </div>
  </div>
</footer>
