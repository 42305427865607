<div class="page-container">
    <div class="page-heading">
        <div class="title">

            <h3 class="ms-2">
                <img src="assets/icons/groups_black_24dp.svg" class="position-title">
                <span class="ms-2">{{'basic.teams' | translate}}</span>
            </h3>
        </div>
    </div>
    <div class="page-content">
        <div class="d-flex justify-content-center">
            <load-spinner *ngIf="pageSpinner"></load-spinner>
        </div>
        <div *ngIf="!pageSpinner">
            <!-- Back button -->
            <a *ngIf="customerId !== 1" id="back-button" (click)="backToTeams()" class="back-button p-1 ps-0">
                <img class="back-icon" src="assets/icons/chevron_left_gray_24dp.svg">
                <span class="font-size-small black-text">{{'planner.teamsDetail.backToTeams' | translate}}</span>
            </a>

            <!-- Title line -->
            <div class="mt-4 mb-3">
                <div *ngIf="!editMode" class="d-flex">
                    <div class="col-10">
                        <h4>{{teamName}}</h4>
                    </div>
                    <div class="col-2">
                        <button type="button" (click)="toggleEdit()" class="btn btn-secondary float-end me-3">
                            <img class="edit-icon" src="assets/icons/edit_gray_24dp.svg">
                            <span class="ms-1">{{'basic.edit' | translate}}</span>
                        </button>
                    </div>
                </div>
                <div *ngIf="editMode" class="d-flex">
                    <div class="col-7">
                        <input type="text" class="form-control max-content" [(ngModel)]="teamName">
                    </div>
                    <div class="col-3">
                        <button type="button" (click)="saveEdit()" class="btn btn-primary">{{'basic.save' | translate}}</button>
                        <button type="button" (click)="cancelEdit()" class="btn btn-secondary ms-1">{{'basic.cancel' | translate}}</button>
                    </div>
                    <div class="col-2">
                        <button type="button" (click)="deleteTeam()" class="btn btn-danger ms-3">{{'planner.teamsDetail.deleteTeam' | translate}}</button>
                    </div>
                </div>
            </div>

            <!-- Teams information line -->
            <div class="d-flex gray-text">
                <div class="col-4">
                    <div class="mb-1">{{'planner.teamsDetail.assignedToCalendars' | translate}}</div>
                    <div *ngIf="assignedCalendars.length > 0" class="calendar-list">
                        <div *ngFor="let calendar of assignedCalendars" class="text-dark fw-bold">
                            <div class="row">
                                <div class="col-5"><a href="/planner/weekly-planning-map/{{calendar.id}}">{{calendar.name}}</a></div>
                                <div class="col-7">({{calendar.startdate | date: 'dd.MM.yyyy'}} - {{calendar.enddate | date: 'dd.MM.yyyy'}})</div>
                                <hr>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="assignedCalendars.length == 0" class="red-text">{{'planner.teamsDetail.noAssigned' | translate}}</div>
                </div>
                <div class="col-3" *ngIf="this.customerId !== 3">
                    <div class="ps-4">
                        <div>{{'teams.overallEfficiency' | translate}}</div>
                        <div *ngIf="showEfficiency">
                            <app-ngx-circle-custom
                            [efficiency]="teamsOverallEfficiency"
                            ></app-ngx-circle-custom>
                        </div>
                    </div>
                </div>
                <div class="col-3" *ngIf="this.customerId !== 3">
                    <div>{{'planner.teamsDetail.teamsAdditionalInfo' | translate}}</div>
                    <div *ngIf="!editMode">
                        <div *ngIf="teamsAdditionalInformation" class="text-dark fw-bold">{{teamsAdditionalInformation}}</div>
                        <div *ngIf="!teamsAdditionalInformation" class="red-text">{{'planner.teamsDetail.noAdditional' | translate}}</div>
                    </div>
                    <div *ngIf="editMode">
                        <textarea class="form-control" [(ngModel)]="teamsAdditionalInformation"></textarea>
                    </div>
                </div>
            </div>

            <!-- All workers information line -->
            <div class="position-relative">
            <ul class="list-group mt-3 main-worker-list">
                <li class="gray-text list-group-item first-list-group-item">
                    <div class="d-flex ps-2">
                        <div class="row col-4">
                            <div class="col-1"></div>
                            <div class="col-11 ps-3">{{'basic.name' | translate}}</div>
                        </div>
                        <div class="row col-8">
                            <div class="col-3 title-worker-position">{{'planner.teamsDetail.workerID' | translate}}</div>
                            <div class="col-3">{{'basic.efficiency' | translate}}</div>
                            <div class="col-3">{{'basic.skills' | translate}}</div>
                        </div>
                    </div>
                </li>
                <li *ngFor="let worker of teamsWorkers; let i = index" class="list-group-item">
                    <div class="d-flex p-2 height-one-worker-line">
                        <div class="row col-4">
                            <div class="col-1">
                                <div class="rounded-pill">{{worker.initials}}</div>
                            </div>
                            <div class="col-10 ms-3">
                                <div class="padding-worker-name">
                                    <div class="fw-bold font-size-small">{{worker.firstname}} {{worker.lastname}}</div>
                                    <div *ngIf="worker.leadInstaller" class="gray-text">{{'planner.teamsDetail.leadInstaller' | translate}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row col-8">
                            <div class="col-3 fw-bold padding-worker-name">{{worker.id}}</div>
                            <div class="col-3">
                                <div class="position-progress">
                                    <app-ngx-circle-custom
                                    [efficiency]="worker.efficiencyNumber"
                                    ></app-ngx-circle-custom>
                                </div>
                            </div>
                            <div class="col-3">
                                <div *ngIf="worker.showSkills && worker.skills.length > 0" class="colors-skills" [ngClass]="i == teamsWorkers.length - 1 ? 'show-last-skills' : 'show-skills'">
                                    <div *ngFor="let skill of worker.skills">{{skill}}</div>
                                </div>
                                <div class="rounded-pill" (mouseenter)="worker.showSkills = true" (mouseleave)="worker.showSkills = false">{{worker.skills.length}} {{'basic.skills' | translate | lowercase}}</div>
                            </div>
                            <div class="col-3" *ngIf="this.customerId !== 3">
                                <button type="button" [ngClass]="worker.icon == 'assets/icons/close_white_24dp.svg' ? 'btn-danger' : 'btn-secondary'" class="btn font-size-small float-end" (click)="deleteWorker(worker.id)" (mouseover)="worker.icon = 'assets/icons/close_white_24dp.svg'" (mouseout)="worker.icon = 'assets/icons/close_gray_24dp.svg'">
                                    <img class="close-icon" [src]="worker.icon">
                                    <span>{{'basic.delete' | translate}}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            </div>
            <!-- Create team member -->
            <button type="button" class="btn btn-primary mt-3" data-bs-toggle="modal" data-bs-target="#addTeamMembers">
                <span class="fs-5">+</span>
                <span class="add-team-member ms-1 font-size-small">{{'planner.teamsDetail.addTeamMembers' | translate}}</span>
            </button>
            <!-- Modal team members -->
            <div class="modal fade" id="addTeamMembers" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-body p-4">
                            <h4>
                                <img src="assets/icons/groups_black_24dp.svg" class="image-reposition-groups">
                                <span class="ms-1">{{'planner.teamsDetail.addTeamMembers' | translate}}</span>
                            </h4>
                            <div>{{'planner.teamsDetail.selectWorkers' | translate}} ({{allWorkers.length}})</div>
                            <div *ngIf="allWorkers.length == 0" class="red-text">{{'workers.noWorkers' | translate}}</div>
                            <div class="all-workers-list">
                                <div *ngFor="let worker of allWorkers; let i = index" class="height-list" id="allWorkersList" [ngClass]="i == 0 ? 'workers-border-all' : 'workers-border-no-top' " >
                                    <div class="row">
                                        <div class="col-6">
                                            <app-checkbox
                                            (isChecked)="workerClicked(worker.id)"
                                            [text]="worker.firstname + ' ' + worker.lastname"
                                            [checkbox]="worker.checked"
                                            ></app-checkbox>
                                        </div>
                                        <div class="col-6">
                                            <app-ngx-circle-custom
                                            [efficiency]="worker.efficiencyNumber"
                                            ></app-ngx-circle-custom>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3" *ngIf="selectedWorkersList.length > 0">
                                <h6>{{'basic.selectedWorkers' | translate}}</h6>
                                <ul class="list-group selected-workers-list">
                                    <li *ngFor="let selectedWorker of selectedWorkersList; let i = index" class="py-2 ps-2 list-group-item" [ngClass]="i == 0 ? 'selected-workers-borders' : 'selected-workers-border-no-top'">
                                        <div class="row">
                                            <div class="blue-text col-1 mt-3">{{i + 1}}.</div>
                                            <div class="col-3 mt-3">{{selectedWorker.firstname}} {{selectedWorker.lastname}}</div>
                                            <div class="col-2">
                                                <app-ngx-circle-custom
                                                [efficiency]=selectedWorker.efficiencyNumber
                                                ></app-ngx-circle-custom>
                                            </div>
                                            <div class="col-4">
                                                <div *ngIf="selectedWorker.showSkills && selectedWorker.skills.length > 0" class="colors-skills" [ngClass]="i == selectedWorkersList.length - 1 ? 'show-last-skills' : 'show-skills'">
                                                    <div *ngFor="let skill of selectedWorker.skills">{{skill}}</div>
                                                </div>
                                                <div class="rounded-pill mt-2" (mouseenter)="selectedWorker.showSkills = true" (mouseleave)="selectedWorker.showSkills = false">{{selectedWorker.skills.length}} {{'basic.skills' | translate | lowercase}}</div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="mt-2">
                                <button type="button" class="btn btn-primary" (click)="saveAddTeamMembers()">{{'basic.save' | translate}}</button>
                                <button type="button" class="btn btn-secondary" (click)="cancelAddTeamMembers()" data-bs-dismiss="modal">{{'basic.cancel' | translate}}</button>
                                <button [hidden]="true" data-bs-dismiss="modal" id="closeModal"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
