<circle-progress
[percent]="efficiency"
[radius]="18"
[outerStrokeWidth]="4"
[innerStrokeWidth]="4"
[outerStrokeColor]="colorOuter"
[innerStrokeColor]="colorInner"
[space]="-4"
[animation]="true"
[animationDuration]="300"
[titleFontSize]="'13'"
[title]="efficiencyTitle"
[showUnits]=false
[titleColor]="colorOuter"
[titleFontWeight]="'600'"
></circle-progress>
