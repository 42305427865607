<div class="page-content">
    <div class="card">
        <div class="header">
            <div class="d-flex">
                <div class="w-20 justify-content-center d-flex">
                    <img (click)="backToDashboard()" src="assets/icons/chevron_left_gray_24dp.svg">
                </div>
                <div class="w-80 d-flex justify-content-center">
                    <img class="image-size-edit" src="assets/icons/edit_blue_24dp.svg"><h6 class="page-title">{{'fieldReporting.shared.currentMeter' | translate}}</h6>
                </div>
                <div class="w-20"></div>
            </div>
            <br>
            <nav class="nav justify-content-center">
                <a class="nav-link onethird" (click)="tabSwitch(1)" [ngStyle]="{'color': tabindex >= 1 ? '#0058ff' : '#707591', 'border-bottom': tabindex >= 1 ? '3px solid #0058ff' : '3px solid #707591'}">{{'fieldReporting.report.oldMeter' | translate}}</a>
                <a class="nav-link onethird" (click)="tabSwitch(2)" [ngClass]="secondTabDisabled ? 'a-disabled' : ''" [ngStyle]="{'color': tabindex >= 2 ? '#0058ff' : '#707591', 'border-bottom': tabindex >= 2 ? '3px solid #0058ff' : '3px solid #707591'}">{{'fieldReporting.shared.newMeter' | translate}}</a>
                <a class="nav-link onethird" (click)="tabSwitch(3)" [ngClass]="thirdTabDisabled ? 'a-disabled' : ''" [ngStyle]="{'color': tabindex >= 3 ? '#0058ff' : '#707591', 'border-bottom': tabindex >= 3 ? '3px solid #0058ff' : '3px solid #707591'}">{{'fieldReporting.report.additional' | translate}}</a>
            </nav>
        </div>
        <div class="card-body">
            <div id="tab-container">

                <!-- 1st TAB -->

                <ng-container id="tab1" *ngIf="tabindex == 1" class="d-flex">
                    <div class="w-100" [style.opacity]="meterTabOpacity">
                        <div class="w-100 d-flex cursor-pointer" (click)="img0.click()">
                            <img src="assets/icons/kamera_blue.svg" class="w-10 camera-icon">
                            <p class="w-90 camera-text small">{{'fieldReporting.report.surroundings' | translate}}</p>
                            <input [disabled]="!isMeterChecked" #img0 type="file" accept="image/*" (change)="handleImageUpload($event, 0)" style="display:none;" />
                        </div>
                        <img class="thumbnail-img" *ngIf="images[0].img" [src]="images[0].img" height="50">
                    </div>
                    <div *ngIf="!images[0].img && firstTabImgCheck" class="red-text">{{'fieldReporting.report.missingImage' | translate}}</div>
                    <hr>
                    <div class="w-100">
                        <h6 class="w-100">{{'basic.meterNumber' | translate}}</h6>
                        <load-spinner *ngIf="meterNumberSpinner"></load-spinner>
                        <div *ngIf="!meterNumberSpinner">
                            <div *ngIf="meterNumber != null" id="meterNumber">{{meterNumber}}</div>
                            <div *ngIf="meterNumber == null" id="meterNumberNull" class="red-text">{{'fieldReporting.report.noDeviceId' | translate}}</div>
                            <div class="mt-1">
                                <input type="checkbox" class="form-check-input" [(ngModel)]="meterNumberCheckbox" (click)="changeOpacity()">
                                <span class="ms-1 small">{{'fieldReporting.report.numberMatches' | translate}}</span>
                            </div>
                            <div class="red-text" *ngIf="meterNumberNotCheckedOnSave">{{'fieldReporting.report.notChecked' | translate}}</div>
                        </div>
                    </div>
                    <div [style.opacity]="meterTabOpacity">
                        <hr>
                        <div class="w-100">
                            <div class="w-100 d-flex cursor-pointer" (click)="img1.click()">
                                <img src="assets/icons/kamera_blue.svg" class="w-10 camera-icon">
                                <p class="w-90 camera-text">{{'fieldReporting.report.wiring' | translate}}</p>
                                <input [disabled]="!isMeterChecked" #img1 type="file" accept="image/*" (change)="handleImageUpload($event, 1)" style="display:none;" />
                            </div>
                            <img class="thumbnail-img" *ngIf="images[1].img" [src]="images[1].img" height="50">
                        </div>
                        <div *ngIf="!images[1].img && firstTabImgCheck" class="red-text">{{'fieldReporting.report.missingImage' | translate}}</div>

                        <hr>
                        <div class="w-100">
                            <h6 class="w-100">{{'fieldReporting.report.finalReading' | translate}}</h6>
                            <input type="number" class="form-control" placeholder="-" [disabled]="!isMeterChecked" [(ngModel)]="finalReadingValue">
                            <div *ngIf="rangeIsWrong && isMeterChecked" class="red-text">
                                <div>
                                    <span>{{'fieldReporting.report.readingRange1' | translate}}</span>
                                    <span>{{finalReadingRangeBottom}} - {{finalReadingRangeTop}}</span>
                                    <span> {{'fieldReporting.report.readingRange2' | translate}}</span>
                                </div>
                                <div *ngIf="!reasonSelected" class="red-text">{{'fieldReporting.report.reasonError' | translate}}</div>
                                <div ngbDropdown>
                                    <button class="btn btn-primary p-2 mt-2" type="button" id="dropdownRangeButton" ngbDropdownToggle>
                                        <span *ngIf="!reasonSelected" class="mt-2">Pick a reason</span>
                                        <span *ngIf="reasonSelected" class="mt-2">{{outOfRangeReason.name}}</span>
                                    </button>
                                    <div ngbDropdownMenu aria-labelledby="dropdownRangeButton">
                                        <div *ngFor="let reason of outOfRangeReasons">
                                            <button ngbDropdownItem type="button" (click)="selectReason(reason.id)">{{reason.name}}</button>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="showReasonsCustom">
                                    <label class="black-text" for="customReason">{{'fieldReporting.report.customReason' | translate}}</label>
                                    <input type="text" id="customReason" [(ngModel)]="customReason" [ngClass]="!customReason && saveOldMeter ? 'red-borders' : ''" class="form-control">
                                </div>
                            </div>
                            <div class="w-100 mt-3">
                                <div class="w-100 d-flex cursor-pointer" (click)="img2.click()">
                                    <img src="assets/icons/kamera_blue.svg" class="w-10 camera-icon">
                                    <p class="w-90 camera-text">{{'fieldReporting.report.oldMeterImageText' | translate}}</p>
                                    <input [disabled]="!isMeterChecked" #img2 type="file" accept="image/*" (change)="handleImageUpload($event, 2)" style="display:none;" />
                                </div>
                                <img class="thumbnail-img" *ngIf="images[2].img" [src]="images[2].img" height="50">
                            </div>
                            <div *ngIf="!images[2].img && firstTabImgCheck" class="red-text">{{'fieldReporting.report.missingImage' | translate}}</div>

                        </div>
                        <hr>
                        <div class="w-100">
                            <div class="dual-container">
                                <h6 class="left">{{'fieldReporting.report.mainFuse' | translate}}</h6>
                                <div class="right">
                                    <div class="form-check form-switch">
                                        <span>{{'fieldReporting.report.notAvailable' | translate}}</span>
                                        <input class="form-check-input" [disabled]="!isMeterChecked" (click)="notAvailableFuse()" type="checkbox" role="switch" id="fuseSwitch" [(ngModel)]="fuseNotAvailable">
                                      </div>
                                </div>
                            </div>
                            <div class="custom-slider mb-5" *ngIf="!fuseNotAvailable && sliderOptions">
                                <ngx-slider [(value)]="sliderValue" [options]="sliderOptions" (userChangeEnd)="checkSliderValue(sliderValue)"></ngx-slider>
                            </div>
                            <div *ngIf="sliderValue == sliderMaxValue" class="red-text mb-1">{{'fieldReporting.report.maxFuseValue' | translate}} {{sliderMaxValue -  1 }}.</div>
                            <button *ngIf="sliderValue == sliderMaxValue" class="btn btn-danger w-100" (click)="interrupt()"><img src="assets/icons/close_white_24dp.svg">{{'basic.interrupt' | translate}}</button>
                        </div>
                        <hr>
                        <div class="w-100">
                            <h6 class="w-100">{{'fieldReporting.report.mainFuseType' | translate}}</h6>
                            <div class="dual-container">
                                <div *ngFor="let fuseType of fuseTypes">
                                    <button class="btn" [ngClass]="selectedFuseType === fuseType ? 'btn-primary' : 'btn-secondary'" [disabled]="!isMeterChecked" id="Fusetype {{fuseType.id}}" (click)="selectFuseType(fuseType.id)" >{{fuseType.name}}</button>
                                </div>
                            </div>
                            <div *ngIf="fuseTypeNotSelectedOnSave && !isFuseTypeSelected" class="red-text">{{'fieldReporting.report.selectMainFuseType' | translate}}</div>
                            <div *ngIf="isFuseTypeSelected && selectedFuseType">
                                <div *ngIf="selectedFuseType.openCustom === true">
                                    <h6>{{'fieldReporting.report.fuseTypeName' | translate}}</h6>
                                    <input type="text" class="form-control" [ngClass]="!fuseType && saveOldMeter ? 'red-borders' : ''" [(ngModel)]="fuseType">
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="dual-container w-100">
                        <button class="btn btn-secondary left  previous-opacity" (click)="backToDashboard()">{{'fieldReporting.shared.previous' | translate}}</button>
                        <div class="w-20"></div>
                        <button class="btn btn-primary left w-40" [style.opacity]="meterTabOpacity" [disabled]="!isMeterChecked" (click)="checkValuesOldMeter()">{{'fieldReporting.report.next' | translate}}</button>
                    </div>

                </ng-container>

                <!-- 2nd TAB -->

                <ng-container id="tab2" *ngIf="tabindex == 2">
                    <div class="w-100">
                        <button class="btn btn-primary w-100" data-bs-toggle="modal" data-bs-target="#barcodeModal" (click)="toggleBarcodeScanner('newMeter', true)">
                            <img src="assets/icons/barcode_scanner_48.svg" class="barcode-icon">
                            <span class="ms-1">{{'fieldReporting.report.scanNewMeter' | translate}}</span>
                        </button>
                        <div class="w-100">
                            <h6 class="w-100">{{'fieldReporting.report.newMeterNumber' | translate}}</h6>
                            <input [ngClass]="!newMeterNr && newMeterOnSave ? 'red-borders' : ''" type="text" class="form-control" [(ngModel)]="newMeterNr">
                        </div>
                        <div class="w-100">
                            <h6 class="w-100">{{'fieldReporting.report.beginningReading' | translate}}</h6>
                            <input [ngClass]="!newMeterReading && newMeterOnSave ? 'red-borders' : ''" type="number" class="form-control" [(ngModel)]="newMeterReading">
                        </div>
                    </div>
                    <div class="w-100">
                        <div class="w-100 d-flex mt-3 cursor-pointer" (click)="img3.click()">
                            <img src="assets/icons/kamera_blue.svg" class="w-10 camera-icon">
                            <p class="w-90 camera-text">{{'fieldReporting.report.newWiring' | translate}}</p>
                            <input #img3 type="file" accept="image/*" (change)="handleImageUpload($event, 3)" style="display:none;" />
                        </div>
                        <img class="thumbnail-img" *ngIf="images[3].img" [src]="images[3].img" height="50">
                    </div>
                    <div *ngIf="!images[3].img && secondTabImgCheck" class="red-text">{{'fieldReporting.report.missingImage' | translate}}</div>

                    <hr>
                    <div class="w-100">
                        <div class="w-100 d-flex cursor-pointer" (click)="img4.click()">
                            <img src="assets/icons/kamera_blue.svg" class="w-10 camera-icon">
                            <p class="w-90 camera-text">{{'fieldReporting.report.newMeter' | translate}}</p>
                            <input #img4 type="file" accept="image/*" (change)="handleImageUpload($event, 4)" style="display:none;" />
                        </div>
                        <img class="thumbnail-img" *ngIf="images[4].img" [src]="images[4].img" height="50">
                    </div>
                    <div *ngIf="!images[4].img && secondTabImgCheck" class="red-text">{{'fieldReporting.report.missingImage' | translate}}</div>

                    <hr>
                    <div class="w-100">
                        <div class="w-100 d-flex cursor-pointer" (click)="img5.click()">
                            <img src="assets/icons/kamera_blue.svg" class="w-10 camera-icon">
                            <p class="w-90 camera-text">{{'fieldReporting.report.newMeterConsumption' | translate}}</p>
                            <input #img5 type="file" accept="image/*" (change)="handleImageUpload($event, 5)" style="display:none;" />
                        </div>
                        <img class="thumbnail-img" *ngIf="images[5].img" [src]="images[5].img" height="50">
                    </div>
                    <div *ngIf="!images[5].img && secondTabImgCheck" class="red-text">{{'fieldReporting.report.missingImage' | translate}}</div>
                    <hr>
                    <div class="d-flex w-100">
                        <div class="left form-check form-switch mr-3">
                            <input class="form-check-input" type="checkbox" role="switch" id="antennaSwitch" [(ngModel)]="antenna">
                        </div>
                        <h6>{{'fieldReporting.report.externalAntenna' | translate}}</h6>
                    </div>
                    <div class="w-100 mt-3" *ngIf="antenna">
                        <h5>{{'fieldReporting.report.antennaInfo' | translate}}</h5>
                        <button class="btn btn-primary w-100" data-bs-toggle="modal" data-bs-target="#barcodeModal" (click)="toggleBarcodeScanner('antenna', true)">
                            <img src="assets/icons/barcode_scanner_48.svg" class="barcode-icon">
                            <span class="ms-1">{{'fieldReporting.report.scanAntenna' | translate}}</span>
                        </button>
                        <div class="w-100 mt-4">
                            <h6 class="w-100">{{'fieldReporting.report.antennaModel' | translate}}</h6>
                            <input [ngClass]="!antModel && newMeterOnSave ? 'red-borders' : ''" type="text" class="form-control" [(ngModel)]="antModel">
                        </div>
                        <div class="w-100">
                            <h6 class="w-100">{{'fieldReporting.report.manufacturer' | translate}}</h6>
                            <input [ngClass]="!antManufacturer && newMeterOnSave ? 'red-borders' : ''" type="text" class="form-control" [(ngModel)]="antManufacturer">
                        </div>
                        <div class="w-100">
                            <h6 class="w-100">{{'fieldReporting.report.modelNumber' | translate}}</h6>
                            <input [ngClass]="!antModelNr && newMeterOnSave ? 'red-borders' : ''" type="text" class="form-control" [(ngModel)]="antModelNr">
                        </div>
                        <div class="w-100">
                            <h6 class="w-100">{{'fieldReporting.report.serialNumber' | translate}}</h6>
                            <input [ngClass]="!antSerialNr && newMeterOnSave ? 'red-borders' : ''" type="text" class="form-control" [(ngModel)]="antSerialNr">
                        </div>
                        <div class="w-100">
                            <h6 class="w-100">{{'fieldReporting.report.signal' | translate}}</h6>
                            <input [ngClass]="!antSignal && newMeterOnSave ? 'red-borders' : ''" type="text" class="form-control" [(ngModel)]="antSignal">
                        </div>
                        <div class="w-100 mt-3">
                            <div class="w-100 d-flex cursor-pointer" (click)="img6.click()">
                                <img src="assets/icons/kamera_blue.svg" class="w-10 camera-icon">
                                <p class="w-90 camera-text">{{'fieldReporting.report.antennaImageText' | translate}}</p>
                                <input #img6 type="file" accept="image/*" (change)="handleImageUpload($event, 6)" style="display:none;" />
                            </div>
                            <img class="thumbnail-img" *ngIf="images[6].img" [src]="images[6].img" height="50">
                        </div>
                        <div *ngIf="!images[6].img && secondTabImgCheck" class="red-text">{{'fieldReporting.report.missingImage' | translate}}</div>

                    </div>
                    <hr>
                    <div class="dual-container w-100 mt-4">
                        <button class="btn btn-secondary left w-40" (click)="tabSwitch(1)">{{'fieldReporting.shared.previous' | translate}}</button>
                        <div class="w-20"></div>
                        <button class="btn btn-primary left w-40" (click)="checkValuesNewMeter()">{{'fieldReporting.report.next' | translate}}</button>
                    </div>




                </ng-container>

                <!-- 3rd TAB -->

                <div id="tab3" [hidden]="tabindex != 3">
                    <div class="w-100">
                        <h6 class="w-100">{{'basic.accessType' | translate}}</h6>
                        <div class="w-100">
                            <img [hidden]="topIcon == 1" (click)="topIconSwitch(1)" class="top-icon" src="assets/icons/key_gray_24dp.svg">
                            <img [hidden]="topIcon == 0 || topIcon == 2 || topIcon == 3" (click)="topIconSwitch(1)" class="top-icon top-icon-chosen" src="assets/icons/key_white_24dp.svg">
                            <img [hidden]="topIcon == 2" (click)="topIconSwitch(2)" class="top-icon ms-3" src="assets/icons/person_lock_gray_24dp.svg">
                            <img [hidden]="topIcon == 0 || topIcon == 1 || topIcon == 3" (click)="topIconSwitch(2)" class="top-icon top-icon-chosen ms-3" src="assets/icons/person_lock_white_24dp.svg">
                            <img [hidden]="topIcon == 3" (click)="topIconSwitch(3)" class="top-icon ms-3" src="assets/icons/lock_unlocked_gray_24dp.svg">
                            <img [hidden]="topIcon == 0 || topIcon == 1 || topIcon == 2" (click)="topIconSwitch(3)" class="top-icon top-icon-chosen ms-3" src="assets/icons/lock_unlocked_white_24dp.svg">
                        </div>
                        <div *ngIf="topIcon == 0" class="mt-5"></div>
                        <p *ngIf="topIcon == 1" class="w-100 gray-text mt-2">{{'accessTypes.1' | translate}}</p>
                        <p *ngIf="topIcon == 2" class="w-100 gray-text mt-2">{{'accessTypes.2' | translate}}</p>
                        <p *ngIf="topIcon == 3" class="w-100 gray-text mt-2">{{'accessTypes.3' | translate}}</p>
                        <h6 class="w-100">{{'fieldReporting.report.accessTypeInfo' | translate}}</h6>
                        <textarea [ngClass]="!accessTypeInfo && saveAdditional ? 'red-borders' : ''" class="form-control" [(ngModel)]="accessTypeInfo"></textarea>
                    </div>
                    <hr>
                    <div class="w-100">
                        <h6 class="w-100">{{'basic.story' | translate}}</h6>
                        <div class="w-50 dual-container">
                            <button class="btn btn-secondary left incdecBtn" (click)="adjustToStory('down')">-</button>
                            <h6 class="mt-2">{{this.story}}</h6>
                            <button class="btn btn-secondary right incdecBtn" (click)="adjustToStory('up')">+</button>
                        </div>
                    </div>
                    <hr>
                    <div class="w-100">
                        <h6 class="w-100">{{'basic.placement' | translate}}</h6>
                        <input type="text" [ngClass]="!placementText && saveAdditional ? 'red-borders' : ''" class="form-control" [(ngModel)]="placementText">
                    </div>
                    <hr>
                    <div class="d-flex w-100">
                        <h6 class="left mr-3">{{'fieldReporting.shared.location' | translate}}</h6>
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" role="switch" id="locationSwitch" [(ngModel)]="location" (click)="locationCheck()">
                        </div>
                    </div>
                    <div id="reportmap" [hidden]="!location"></div>
                    <div *ngIf="location && saveAdditional && ((latitude == undefined) || (longitude == undefined))" class="red-text">{{'fieldReporting.shared.locationError' | translate}}</div>
                    <hr>
                    <div class="w-100">
                        <h6 class="w-100">{{'fieldReporting.report.extraWorkType' | translate}}</h6>
                        <div ngbDropdown>
                            <button class="btn btn-secondary w-100 dropdown-border" type="button" id="dropdownExtraWorkButton" ngbDropdownToggle>
                                <span *ngIf="extraWorkType" class="ms-2 black-text float-start">{{extraWorkType.name}}</span>
                                <span *ngIf="!extraWorkType" class="ms-2 black-text float-start">Pick extra work if needed</span>
                            </button>
                            <div ngbDropdownMenu aria-labelledby="dropdownExtraWorkButton">
                                <div *ngFor="let workType of extraWorkTypes">
                                    <button ngbDropdownItem type="button" (click)="updateExtraWorkType(workType.id)">{{workType.name}}</button>
                                </div>
                            </div>
                            <div *ngIf="showOtherWorkType">
                                <h6>{{'fieldReporting.report.extraWorkName' | translate}}</h6>
                                <input type="text" [ngClass]="!otherWorkType && saveAdditional ? 'red-borders' : ''" [(ngModel)]="otherWorkType" class="form-control">
                            </div>
                        </div>
                        <div *ngIf="extraWorkType">
                            <div *ngIf="extraWorkType.openCustom === true">
                                <h6 class="w-100 mt-4">{{'fieldReporting.report.extraWorkMin' | translate}}</h6>
                                <div class="d-flex">
                                    <input type="number" min="0" [ngClass]="extraTime == 0 && saveAdditional && extraWorkType.openCustom === true ? 'red-borders' : ''" class="w-50 form-control" [(ngModel)]="extraTime">
                                    <div class="w-10"></div>
                                    <div class="w-30 dual-container">
                                        <button class="btn btn-secondary left incdecBtn" (click)="adjustExtraWork('dec')">-</button>
                                        <button class="btn btn-secondary right incdecBtn" (click)="adjustExtraWork('inc')">+</button>
                                    </div>
                                    <div class="w-10"></div>
                                </div>


                                <div class="w-100 mt-3">
                                    <div class="w-100 d-flex cursor-pointer" (click)="img7.click()">
                                        <img src="assets/icons/kamera_blue.svg" class="w-10 camera-icon">
                                        <p class="w-90 camera-text">{{'fieldReporting.report.extraWorkImage' | translate}}</p>
                                        <input #img7 type="file" accept="image/*" (change)="handleImageUpload($event, 7)" style="display:none;" />
                                    </div>
                                    <img class="thumbnail-img" *ngIf="images[7].img" [src]="images[7].img" height="50">
                                </div>
                                <div *ngIf="!images[7].img && extraWorkImgCheck" class="red-text">{{'fieldReporting.report.missingImage' | translate}}</div>
                            </div>

                        </div>

                    </div>
                    <hr>
                    <div class="w-100">
                        <h6 class="w-100">{{'basic.additionalInformation' | translate}}</h6>

                        <textarea class="w-100 form-control" [(ngModel)]="additionalInfo"></textarea>
                    </div>
                    <hr>
                    <div class="w-100">
                        <div class="w-100 d-flex cursor-pointer" (click)="img8.click()">
                            <img src="assets/icons/kamera_blue.svg" class="w-10 camera-icon">
                            <p class="w-90 camera-text">{{'fieldReporting.report.meterSurroundings' | translate}}</p>
                            <input #img8 type="file" accept="image/*" (change)="handleImageUpload($event, 8)" style="display:none;" />
                        </div>
                        <img class="thumbnail-img" *ngIf="images[8].img" [src]="images[8].img" height="50">
                    </div>
                    <div *ngIf="!images[8].img && thirdTabImgCheck" class="red-text">{{'fieldReporting.report.missingImage' | translate}}</div>

                    <hr>
                    <div class="w-100 mb-5">
                        <h6 class="w-100">{{'basic.installTime' | translate}}</h6>
                        <div class="d-flex" *ngIf="timeUntouched" (click)="makeTimeDirty()">
                            <div class="top-icon top-icon-chosen justify-content-center d-flex mr-3"><img style="height: 30px;" src="assets/icons/schedule_white_24dp.svg"></div>
                            <div class="top-icon mr-3 gray-text"><p class="install-time">{{installationDate.getDate()}}.{{installationDate.getMonth()+1}}.{{installationDate.getFullYear()}}</p></div>
                            <div class="top-icon gray-text"><p class="install-time">{{installHours}}:{{installMinutes}}</p></div>
                        </div>
                        <div class="d-flex" *ngIf="!timeUntouched">
                            <div class="timeTouched d-flex">
                                <div class="top-icon top-icon-chosen justify-content-center d-flex mr-3"><img style="height: 30px;" src="assets/icons/schedule_white_24dp.svg"></div>
                                <input type="date" [(ngModel)]="switchedDate" class="form-control dateHeight mr-3">
                            </div>

                            <input type="time" class="input-switched-time form-control" [(ngModel)]="switchedTime">

                        </div>
                        <p class="blue-text w-100 mt-3" *ngIf="!timeUntouched">
                            {{'fieldReporting.report.reportedAt' | translate}}<br>
                            {{installationDate.getDate()}}.{{installationDate.getMonth()+1}}.{{installationDate.getFullYear()}} {{installHours}}:{{installMinutes}}
                        </p>


                    </div>
                    <hr>
                    <div class="dual-container w-100">
                        <button class="btn btn-secondary left w-40" (click)="tabSwitch(2)">{{'fieldReporting.shared.previous' | translate}}</button>
                        <div class="w-20"></div>
                        <button class="btn btn-primary left w-40" (click)="checkValuesAdditional()">{{'basic.save' | translate}}</button>
                    </div>
                </div>
            </div>

            <!-- Barcode modal -->

            <div class="modal fade" id="barcodeModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
                <button [hidden]="true" data-bs-dismiss="modal" id="closeModal"></button>
                <div class="modal-dialog modal-md modal-dialog-centered" style="max-height: 60vh;">
                    <div class="modal-content background-modal">
                        <div class="modal-body text-center">
                            <div class="d-flex mb-2">
                                <div class="w-10 justify-content-center d-flex">
                                    <img class="pointer" src="assets/icons/close_gray_24dp.svg" (click)="toggleBarcodeScanner('no name', false)" data-bs-dismiss="modal">
                                </div>
                                <div class="w-80 d-flex justify-content-center">
                                    <img src="assets/icons/barcode_scanner_blue_48.svg" class="barcode-icon">
                                    <span class="ms-1 align-self-center">{{'fieldReporting.report.scanBarcode' | translate}}</span>
                                </div>
                            </div>
                            <button hidden="true" id="hiddenModalClose" data-bs-dismiss="modal"></button>
                            <load-spinner [hidden]="!barcodeSpinner"></load-spinner>
                            <barcode-scanner-livestream
                              [type]="barcodeTypes"
                              (valueChanges)="onBarcodeValueChanges($event)"
                              [maxWidth]="'100%'"
                              [maxHeight]="'70vh'"
                              (started)="barcodeSpinner = false"
                              [errorFilter]="barcodeErrorFilter"
                              [config]="barcodeConfig"
                            ></barcode-scanner-livestream>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
