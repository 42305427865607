import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core'
import {UserService} from "@shared/services/user/user.service";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {
    componentDestroyed$: Subject<boolean> = new Subject()

  constructor(
    private router: Router,
    private userService: UserService,
    private translateService: TranslateService
  ) { }

    loading = true;
  plannerCount = 0;
  activePlannerCount = 0;
  callServiceCount = 0;
  activeCallServiceCount = 0;
  adminCount = 0;
  activeAdminCount = 0;

  ngOnInit(): void {
      this.getUserCount();
  }
    ngOnDestroy(): void {
        this.componentDestroyed$.next(true)
        this.componentDestroyed$.complete()
    }

    getUserCount() {
        this.userService.getUsers('', 1)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                data => {
                    Object.keys(data).forEach((key)=>{
                        let user = data[key];
                        let disabled = user.disabled
                        if(user.role == 1) {
                            this.plannerCount++
                            if(!disabled) {
                                this.activePlannerCount++
                            }
                        }
                        if(user.role == 3) {
                            this.callServiceCount++
                            if(!disabled) {
                                this.activeCallServiceCount++
                            }
                        }
                        if(user.role == 5) {
                            this.adminCount++
                            if(!disabled) {
                                this.activeAdminCount++
                            }
                        }
                    })
                    this.loading = false
                }
            )
    }

  /**
   * 12.12.2022
   * Navigate to workers or teams page
   * @param destination name of landing page
   * @author Jesse Lindholm
   */
  navigate(destination: string) {
    if (destination === 'workers' || destination === 'teams') this.router.navigate([`/planner/${destination}`])
  }

}
