import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CallServiceDashboardComponent } from './components/call-service-dashboard/call-service-dashboard.component';
import { SingleEnduserComponent } from './components/single-enduser/single-enduser.component';
import { CallServiceComponent } from '@layout/call-service/call-service.component';
import { AppRoutingModule } from '@app/app-routing.module';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { SortBydatePipe } from './pipes/sortByDate.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FilterMsaByProjectPipe } from './pipes/filter-msa-by-project.pipe';
import { FilterAccessTypeByProjectPipe } from './pipes/filter-access-type-by-project.pipe';
import { CallLogComponent } from './components/call-service-dashboard/call-log.component';



@NgModule({
  declarations: [
    CallServiceDashboardComponent,
    SingleEnduserComponent,
    CallServiceComponent,
    SortBydatePipe,
    FilterMsaByProjectPipe,
    FilterAccessTypeByProjectPipe,
    CallLogComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    FormsModule,
    SharedModule,
    TranslateModule,
    NgbModule
  ]
})
export class CallServiceModule { }
