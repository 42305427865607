import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '@core/auth/components/login/login.component';
import { AuthGuard } from '@core/guards/auth.guard';
import { PlannerComponent } from '@layout/planner/planner.component';
import { MapViewComponent } from '@modules/planner/components/map-view/map-view.component';
import { ProjectPageComponent } from '@modules/planner/components/project-page/project-page.component';
import { NewProjectComponent } from '@modules/planner/components/new-project/new-project.component';
import { UserManagementComponent } from '@modules/planner/components/user-management/user-management.component';
import { PlannerManagementComponent } from '@modules/planner/components/planner-management/planner-management.component';
import { CallServiceManagementComponent } from '@modules/planner/components/callService-management/callService-management.component';
import { CustomerAdminManagementComponent } from '@modules/planner/components/customerAdmin-management/customerAdmin-management.component';
import { HumanResourcesComponent } from '@modules/planner/components/human-resources/human-resources.component';
import { WorkersComponent } from '@modules/planner/components/workers/workers.component';
import { WorkerDetailComponent } from '@modules/planner/components/worker-detail/worker-detail.component';
import { FieldReportingComponent } from '@layout/field-reporting/field-reporting.component';
import { DashboardComponent } from '@modules/field-reporting/components/dashboard/dashboard.component';
import { OrdergroupImportComponent } from '@modules/planner/components/import-data/import-data.component';
import { WeeklyPlanningComponent } from '@modules/planner/components/weekly-planning/weekly-planning.component';
import { WeeklyPlanningDetailComponent } from '@modules/planner/components/weekly-planning-detail/weekly-planning-detail.component';
import { WeeklyPlanningMapComponent } from '@modules/planner/components/weekly-planning-map/weekly-planning-map.component';
import { ProjectDashboardComponent } from '@modules/planner/components/project-dashboard/project-dashboard.component';
import { ReportComponent } from '@modules/field-reporting/components/report/report.component';
import { TeamsComponent } from '@modules/planner/components/teams/teams.component';
import { EndUserCommunicationComponent } from '@modules/planner/components/end-user-communication/end-user-communication.component';
import { TeamsDetailComponent } from '@modules/planner/components/teams-detail/teams-detail.component';
import { CallServiceComponent } from '@layout/call-service/call-service.component';
import { CallServiceDashboardComponent } from '@modules/call-service/components/call-service-dashboard/call-service-dashboard.component';
import { SingleEnduserComponent } from '@modules/call-service/components/single-enduser/single-enduser.component';
import { EndUserLoginComponent } from '@modules/end-user/components/end-user-login/end-user-login.component';
import { ConfirmSlotComponent } from '@modules/end-user/components/confirm-slot/confirm-slot.component';
import { EndUserComponent } from '@layout/end-user/end-user/end-user.component';
import { InterruptComponent } from '@modules/field-reporting/components/interrupt/interrupt.component';
import { ReportsComponent } from '@modules/planner/components/reports/reports.component';
import { ReportWorkorderDetailComponent } from '@modules/planner/components/report-workorder-detail/report-workorder-detail.component';
import { WiringReportComponent } from '@modules/field-reporting/components/wiring-report/wiring-report.component';
import { WorkordersWithoutCoordinatesComponent } from '@modules/planner/components/workorders-without-coordinates/workorders-without-coordinates.component';
import { ForgotPasswordComponent } from '@core/auth/components/forgot-password/forgot-password.component';
import { UserInfoResolver } from '@shared/services/user/user.info.resolver';
import { PlannerDetailComponent } from '@modules/planner/components/planner-detail/planner-detail.component';
import { CallServiceDetailComponent } from '@modules/planner/components/callService-detail/callService-detail.component';
import { DanishWaterMeterReport } from '@modules/field-reporting/components/danish-water-meter-report/danish-water-meter-report.component';
import { DanishHeatMeterReport } from '@modules/field-reporting/components/danish-heat-meter-report/danish-heat-meter-report.component';
import { DanishHeatMeterReportSixValues } from '@modules/field-reporting/components/danish-heat-meter-report-six-values/danish-heat-meter-report-six-values.component';
import {GermanElectricMeterReport} from "@modules/field-reporting/components/german-electric-meter-report/german-electric-meter-report.component";
import { DanishHeatMeterReportTwelvePictures } from '@modules/field-reporting/components/danish-heat-meter-report-twelve-pictures/danish-heat-meter-report-twelve-pictures.component';
import { WeeklyPlanningCompareComponent } from '@modules/planner/components/weekly-planning-compare/weekly-planning-compare.component';
/**
 * This is our routing module which handles the routing of our application.
 */

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'confirm',
    component: EndUserComponent,
    children: [
      {
        path: '',
        component: EndUserLoginComponent
      },
      {
        path: 'installation-time',
        component: ConfirmSlotComponent,
      }
    ]
  },
  {
    path: 'konfirmere',
    component: EndUserComponent,
    children: [
      {
        path: '',
        component: EndUserLoginComponent,
        data: {
          language: 'da'
        },
      },
      {
        path: 'installation-time',
        component: ConfirmSlotComponent,
        data: {
          language: 'da'
        },
      }
    ]
  },
  {
    path: 'planner',
    component: PlannerComponent,
    canActivate: [ AuthGuard ],
    canActivateChild: [ AuthGuard ],
    data: {
      role: [1, 5]
    },
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        component: ProjectDashboardComponent,
        data: {
          role: [1, 5]
        }
      }, {
        path: 'map',
        component: MapViewComponent,
        data : {msa : false, role: [1, 5]}
      },
      {
        path: 'msa-map',
        component: MapViewComponent,
        data : {msa : true, role: [1, 5]}
      },
      {
        path: 'project/:newproject',
        component: ProjectPageComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'project',
        component: ProjectPageComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'new-project',
        component: NewProjectComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'user-management',
        component: UserManagementComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'planner-management',
        component: PlannerManagementComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'callservice-management',
        component: CallServiceManagementComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'admin-management',
        component: CustomerAdminManagementComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'human-resources',
        component: HumanResourcesComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'workers',
        component: WorkersComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'teams',
        component: TeamsComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'teams-detail/:id',
        component: TeamsDetailComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'worker-detail/:id',
        component: WorkerDetailComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'planner-detail/:id',
        component: PlannerDetailComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'callService-detail/:id',
        component: CallServiceDetailComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'import-data',
        component: OrdergroupImportComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'weekly-planning',
        component: WeeklyPlanningComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'weekly-planning-detail/:id',
        component: WeeklyPlanningDetailComponent,
        resolve: {
          userInfo: UserInfoResolver
        },
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'weekly-planning-map/:id',
        component: WeeklyPlanningMapComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'end-user-communication',
        component: EndUserCommunicationComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'reports',
        component: ReportsComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'report-workorder-detail/:id',
        component: ReportWorkorderDetailComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'workorders-without-coordinates',
        component: WorkordersWithoutCoordinatesComponent,
        data: {
          role: [1, 5]
        }
      },
      {
        path: 'weekly-planning-compare',
        component: WeeklyPlanningCompareComponent,
        data: {
          role: [1, 5]
        }
      }
    ]
  },{
    path: 'field-reporting',
    component: FieldReportingComponent,
    canActivate: [ AuthGuard ],
    canActivateChild: [ AuthGuard ],
    data: {
      role: [2]
    },
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: {
          role: [2]
        }
      },
      {
        path: 'report/:id',
        component: ReportComponent,
        data: {
          role: [2]
        }
      },
      {
        path: 'interrupt/:id',
        component: InterruptComponent,
        data: {
          role: [2]
        }
      },
      {
        path: 'wiring-report/:id',
        component: WiringReportComponent,
        data: {
          role: [2]
        }
      },
      {
        path: 'danish-water-meter-report/:id',
        component: DanishWaterMeterReport,
        data: {
          role: [2]
        }
      },
      {
        path: 'danish-heat-meter-report/:id',
        component: DanishHeatMeterReport,
        data: {
          role: [2]
        }
      },
      {
        path: 'danish-heat-meter-report-six-values/:id',
        component: DanishHeatMeterReportSixValues,
        data: {
          role: [2]
        }
      },
      {
        path: 'german-electric-meter-report/:id',
        component: GermanElectricMeterReport,
        data: {
          role: [2]
        }
      },
      {
        path: 'danish-heat-meter-report-twelve-pictures/:id',
        component: DanishHeatMeterReportTwelvePictures,
        data: {
          role: [2]
        }
      },
    ]
  },
  {
    path: 'call-service',
    component: CallServiceComponent,
    canActivate: [ AuthGuard ],
    canActivateChild: [ AuthGuard ],
    data: {
      role: [3]
    },
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        component: CallServiceDashboardComponent,
        data: {
          role: [3]
        }
      },
      {
        path: 'enduser/:id',
        component: SingleEnduserComponent,
        data: {
          role: [3]
        }
      }
    ]
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
