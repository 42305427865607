import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterAccessTypeByProject'
})
export class FilterAccessTypeByProjectPipe implements PipeTransform {

  transform(accessTypeList: any[], selectedProjectId: number | undefined): any[] {
    if (!accessTypeList || !selectedProjectId) {
      return accessTypeList;
    }
    return accessTypeList.filter(at => (at.projectIds.some(pid => pid === selectedProjectId)) || (at.projectIds[0] === 0));
  }

}
