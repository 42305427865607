import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseUrl } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Customer } from '@shared/models/customer';
import { ToastService } from '@shared/services/toast/toast.service';
import { catchError, map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CallServiceService {

  constructor(
    private http: HttpClient,
    private toastService: ToastService,
    private translateService: TranslateService
    ) { }

  getCalendarsByWorkorder(workorderId) {
    return this.http.get<any>(`${baseUrl}/api/calendarbyworkorder/${workorderId}`)
      .pipe(
        map((data: any) => {
          return data['message'];
        }),
        catchError(this.handleError<any>(`getCalendars with workorder id: ${workorderId}`))
      );
  }

  /**
   * 13.12.2022
   * Search customer with search keyword that is more or equal than 3 characters and search field which is from list [name / address / location_number / device_id / phone]
   * @param searchWord
   * @param searchField
   * @returns Observable which can be subscribed to
   * @author Jesse Lindholm
   *
   * @edit 19.01.2023
   * Added project ID to api-call
   */
  customerSearch(searchWord: string, searchField: string, projectIds: string[], selectedWeek: string) {
    let params = new HttpParams()
    .set('search', searchWord)
    .set('searchfield', searchField)
    .set('project_ids', projectIds.join(','))
    .set('selected_week', selectedWeek);
    return this.http.get<Customer>(`${baseUrl}/api/customerSearch`, {params: params})
      .pipe(
        map((data: Customer) => {
          return data['message'];
        }),
        catchError(this.handleError<Customer>(`customerSearch failed`))
      );
  }

  saveInputFields(workorderId, phoneNumber, additionalInformation, notesForInstaller) {
    let newdataJSON = {
      "contact_info": phoneNumber,
      "notes": additionalInformation,
      "worker_notes": notesForInstaller
    }
    let newdata = JSON.stringify(newdataJSON)

    return this.http.patch(`${baseUrl}/api/workorders/${workorderId}`, { newdata})
    .pipe(
      map((data: any) => {
        this.toastService.sendToast(true, this.translateService.instant('services.inputFieldsUpdated'))
        return data['message']
      }),
      catchError(this.handleError<any>(`saveInputFields, workorder id: ${workorderId}.`))
    )
  }

  saveSlot(workorderId, slotId) {

    return this.http.post(`${baseUrl}/api/calendarslots/${slotId}/${workorderId}`, null)
    .pipe(
      map((data: any) => {
        this.toastService.sendToast(true, this.translateService.instant('services.slotTimeAdjusted'))
        return data['message']
      }),
      catchError(this.handleError<any>(`saveSlot, workorder id: ${workorderId}, slot id: ${slotId}.`))
    )
  }

  saveInterrupt(reason, id, interruptCode) {


    let newdataJSON = {
      "status": 6,
      "interrupt_reason": reason,
      "interrupt_code": interruptCode
    }
    let newdata = JSON.stringify(newdataJSON)

    this.http.patch(`${baseUrl}/api/workorders/${id}`, {
        newdata
      })
      .toPromise()
      .then(response => {
        this.toastService.sendToast(true, this.translateService.instant('services.interruptedWorkorder'), id)
        return response
      })
      .catch(err => {
        //console.log(err);
        this.toastService.sendToast(false, err)
      }
    )

  }

  /**
   * @created 19.1.2023
   * Get projects for call service
   * @author Jesse Lindholm
   */
  getProjects() {
    return this.http.get(`${baseUrl}/api/phoneserviceprojects`)
    .pipe(
      map(
        data => {
          return data['message']
        }
      ),
      catchError(this.handleError<any>(this.translateService.instant('services.callServiceProjectsFailed')))
    )
  }

  updateEnduserMsgLog(enduserId: number, messageLogs) {
    let newDate = new Date()
    let date = newDate.getFullYear() + "-"  + newDate.getDate() + "-" + newDate.getDay()
    let time = newDate.getHours() + ":" + newDate.getMinutes()
    let newLog = {date: date, time: time}
    let msgLogs = [...messageLogs, newLog]

    let newdataJSON = {
      "text_communicated": msgLogs
    }

    let newdata = JSON.stringify(newdataJSON)



    return this.http.patch(`${baseUrl}/api/endusercommunications/${enduserId}`, { newdata }).pipe(
      map(response => {
        this.toastService.sendToast(true, this.translateService.instant('basic.success'));
        return response;
      }),
      catchError(err => {
        this.toastService.sendToast(false, err);
        return of(null);
      })
  )
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
   private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

}
