<div class="toast show" role="alert" aria-live="assertive" aria-atomic="true" *ngIf="toast">
    <div *ngIf="toast.success" class="toast-icon toast-success">
        <img class="nav-icon" src="assets/icons/check_white_24dp.svg">
    </div>
    <div *ngIf="!toast.success" class="toast-icon toast-error">
        <img class="nav-icon" src="assets/icons/close_white_24dp.svg">
    </div>
    <div class="toast-main"> 
        <div class="toast-header">
            <strong class="me-auto">{{toast.title  | translate}}</strong>
        </div>
        <div class="toast-body" *ngIf="toast.description">
            {{toast.description  | translate}}
        </div>
    </div>
</div>