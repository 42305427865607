import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';


@Component({
  selector: 'app-week-picker',
  templateUrl: './week-picker.component.html',
  styleUrls: ['./week-picker.component.scss']
})
export class WeekPickerComponent implements OnInit, OnChanges {

  constructor(
    private datePipe: DatePipe
  ) { }

  weekNumber: string | null = null
  lastDay: Date | null = null
  firstDay: Date | null = null

  @Input() changeWeekPickerDate: Date | null = null

  @Output() weekChange = new EventEmitter();


  /**
   * Init component:
   * Setting date for first day
   * Setting date for last day
   * Setting week number
   */
  ngOnInit(): void {
    this.moveToToday()
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange}) {
    if (changes['changeWeekPickerDate'] && changes['changeWeekPickerDate'].currentValue != null) {
      this.moveToSpecificDate()
    }
  }

  /**
   * Move backwards or forwards in weeks. Use new Date object to create a new date, because Angular Datepipe does not rerender
   * on changes to same Date object.
   * @param forward boolean that represents whetever to go forward or backwards in weeks
   */
  moveWeek(forward) {
    // Null check
    if (this.firstDay && this.lastDay) {
      let newFirst = new Date(this.firstDay)
      let newLast = new Date(this.lastDay)
      // Going forward
      if (forward) {
        newFirst.setDate(this.firstDay.getDate() + 7)
        newLast.setDate(this.lastDay.getDate() + 7)
        this.firstDay = newFirst
        this.lastDay = newLast
        this.weekChange.emit(this.firstDay)
      }
      // Going backwards
      else {
        newFirst.setDate(this.firstDay.getDate() - 7)
        newLast.setDate(this.lastDay.getDate() - 7)
        this.firstDay = newFirst
        this.lastDay = newLast
        this.weekChange.emit(this.firstDay)
      }
      this.weekNumber = this.datePipe.transform(this.firstDay, 'w')
    }
  }

  moveToToday() {
    let date = new Date();
    let day = date.getDay(),

    diff = date.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
    this.firstDay = new Date(date.setDate(diff));
    this.lastDay = new Date()
    this.lastDay.setDate(this.firstDay.getDate() + 6)
    this.weekNumber = this.datePipe.transform(this.firstDay, 'w')

    this.weekChange.emit(this.firstDay)

  }

  moveToSpecificDate() {

    // @ts-ignore
    if (this.changeWeekPickerDate != null && Object.prototype.toString.call(this.changeWeekPickerDate) === "[object Date]" && !isNaN(this.changeWeekPickerDate)) {
      let date = new Date(this.changeWeekPickerDate);
      let day = date.getDay(),

      diff = date.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
      this.firstDay = new Date(date.setDate(diff));
      this.lastDay = new Date(this.changeWeekPickerDate)
      this.lastDay.setDate(this.firstDay.getDate() + 6)

      this.weekNumber = this.datePipe.transform(this.firstDay, 'w')
    }
  }

}
