<div class="page-content">
    <div class="card">
        <div class="header">
            <div class="d-flex">
                <div class="w-20 justify-content-center d-flex">
                    <img (click)="backToDashboard()" src="assets/icons/chevron_left_gray_24dp.svg">
                </div>
                <div class="w-80 d-flex justify-content-center">
                    <img class="image-size-edit" src="assets/icons/edit_blue_24dp.svg">
                    <h6 class="page-title">{{'fieldReporting.shared.currentMeter' | translate}}</h6>
                </div>
                <div class="w-20"></div>
            </div>
            <br>
        </div>

        <div class="p-4">
            <div class="fw-bold">{{'fieldReporting.wiringReport.installationDone' | translate}}</div>
            <div class="d-flex">
                <div class="col-6 ">
                    <button id="yes-button" class="btn w-90"
                        [ngClass]="clickedButtonId === 1 ? 'btn-primary' : 'btn-secondary'"
                        (click)="changeButtonId(1)">{{'basic.yes' | translate}}</button>
                </div>
                <div class="col-6">
                    <button id="no-button" class="btn w-90"
                        [ngClass]="clickedButtonId === 2 ? 'btn-danger' : 'btn-secondary'"
                        (click)="changeButtonId(2)">{{'basic.no' | translate}}</button>
                </div>
            </div>
        </div>

        <hr *ngIf="clickedButtonId === 2">
        <div *ngIf="clickedButtonId === 2" class="p-4">
            <div class="fw-bold">{{'fieldReporting.wiringReport.interruptCode' | translate}}</div>
            <div ngbDropdown class="d-inline-block w-100">    
                <button class="btn btn-secondary w-100" id="dropdownInterrupt" ngbDropdownToggle>
                  <span *ngIf="!interruptCode">{{'fieldReporting.wiringReport.interruptCode' | translate}}</span>
                  <span *ngIf="interruptCode">{{interruptCode.definition}}</span>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownInterrupt" class="w-100">
                  <div *ngFor="let item of interruptOptions" ngbDropdownItem class="w-100 interrupt-option" (click)="setInterruptCode(item)">{{item.definition}}</div>
                </div>
            </div>
        </div>
        <div *ngIf="clickedButtonId === 2" class="p-4">
            <div class="fw-bold">
                {{'basic.reasonForInterruption' | translate}}
                <span *ngIf="isMandatory">*</span>
                <span *ngIf="isOptional">(Optional)</span>
            </div>
            <textarea type="text" rows="4" class="form-control"
                placeholder="{{'basic.reasonForInterruption' | translate}}" [(ngModel)]="interruptReason"
                [disabled]="!textareaEnabled">
            </textarea>
        </div>

        <div *ngIf="clickedButtonId === 2 || clickedButtonId === 1" class="card-body">
            <div class="w-100" [style.opacity]="(interruptCode !== null || clickedButtonId === 1) ? '1' : '0.5'">

                <div class="w-100 d-flex cursor-pointer" (click)="imgInput.click()">
                    <img src="assets/icons/kamera_blue.svg" class="w-10 camera-icon">
                    <p class="w-90 camera-text small">
                        Click the icon to take a picture
                        <span *ngIf="imageMandatory">*</span>
                        <span *ngIf="imageOptional">(Optional)</span>
                    </p>
                    <input #imgInput type="file" accept="image/*" (change)="handleImageUpload($event)"
                        style="display:none;" />
                </div>

                <!-- Display all image thumbnails -->
                <div *ngFor="let image of images">
                    <img class="thumbnail-img" [src]="image.img" height="50">
                </div>

                <!-- Warning for missing images -->
                <div *ngIf="images.length === 0 && firstTabImgCheck" class="red-text">
                    {{'fieldReporting.report.missingImage' | translate}}
                </div>

            </div>
        </div>


        <div class="d-flex justify-content-center background-light p-4 w-100">
            <button type="button" class="btn btn-primary w-90" [disabled]="!canSave()" (click)="saveReport()">
                {{'fieldReporting.wiringReport.saveReport' | translate}}
            </button>
        </div>
    </div>
</div>
