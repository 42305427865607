import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TeamsService } from '@modules/planner/services/teams/teams.service';
import { WorkersService } from '@modules/planner/services/workers/workers.service';
import { TranslateService } from '@ngx-translate/core';
import { Calendar } from '@shared/models/calendar';
import { Skill } from '@shared/models/skill';
import { Worker } from '@shared/models/worker';
import { ToastService } from '@shared/services/toast/toast.service';
import { Subject, takeUntil } from 'rxjs';
import Swal from 'sweetalert2';
import { UserService } from '@shared/services/user/user.service';

@Component({
  selector: 'app-teams-detail',
  templateUrl: './teams-detail.component.html',
  styleUrls: ['./teams-detail.component.scss']
})
export class TeamsDetailComponent implements OnInit, OnDestroy {

  componentDestroyed$: Subject<boolean> = new Subject()

  pageSpinner: boolean = true
  editMode: boolean = false

  // Team
  teamsWorkers: Array<Worker> = Array()
  teamName: string = ""
  assignedCalendars: Array<Calendar>  = Array()
  teamsOverallEfficiency: number = 0
  showEfficiency: boolean = true
  teamsAdditionalInformation: string = ""
  teamId: number = 0

  // Original/Previous values
  originalTeamName: string = ""
  originalTeamsAdditionalInformation: string = ""

  // Add new team members
  selectedWorkersList: Array<Worker> = Array()
  allWorkers: Array<Worker> = Array()

  customerId;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private teamsService: TeamsService,
    private workersService: WorkersService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private userService: UserService
  ) { }

  /**
   * Component initialization.
   * Get team ID from URL.
   * @author Jesse Lindholm
   */
  ngOnInit(): void {
    this.route.paramMap.subscribe(pmap =>  this.getTeamById(pmap.get('id')));
    this.getCustomer();
  }

  /**
   * Component destruction.
   * End unnecessary subscribes.
   * @author Jesse Lindholm
   */
  ngOnDestroy() {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }

  /**
   * Get all workers from project.
   * Ignore workers that are in some team already.
   * @author Jesse Lindholm
   */
  getAllWorkers() {
    this.workersService.getWorkers()
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(
      data => {
        const workers = data
        for (let i = 0; i < workers.length; i++) {
          if (!this.teamsWorkers.some(element => element.id == workers[i].id)) {
            workers[i].efficiencyNumber = parseFloat(workers[i].efficiency)
            workers[i].checked = false
            workers[i].skills = this.setSkills(workers[i].qualifications, workers[i])
            this.allWorkers.push(workers[i])
          }
        }
      }
    )
  }

  /**
   * Navigate to teams listing page.
   * @author Jesse Lindholm
   */
  backToTeams() {
    this.router.navigate(['planner/teams'])
  }

  /**
   * Get information about single team.
   * Workers own logic.
   * @param id teams ID
   * @author Jesse Lindholm
   */
  getTeamById(id) {
    this.teamsService.getTeamById(id)
     .pipe(takeUntil(this.componentDestroyed$))
     .subscribe(
      data => {

        this.teamId = data.id
        this.teamName = data.name
        this.teamsOverallEfficiency = parseFloat(data.efficiency)
        this.teamsAdditionalInformation = data.notes
        for (let i = 0; i < Object.keys(data.calendars).length; i++) {
            let cal = Object.values(data.calendars)[i]
            cal.id = parseInt(Object.keys(data.calendars)[i])
          this.assignedCalendars.push(Object.values(data.calendars)[i])
        }
        this.assignedCalendars.sort((objA, objB) => new Date(objB.startdate).getTime() - new Date(objA.startdate).getTime());
        for (let j = 0; j < Object.keys(data.workers).length; j++) {
          let worker = Object.values(data.workers)[j]
          worker.id = parseInt(Object.keys(data.workers)[j])
          worker.skills = this.setSkills(Object.values(data.workers)[j].qualifications, worker)
          worker.showSkills = false
          worker.initials = worker.firstname.charAt(0) + worker.lastname.charAt(0)
          worker.efficiencyNumber = parseFloat(worker.efficiency)
          worker.icon = "assets/icons/close_gray_24dp.svg"
          this.teamsWorkers.push(worker)
        }
        this.originalTeamName = data.name
        this.originalTeamsAdditionalInformation = data.notes
        this.pageSpinner = false;
        this.getAllWorkers()
      }
    )
  }

  /**
   * Configure skills to displayeable version from response data.
   * @param qualifications skills
   * @param worker can be set to lead installer
   * @returns skills in proper form
   * @author Jesse Lindholm
   */
  setSkills(qualifications, worker) {
    if (qualifications && qualifications.length > 0) {
      let skills = Array<string>()
      try {
        let parsedSkills = <Skill[]>JSON.parse(qualifications)
        for (let i = 0; i < Object.keys(parsedSkills).length; i++) {
          skills.push(Object.values(parsedSkills)[i].name)
          if (Object.values(parsedSkills)[i].name == 'Lead installer') worker.leadInstaller = true
        }
      } catch (e) {
        // console.log(e)
      }
      return skills
    } else return []
  }

  /**
   * Toggle edit mode on.
   * @author Jesse Lindholm
   */
  toggleEdit() {
    this.editMode = true
  }

  /**
   * Save teams data on edit mode.
   * Weird subscribe to be able to make api-call.
   * @author Jesse Lindholm
   * TODO: make better api-call
   */
  saveEdit() {
    if (this.teamName != this.originalTeamName || this.teamsAdditionalInformation != this.originalTeamsAdditionalInformation) {
      this.teamsService.editTeam(this.teamId, this.teamName, this.teamsAdditionalInformation)
       .pipe(takeUntil(this.componentDestroyed$))
       .subscribe()
      const teamName = this.teamName
      this.originalTeamName = teamName
      const additionalInformation = this.teamsAdditionalInformation
      this.originalTeamsAdditionalInformation = additionalInformation
      this.editMode = false
    } else {
      this.toastService.sendToast(false, this.translateService.instant('basic.nothingToSave'))
    }

  }

  /**
   * Cancel edit mode.
   * Revert changes to team name and additional information.
   * @author Jesse Lindholm
   */
  cancelEdit() {
    const teamName = this.originalTeamName
    this.teamName = teamName
    const additionalInformation = this.originalTeamsAdditionalInformation
    this.teamsAdditionalInformation = additionalInformation
    this.editMode = false
  }

  /**
   * Delete team and if confirmed, move to teams listing page.
   * @author Jesse Lindholm
   */
  deleteTeam() {
    Swal.fire({
      title: this.translateService.instant('planner.teamsDetail.swal.deleteTeamConfirmation'),
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('basic.yes'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.teamsService.deleteTeam(this.teamId).subscribe(
          () => this.router.navigate(['/planner/teams'])
        )
      }
    })
  }

  /**
   * Unlink worker from workerteam.
   * @param workerId worker that needs to be deleted
   * @author Jesse Lindholm
   */
  deleteWorker(workerId) {
    Swal.fire({
      title: this.translateService.instant('planner.teamsDetail.swal.deleteWorker'),
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('basic.yes'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.teamsService.unlinkWorkerFromWorkerteam(workerId, this.teamId).subscribe(
          () => {
            // Go through workers
            for (let i = 0; i < this.teamsWorkers.length; i++) {
              // If found
              if (this.teamsWorkers[i].id == workerId) {
                this.allWorkers.push(this.teamsWorkers[i])
                // Remove worker
                this.teamsWorkers.splice(i, 1)

                break
              }
            }
            this.calculateEfficiency()
          })
      }
    })
  }


  /**
   * Calculate efficiency of team.
   * @author Jesse Lindholm
   */
  calculateEfficiency() {
    let efficiencyTotal = 0
    let workerCount = 0
    // Make a new list without worker
    const workers = this.teamsWorkers
    // Add efficiency values
    for (let j = 0; j < workers.length; j++) {
      workerCount++;
      efficiencyTotal = efficiencyTotal + workers[j].efficiencyNumber
    }
    // Count efficiency if it's more than zero
    if (efficiencyTotal != 0 && workerCount != 0) {
      let efficiencyInDecimals: number | string = efficiencyTotal / workerCount
      efficiencyInDecimals = efficiencyInDecimals.toFixed(2)
      this.teamsOverallEfficiency = parseFloat(efficiencyInDecimals)
    } else this.teamsOverallEfficiency = 0
    this.showEfficiency = false
    setTimeout(() => {
      this.showEfficiency = true
    }, 100);
  }

  /**
   * Worker clicked in "Add team members" popup.
   * Adds worker to new team.
   * @param id workers ID
   * @author Jesse Lindholm
   */
  workerClicked(id) {
    for (let j = 0; j < this.allWorkers.length; j++) {
      if (this.allWorkers[j].id == id) {
        if (this.allWorkers[j].checked == false || this.allWorkers[j].checked == undefined) {
          this.allWorkers[j].checked = true
          this.selectedWorkersList.push(this.allWorkers[j])
        } else {
          this.allWorkers[j].checked = false
          for (let i = 0; i < this.selectedWorkersList.length; i++) {
            if (this.selectedWorkersList[i].id == id) {
              this.selectedWorkersList.splice(i, 1)
              break;
            }
          }
        }
        break;
      }
    }
  }

  /**
   * Cancel adding team members popup.
   * Reset values.
   * @author Jesse Lindholm
   */
  cancelAddTeamMembers() {
    for (let i = 0; i < this.selectedWorkersList.length; i++) {
      this.selectedWorkersList[i].checked = false
    }
    this.selectedWorkersList = Array()
    this.scrollToTop()
  }

  /**
   * Scroll to top of page.
   * @author Jesse Lindholm
   */
  scrollToTop() {
    if (document.getElementById('allWorkersList') != null) {
    let myDiv = <HTMLElement>document.getElementById('allWorkersList')
    myDiv.scrollIntoView()
    }
  }

  /**
   * Add new team members to team.
   * @author Jesse Lindholm
   * TODO: Make single api-call that adds all new team members to team, instead of adding single-by-single way.
   */
  saveAddTeamMembers() {
    for (let i = 0; i < this.selectedWorkersList.length; i++) {
      this.workersService.setWorkerToWorkerteam(this.selectedWorkersList[i].id, this.teamId)
       .pipe(takeUntil(this.componentDestroyed$))
       .subscribe()
      this.selectedWorkersList[i].checked = false
      this.selectedWorkersList[i].initials = this.selectedWorkersList[i].firstname.charAt(0) + this.selectedWorkersList[i].lastname.charAt(0)
      this.selectedWorkersList[i].icon = "assets/icons/close_gray_24dp.svg"
      this.teamsWorkers.push(this.selectedWorkersList[i])
      const found = <Worker>this.allWorkers.find(elem => elem.id === this.selectedWorkersList[i].id);
      const index = this.allWorkers.indexOf(found)
      this.allWorkers.splice(index, 1)
    }
    if (this.selectedWorkersList.length == 0) this.toastService.sendToast(false, this.translateService.instant('planner.teamsDetail.toast.noWorkersAdded'))
    else {
      this.toastService.sendToast(true, this.translateService.instant('planner.teamsDetail.toast.workersAdded'))
      this.selectedWorkersList = Array()
      this.calculateEfficiency()
    }
    document.getElementById('closeModal')?.click()
    this.scrollToTop()
  }

  getCustomer() {
    this.userService.getUserInfo()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.customerId = data.customer_id;
      })
  }

}
