import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FieldReportingService} from '@modules/field-reporting/services/field-reporting/field-reporting.service';
import {WorkOrderService} from '@modules/planner/services/workorder/work-order.service';
import {UserService} from '@shared/services/user/user.service';
import {Subject, takeUntil} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ToastService} from "@shared/services/toast/toast.service";
import {WorkOrder} from "@shared/models/work-order";
import Swal from 'sweetalert2';
import { Status } from '@shared/models/status';
import { PlannerProjectService } from '@modules/planner/services/planner-project/planner-project.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  componentDestroyed$: Subject<boolean> = new Subject()

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private fieldReporting: FieldReportingService,
    private translateService: TranslateService,
    private toastService: ToastService,
    private workOrderService: WorkOrderService,
    private plannerProjectService: PlannerProjectService
    ) { }

  userid: any;
  currentProject: any;
  currentProjectId: number = 0
  projects = Array();
  workerteams = Array();
  allWorkerteams = Array();
  currentWorkerteam;
  tab = 1;
  currentUser;
  workOrderArray = Array()
  displayedWorkOrders = Array()
  filteredWorkOrders = Array()
  allOrders = Array()
  mustDoForTodayOrders = Array()
  doneOrders = Array()
  toDoCount = 0;
  doneCount = 0;
  freeLocationsCount = 0;
  tabTitle = ''
  topIcon = 0
  expandInventoryList = 'assets/icons/expand_more_gray_24dp.svg';
  showInventoryList = false;
  showDashboard = true;
  showSingleOrderDetail = false;
  workOrderId;
  runGetWorkOrder = false;
  typeArray = Array();
  lat: number | null = null
  lon: number | null = null
  sortChoise = 'default';
  customerId = 1;
  projectType: number = 0
  loading: boolean = true

  queuedWorkOrders: Number[] = [];
  searchField: string = ""
  searchedWorkorders: any[] = []
  selectedWorkorders: WorkOrder[] = []
  listSpinner: boolean = false
  searchActive: boolean = false
  longPressTimer: any
  isLongPress: boolean = false
  firstItem: boolean = false
  statuses: Status[] = []
  firstInterruptedStatus: Status | null = null
  allInterruptedStatuses: Status[] = []
  showWorklist: boolean = false
  showMainList: boolean = true
  currentWorkordersCount: number = 0

  ngOnInit(): void {
    this.getStatuses()
    this.sortChoise = 'default';
    this.getCurrentProject();
    this.route.queryParams.subscribe(params => {
      if (params['queuedWorkOrderId']) {
        const workOrderId = Number(params['queuedWorkOrderId'])
        this.queuedWorkOrders.push(workOrderId);
      }
    })

     // Retrieve the saved filter choice from localStorage
     const savedFilter = localStorage.getItem('selectedFilter');
     if (savedFilter) {
         this.changeFilter(savedFilter);
     }
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }

  /**
   * Get user info and set projects variable from it.
   * Set also workerteams correctly
   */
  getCurrentProject() {
    this.userService.getUserInfo()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.userid = data.id;
        this.projects = Array();
        this.currentProjectId = data.current_project
        this.projectType = data.project_type
        this.customerId = data.customer_id;
        // Loop through projects to add them to projects array. If
        // project id matches current project number set its name to current project variable
        for (let i = 0; i < Object.keys(data.projects).length; i++) {
          if (Object.keys(data.projects)[i] == data.current_project) this.currentProject = Object.values(data.projects)[i]
          if (Object.values(data.projects)[i] != this.currentProject) this.projects.push({ name: Object.values(data.projects)[i], id: Object.keys(data.projects)[i] })
        }
        // Loop through workers data and push workerteams to workerteams variable and allworkerteams variable
        // Workerteams variable is active variable and allworkerteams is for later usage for accessing all original workerteams
        data.workers.forEach(worker => {
          interface Workerteam {
            id: number;
            name: string;
            project_id: number;
          }
          const teamsArray: Workerteam[] = Object.values(worker.workerteams);
          teamsArray.forEach(workerteam => {
            if (workerteam.project_id === data.current_project) {
              this.workerteams.push(workerteam);
            }
            this.allWorkerteams.push(workerteam);
          });
        });
        if (this.workerteams.length > 0) {
          // Set current worker team variable from first of workerteams array
          this.currentWorkerteam = { name: this.workerteams[0].name, id: this.workerteams[0].id }
          // Remove first workerteam from workerteams variable so that it doesnt appear on list of workerteams that
          // you can change to
          this.workerteams.splice(0, 1)
        }
        this.getWorkerteamOrders()
        this.currentUser = data.id;
      }
      )
  }

  /**
   * Switch project by pressing another project in list from interface
   * @param projectId correct project id to change to
   */
  switchProject(projectId) {
    this.userService.updateCurrentProject(this.currentUser, projectId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        () => window.location.reload()
      )
  }

  /**
   * Go through work orders that workerteam has and apply data accordingly
   */
  getWorkerteamOrders() {
    this.toDoCount = 0;
    this.doneCount = 0;
    this.freeLocationsCount = 0;

    // this.displayedWorkOrders = this.workOrderArray
    this.displayedWorkOrders = []
    this.changeFilter(this.sortChoise)
    this.fieldReporting.getFieldReporting(this.customerId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.workOrderArray = []
        this.doneOrders = []
        this.mustDoForTodayOrders = []
        if(this.projectType == 2) {
          // Project type 2 sees all workorders, for all workerteams
          for (let i = 0; i < data.all.length; i++) {
            data.all[i].expand = false
            this.workOrderArray.push(data.all[i])
          }
          for (let j = 0; j < data.done.length; j++) {
            data.done[j].expand = false
            this.doneOrders.push(data.done[j])
          }
          for (let k = 0; k < data.today.length; k++) {
            data.today[k].expand = false
            this.mustDoForTodayOrders.push(data.today[k])
          }
        } else if (this.currentWorkerteam != undefined) {
          const workerTeamId = this.currentWorkerteam.id;

          for (let i = 0; i < data.all.length; i++) {
            if (data.all[i].workerteam_id == workerTeamId || data.all[i].reserver_id == this.userid) {
              this.workOrderArray.push(data.all[i])
            }
          }
          for (let j = 0; j < data.done.length; j++) {
            if (data.done[j].workerteam_id == workerTeamId || data.done[j].reserver_id == this.userid) {
              this.doneOrders.push(data.done[j])
            }
          }
          for (let k = 0; k < data.today.length; k++) {
            if (data.today[k].workerteam_id == workerTeamId || data.all[k].reserver_id == this.userid) {
              this.mustDoForTodayOrders.push(data.today[k])
            }
          }
        }
        this.getLocation()
        this.displayedWorkOrders = this.workOrderArray
        let worklist = localStorage.getItem('worklist')
        if (worklist) this.setTabTitle(worklist)
        else this.setTabTitle('main')
        this.loading = false;
      }
      )
  }

  /**
   * Change tab so that we display correct information in interface
   */
  tabChanger(tab) {
    this.topIcon = 0
    this.tab = tab
    if (tab == 1) {
      this.displayedWorkOrders = this.workOrderArray
    } else if (tab == 2) {
      this.displayedWorkOrders = this.mustDoForTodayOrders
    } else if (tab == 3) {
      this.displayedWorkOrders = this.doneOrders
    }
    this.changeFilter(this.sortChoise)
    let worklist = localStorage.getItem('worklist')
    if (worklist) this.setTabTitle(worklist)
    else this.setTabTitle('main')
  }

  /**
   * Change active icon to correct one and show correct list of workorders. Disable also previous icon from active state.
   * @param icon clicked icon
   */
  topIconSwitch(icon) {
    if (icon == this.topIcon) {
      this.topIcon = 0
      if (this.tab == 1) {
        this.displayedWorkOrders = this.workOrderArray;
      } else if (this.tab == 2) {
        this.displayedWorkOrders = this.mustDoForTodayOrders
      } else if (this.tab == 3) {
        this.displayedWorkOrders = this.doneOrders
      }
    } else {
      this.topIcon = icon
    }
    // TODO check if this can be simplified
    // Logic for tab one
    if (this.tab == 1) {
      if (this.topIcon == 3) { // openAccess
        this.displayedWorkOrders = this.workOrderArray.filter((el) => {
          return el.access_type == 1
        });
      } else if (this.topIcon == 1) { // keyNeeded
        this.displayedWorkOrders = this.workOrderArray.filter((el) => {
          return el.access_type == 3
        });
      } else if (this.topIcon == 2) { // notOpen
        this.displayedWorkOrders = this.workOrderArray.filter((el) => {
          return el.access_type == 2
        });
      }
    }
    // Logic for tab two
    else if (this.tab == 2) {
      if (this.topIcon == 3) { // openAccess
        this.displayedWorkOrders = this.mustDoForTodayOrders.filter((el) => {
          return el.access_type == 1
        });
      } else if (this.topIcon == 1) { // keyNeeded
        this.displayedWorkOrders = this.mustDoForTodayOrders.filter((el) => {
          return el.access_type == 3
        });
      } else if (this.topIcon == 2) { // notOpen
        this.displayedWorkOrders = this.mustDoForTodayOrders.filter((el) => {
          return el.access_type == 2
        });
      }
    }
    // Logic for tab three
    else if (this.tab == 3) {
      if (this.topIcon == 3) { // openAccess
        this.displayedWorkOrders = this.doneOrders.filter((el) => {
          return el.access_type == 1
        });
      } else if (this.topIcon == 1) { // keyNeeded
        this.displayedWorkOrders = this.doneOrders.filter((el) => {
          return el.access_type == 3
        });
      } else if (this.topIcon == 2) { // notOpen
        this.displayedWorkOrders = this.doneOrders.filter((el) => {
          return el.access_type == 2
        });
      }
    }
    this.changeFilter(this.sortChoise)

  }

  setTabTitle(message: string) {
    let userlang = localStorage.getItem('userlanguage')
    if (userlang) this.translateService.currentLang = userlang
    if (message === 'extra') {
      this.translateService.get('basic.extraWorklist').subscribe({
        next: translation => this.tabTitle = translation
      })
      this.showWorklist = true
      this.currentWorkordersCount = this.displayedWorkOrders.filter(dwo => dwo.time_window_start === null).length
      localStorage.setItem('worklist', 'extra')
    }
    else {{
      this.translateService.get('basic.mainWorklist').subscribe({
        next: translation => {
          this.tabTitle = translation
        }
      })
      this.showWorklist = false
      this.currentWorkordersCount = this.displayedWorkOrders.filter(dwo => dwo.time_window_start !== null).length
      localStorage.setItem('worklist', 'main')
    }}
  }

  toSingleWorkOrder(workorder) {
    let id = workorder.id
    if (!this.isLongPress) {
      this.workOrderId = id;
      this.showDashboard = false;
      this.showSingleOrderDetail = true;
      this.runGetWorkOrder = true;
    } else {
      if(!this.firstItem) {
        this.addSelectedWorkorder(workorder)
      } else {
        this.firstItem = false
      }
    }

  }


  /**
   * Sets attributes dynamically to color and borderm-bottom
   * @param tabId tabId which is 1-3
   * @returns correct color and border-botto attribute for tab
   */
  styleObject(tabId): Object {
    if (this.tab == tabId) return { 'color': '#0058ff', 'border-bottom': '#0058ff 4px solid' }
    else return { 'color': 'gray', 'border-bottom': '0px solid white' }
  }

  /**
   * Switches to correct workerteam, removes correct workerteam from list and runs getWorkerteamOrders function
   * @param id what workerteam we want to switch to
   */
  switchWorkerteam(id) {
    // Project type 2 sees all workorders, for all workerteams, so for them we disable this filter
    if(this.projectType != 2) {
      this.workerteams = Array()
      this.loading = true
      let selectedTeam = this.allWorkerteams.find(workerteam => workerteam.id === id);
      if (selectedTeam != undefined) {
        this.currentWorkerteam = selectedTeam
        this.workerteams = this.allWorkerteams.filter(workerteam => workerteam.id !== selectedTeam.id && workerteam.project_id == this.currentProjectId)
      }

      // Run getWorkerteamOrders
      this.getWorkerteamOrders()
    }
  }

  toggleInventoryList() {
    if (!this.showInventoryList) {
      this.showInventoryList = true;
      this.expandInventoryList = 'assets/icons/expand_less_gray_24dp.svg'
    } else {
      this.showInventoryList = false;
      this.expandInventoryList = 'assets/icons/expand_more_gray_24dp.svg'
    }
  }

  setInformation(workOrderArray) {
    // Apply time window end logic which is when does the work order end
    if (workOrderArray.time_window_end != null) {
      // Split time window end in 2, date and time
      let splitTimeWindowEnd = workOrderArray.time_window_end.split(" ")
      // Split time second time
      let splittedSecondTimeEnd = splitTimeWindowEnd[1].split(":");
      // Format time to correct time view
      workOrderArray.end = splittedSecondTimeEnd[0] + ":" + splittedSecondTimeEnd[1]
      // Apply logic to date
      let date = new Date(splitTimeWindowEnd[0])
      workOrderArray.day = date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()
    }
    let workOrderDate;
    // Apply time window start logic. Time window start is when work order is started
    if (workOrderArray.time_window_start != null) {
      // Split it to time and date
      let splitTimeWindowStart = workOrderArray.time_window_start.split(" ")
      // Split time second time
      let splittedSecondTimeStart = splitTimeWindowStart[1].split(":");
      // Format time
      workOrderArray.start = splittedSecondTimeStart[0] + ":" + splittedSecondTimeStart[1]
      // Make date a date
      let date = new Date(splitTimeWindowStart[0])
      workOrderDate = date;
      // Save date to variable
      workOrderArray.day = date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()
    }

    // Set up tools for work order
    if (workOrderArray.tools != null) {
      // Configure tools to correct JSON format
      let tempToolsOriginal = workOrderArray.tools.replaceAll("'", '"').substring(1, (workOrderArray.tools.length - 1))
      // Set toolsArray to use in HTML
      workOrderArray.toolsArray = JSON.parse("[" + tempToolsOriginal + "]")
    }
    let today = new Date()
    if (workOrderDate != null) {
      // Compare dates to today's date
      if ((workOrderDate.getFullYear() + "-" + workOrderDate.getMonth() + "-" + workOrderDate.getDate() == today.getFullYear() + "-" + today.getMonth() + "-" + today.getDate())) {
        // Push to inventory list work order list
        this.workOrderArray[2].push(workOrderArray)
        // If has tools run this
        if (workOrderArray.toolsArray) {
          for (let i = 0; i < workOrderArray.toolsArray.length; i++) {
            let doesExist = false;
            for (let z = 0; z < this.typeArray.length; z++) {
              // Check if tool exists in typeArray and add to count if does
              if (this.typeArray[z].name == workOrderArray.toolsArray[i].name) {
                this.typeArray[z].count++
                doesExist = true
              }
            }
            // If tool does not exist add to typeArray
            if (!doesExist) {
              this.typeArray.push({ "name": workOrderArray.toolsArray[i].name, "count": 1 })
            }
          }
        }
        if (workOrderArray.model) {
          // Configure meters to correct JSON format
          let replaced = workOrderArray.model.replaceAll("'", '"')
          let model = JSON.parse(replaced)
          let doesExist = false;
          // Run through typeArray array to check if meter exists
          for (let x = 0; x < this.typeArray.length; x++) {
            // Check if meter exists in typeArray and add to count if does
            if (this.typeArray[x].name == model.name) {
              this.typeArray[x].count++
              doesExist = true
            }
          }
          // If meter does not exist in typeArray push it there
          if (!doesExist) this.typeArray.push({ "name": model.name, "count": 1 })
        }
      }
    }
  }
  /**
   * Toggle dashboard on and hide single order detail. Also change runGetWorkOrder to false so
   * that it can be changed to true when needs to be run in single order detail component. This function is
   * called when single order detail is closed
   */
  showDashboardToggle() {
    this.showDashboard = true;
    this.showSingleOrderDetail = false;
    this.runGetWorkOrder = false;
    this.displayedWorkOrders.forEach(element => {
      element.expand = false
    });
  }

  


  changeFilter(value) {
    this.sortChoise = value
    localStorage.setItem('selectedFilter', value); // Save the filter choice

    if (value == 'address') {
      this.displayedWorkOrders.sort((a, b) => (a.address > b.address) ? 1 : ((b.address > a.address) ? -1 : 0))
    } else if (value == 'contactperson') {
      this.displayedWorkOrders.sort((a, b) => (a.endusers[0]?.contact_person_first > b.endusers[0]?.contact_person_first) ? 1 : ((b.endusers[0]?.contact_person_last > a.endusers[0]?.contact_person_last) ? -1 : 0))
      this.displayedWorkOrders.sort((a, b) => (a.endusers[0]?.contact_person_last > b.endusers[0]?.contact_person_last) ? 1 : ((b.endusers[0]?.contact_person_last > a.endusers[0]?.contact_person_last) ? -1 : 0))
    } else if (value == 'starttime') {
      this.displayedWorkOrders.sort((a, b) => (a.time_window_start > b.time_window_start) ? 1 : ((b.time_window_start > a.time_window_start) ? -1 : 0))
    } else if (value == 'distance') {
      this.displayedWorkOrders.sort((a, b) => (a.distance > b.distance) ? 1 : ((b.distance > a.distance) ? -1 : 0))
    } else {
      this.displayedWorkOrders.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
    }
  }

  getLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lon = position.coords.longitude;
        if (this.lat && this.lon) {
          // todo laske etäisyys mittareista

          for (let i = 0; i < this.displayedWorkOrders.length; i++) {
            if (this.displayedWorkOrders[i].coordinates) {
              try {
                let wLat = JSON.parse(this.displayedWorkOrders[i].coordinates!).lat
                let wLon = JSON.parse(this.displayedWorkOrders[i].coordinates!).lon

                let distance = this.calcDistance(this.lat, this.lon, wLat, wLon)
                this.displayedWorkOrders[i].distance = distance
              } catch (error) {
                //console.log(error)

              }
            }
          }
        }
      });
    } else {
      console.log("No support for geolocation")
    }
  }

  //This function takes in latitude and longitude of two location and returns the distance between them as the crow flies
  calcDistance(lat1, lon1, lat2, lon2) {
    let Radius = 6371; // km
    let dLat = this.toRad(lat2 - lat1);
    let dLon = this.toRad(lon2 - lon1);
    lat1 = this.toRad(lat1);
    lat2 = this.toRad(lat2);
    let returnVal = ""

    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = Radius * c; // km

    return d
  }

  // Converts numeric degrees to radians
  toRad(Value) {
    return Value * Math.PI / 180;
  }

  filterOutQueued(workOrders) {
    const ret = workOrders.filter(wo => !this.queuedWorkOrders.includes(wo.id));
    return ret;
  }

  search() {
    if(this.searchField == "") {
      this.toastService.sendToast(false, this.translateService.instant('fieldReporting.dashboard.nothingToSearch'))
    } else {
      this.listSpinner = true
      this.searchedWorkorders = []
      this.selectedWorkorders = []
      this.isLongPress = false;
      this.firstItem = false;
      this.workOrderService.searchWorkorders(
        this.searchField,
        'multisearch'
      ).pipe(takeUntil(this.componentDestroyed$))
        .subscribe({
          next: data => {
            this.searchedWorkorders = data
            this.searchedWorkorders.forEach(element => {
              element.expand = false
            });
            this.listSpinner = false
          },
          // Error handling, show error message in HTML after stopping spinner
          error: () => {
            this.toastService.sendToast(false, this.translateService.instant('planners.reports.noWorkordersFound'))
            this.listSpinner = false
          }
        })
    }
  }

  onMouseDown(workorder): void {
    if (!this.isLongPress) {
      this.longPressTimer = setTimeout(() => {
        this.isLongPress = true;
        if(this.selectedWorkorders.length == 0) {
          this.firstItem = true;
        }
        this.addSelectedWorkorder(workorder);
      }, 500); // 500 ms for long press
    }
  }

  onMouseUp(): void {
    clearTimeout(this.longPressTimer);
    // this.isLongPress = false;
  }

  addSelectedWorkorder(workorder): void {
    const index = this.selectedWorkorders.indexOf(workorder);
    if (index > -1) {
      this.selectedWorkorders.splice(index, 1); // Remove from selected if already there
    } else {
      this.selectedWorkorders.push(workorder); // Add to selected if not already there
    }

    if(this.selectedWorkorders.length == 0) {
      this.isLongPress = false;
      this.firstItem = false;
    }
  }

  interruptSelected() {
    if(this.selectedWorkorders.length > 0) {
      if (this.firstInterruptedStatus) {
        let workorders = this.selectedWorkorders.filter(w =>
          !this.allInterruptedStatuses.some(a => a.id === w.status)
        ).map(wo => wo.id)

        this.selectedWorkorders.forEach(element => {
          if (this.firstInterruptedStatus) element.status = this.firstInterruptedStatus.id
        });
        if (workorders.length > 0) {
          this.fieldReporting.restatusMultipleWorkorders(workorders, this.firstInterruptedStatus.id)
          .subscribe(() => {
            this.selectedWorkorders = []
            this.search()
          })
        } else Swal.fire(this.translateService.instant('fieldReporting.dashboard.massInterruptError'))
      }
    }
  }

  getStatuses() {
    this.plannerProjectService.getStatuses()
    .subscribe(data => {
      this.statuses = data
      let status = this.statuses.find(s => s.state === 4)
      if (status) this.firstInterruptedStatus = status
      this.allInterruptedStatuses = []
      this.statuses.forEach(element => {
        if (element.state === 4) this.allInterruptedStatuses.push(element)
      });
    })
  }

  toggleList(listname: string) {
    if (listname === 'worklist') {
      if (this.displayedWorkOrders.some(wo => wo.time_window_start === null)) this.showWorklist = !this.showWorklist
      else Swal.fire(this.translateService.instant('fieldReporting.dashboard.toggleWorklistError'))
    } else if (listname === 'main') this.showMainList = !this.showMainList
  }

}
