import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FieldReportingService } from '@modules/field-reporting/services/field-reporting/field-reporting.service';

@Injectable({
  providedIn: 'root'
})
export class QueueService {
  private syncInterval: any;
  private syncIntervalTime = 60000; // 1 minute interva
  private queue: any[] = [];

  constructor(
    private fieldReportingService: FieldReportingService
  ) {
    this.initializeOnlineEventListener();
    this.initializeSyncInterval();
    this.loadQueue();
  }

  private initializeSyncInterval() {
    this.syncInterval = setInterval(() => {
      if (navigator.onLine) {
        this.syncQueueWithServer();
      }
    }, this.syncIntervalTime);
  }

  private initializeOnlineEventListener() {
    window.addEventListener('online', () => this.syncQueueWithServer());
  }

  private loadQueue() {
    const existingQueue = localStorage.getItem('reportQueue');
    this.queue = existingQueue ? JSON.parse(existingQueue) : [];
  }

  async addToQueue(jsonData: any, workOrderId: string, reportType: string, images: any[]) {
    const imageData = await this.processImagesAndConvertToBase64(images, reportType);

    const reportData = {
      jsonData: jsonData,
      imageData: imageData,
      workOrderId: workOrderId
    };

    this.queue.push(reportData);
    localStorage.setItem('reportQueue', JSON.stringify(this.queue));
  }

  async processImagesAndConvertToBase64(images: any[], reportType: string): Promise<ImageData[]> {
    let imageMetaData: ImageData[] = [];

    for (let i = 0; i < images.length; i++) {
      if (images[i] && images[i].data && images[i].data.name) {
        let imagePlacePrefix = this.getImagePlacePrefix(reportType, i, images.length);
        let title = images[i].data.name + this.getTitleSuffix(reportType, i, images.length);
        let base64String = await this.convertImageToBase64(images[i].data);

        imageMetaData.push({
          "image_place": imagePlacePrefix + i,
          "title": title,
          "image_key": 'img' + i,
          "base64": base64String
        });
      }
    }

    return imageMetaData;
  }

  private getImagePlacePrefix(reportType: string, index: number, totalImages: number): string {
    switch (reportType) {
      case 'waterMeter':
        return (index < totalImages / 2) ? 'old_' : 'new_';
      case 'interrupt':
        return 'interrupted_';
      default:
        return '';
    }
  }

  private getTitleSuffix(reportType: string, index: number, totalImages: number): string {
    switch (reportType) {
      case 'waterMeter':
        return (index < totalImages / 2) ? '_old' : '_new';
      default:
        return '';
    }
  }

  syncQueueWithServer() {
    this.queue.forEach((report, index) => {
      this.sendReportToServer(report, index);
    });
  }

  sendReportToServer(report, index) {
    const formData = new FormData();

    // Extract workOrderId, jsonData, and imageData from the report
    const workOrderId = report.workOrderId;
    const jsonData = report.jsonData;
    const imageData = report.imageData;

    if (!workOrderId) {
      console.error('Work order ID not found in report');
      return;
    }

    // Append jsonData to formData
    if (jsonData) {
      formData.append('reportdata', JSON.stringify(jsonData));
    }

    // Convert Base64 images back to binary and append
    if (imageData) {
      imageData.forEach(img => {
        if (img.base64) {
          const blob = this.base64ToBlob(img.base64, 'image/png');
          formData.append(img.image_key, blob, img.title);
        }
      });

      // Append the image metadata as well
      formData.append('images', JSON.stringify(imageData.map(img => ({
        image_place: img.image_place,
        title: img.title,
        image_key: img.image_key
      }))));
    }

    // Send the report to the server
    this.fieldReportingService.reportWorkorder(Number(workOrderId), formData)
      .subscribe(
        result => {
          if (result) {
            this.removeFromQueue(index);
          } else {
            // Handle sending failure
          }
        },
        error => {
          // Handle any errors in the request
        }
      );
  }

  removeFromQueue(index: number) {
    this.queue.splice(index, 1);
    localStorage.setItem('reportQueue', JSON.stringify(this.queue));
  }

  base64ToBlob(base64, mimeType) {
    const byteString = atob(base64.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeType });
  }

  processImagesHeatMeter(oldImages: any[], newImages: any[], interruptImages: any[], clickedButtonId: number): { metadata: ImageData[], files: File[] } {
    let imagesToProcess: any[] = [];
    let imageData: ImageData[] = [];
    let imageFiles: File[] = [];

    if (clickedButtonId === 1) {
      imagesToProcess = [...oldImages, ...newImages];
    } else if (clickedButtonId === 2) {
      imagesToProcess = interruptImages;
    }

    for (let i = 0; i < imagesToProcess.length; i++) {
      if (imagesToProcess[i] && imagesToProcess[i].data && imagesToProcess[i].data.name) {
        let imagePlacePrefix = '';
        let titleSuffix = '';

        if (clickedButtonId === 1) {
          if (i < oldImages.length) {
            imagePlacePrefix = 'old_';
            titleSuffix = '_old';
          } else {
            imagePlacePrefix = 'new_';
            titleSuffix = '_new';
          }
        } else if (clickedButtonId === 2) {
          imagePlacePrefix = 'interrupted_';
        }

        imageData.push({
          "image_place": imagePlacePrefix + i,
          "title": imagesToProcess[i].data.name + titleSuffix,
          "image_key": 'img' + i,
        });
        imageFiles.push(imagesToProcess[i].data);
      }
    }

    return { metadata: imageData, files: imageFiles };
  }

  processWaterMeterImages(images: any[], reportType: string): { metadata: ImageData[], files: File[] } {
    let imageData: ImageData[] = [];
    let imageFiles: File[] = [];

    // Define the prefix based on reportType
    let imagePlacePrefix = '';
    if (reportType === 'waterMeter') {
        imagePlacePrefix = 'reported_';
    } else if (reportType === 'interrupt') {
        imagePlacePrefix = 'interrupted_';
    } else {
        imagePlacePrefix = 'unknown_'; // Fallback prefix
    }

    for (let i = 0; i < images.length; i++) {
        if (images[i] && images[i].data && images[i].data.name) {
            imageData.push({
                "image_place": imagePlacePrefix + i,
                "title": images[i].data.name,
                "image_key": 'img' + i
            });
            imageFiles.push(images[i].data);
        }
    }

    return { metadata: imageData, files: imageFiles };
}

  async convertImagesToBase64(files: File[]): Promise<string[]> {
    const promises = files.map(file => this.convertImageToBase64(file));
    return Promise.all(promises);
  }

  convertImageToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result);
        } else {
          reject('Expected a string result');
        }
      };
      reader.onerror = error => reject(error);
      reader.readAsDataURL(file);
    });
  }


}

interface ImageData {
  image_place: string;
  title: string;
  image_key: string;
  base64?: string; // Optional
}