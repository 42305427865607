
        <div class="content position-relative">
            <div class="d-flex pb-2 pt-2">
                <div class="w-10">
                    <img (click)="navigateToOrderDetail()" class="float-left" src="assets/icons/chevron_left_gray_24dp.svg">
                </div>
                <div class="d-flex justify-content-center w-80">
                    <img src="assets/icons/add_blue_24dp.svg">
                    <span class="fw-bold">{{'fieldReporting.wiringReport.newInstallation' | translate}}</span>
                </div>
            </div>
            <div class="row content">
                <hr>
                <div class="p-4">
                    <div class="fw-bold">{{'basic.address' | translate}}</div>
                    <div>{{address}}</div>
                </div>
                <hr>
                <div class="p-4">
                    <div class="fw-bold">{{'fieldReporting.wiringReport.installationDone' | translate}}</div>
                    <div class="d-flex">
                        <div class="col-6 ">
                            <button id="yes-button" class="btn w-90" [ngClass]="clickedButtonId === 1 ? 'btn-primary' : 'btn-secondary'" (click)="changeButtonId(1)">{{'basic.yes' | translate}}</button>
                        </div>
                        <div class="col-6">
                            <button id="no-button" class="btn w-90" [ngClass]="clickedButtonId === 2 ? 'btn-danger' : 'btn-secondary'" (click)="changeButtonId(2)">{{'basic.no' | translate}}</button>
                        </div>
                    </div>
                </div>
                <hr *ngIf="clickedButtonId === 2">
                <div *ngIf="clickedButtonId === 2" class="p-4">
                    <div class="fw-bold">{{'fieldReporting.wiringReport.interruptCode' | translate}}</div>
                    <div ngbDropdown class="d-inline-block w-100">    
                        <button class="btn btn-secondary w-100" id="dropdownInterrupt" ngbDropdownToggle>
                          <span *ngIf="!interruptCode">{{'fieldReporting.wiringReport.interruptCode' | translate}}</span>
                          <span *ngIf="interruptCode">{{interruptCode.definition}}</span>
                        </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownInterrupt" class="w-100">
                          <div *ngFor="let item of interruptOptions" ngbDropdownItem class="w-100 interrupt-option" (click)="interruptCode = item">{{item.definition}}</div>
                        </div>
                    </div>
                </div>
                <div *ngIf="clickedButtonId === 2" class="p-4">
                    <div class="fw-bold">{{'basic.reasonForInterruption' | translate}}
                    <span *ngIf="interruptCode && interruptCode.comment_required">*</span>
                    </div>
                    <textarea type="text" rows="4" class="form-control" placeholder="{{'basic.reasonForInterruption' | translate}}" [(ngModel)]="interruptReason"></textarea>
                </div>
                <div class="d-flex justify-content-center background-light p-4 w-100">
                    <button type="button" class="btn btn-primary w-90" [ngClass]="checkDisabled()" (click)="saveWiringReport()">{{'fieldReporting.wiringReport.saveReport' | translate}}</button>
                </div>
            </div>
        </div>
