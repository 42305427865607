import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {

  @Input() text: string = ""
  @Output() isChecked = new EventEmitter<boolean>();

  @Input() checkbox: boolean = false

  constructor() { }

  ngOnInit(): void {
  }

  checkboxClicked() {
    this.isChecked.emit()
  }


}
