    <div class="d-flex justify-content-center login-container">
        <div id="auth-left" class="shadow-sm rounded-card">
            <h2 class="auth-title fw-600">{{'basic.login' | translate}}</h2>
            <form id="login-form" [formGroup]="formGroup" (ngSubmit)="loginProcess()" class="mt-5">
                <p *ngIf="authFail" class="red-text">Username or password incorrect</p>
                <div class="position-relative has-icon-left mb-4">
                    {{'basic.email' | translate}}
                    <div class="opacity-75">
                        <input id="login-email" type="text" class="form-control mt-1" placeholder="{{'basic.email' | translate}}" formControlName="username">
                    </div>
                </div>
                <div class="position-relative has-icon-left mb-4">
                    {{'basic.password' | translate }}
                    <div class="opacity-75 password_field dual-container">
                        <input id="login-password" type="password" class="form-control mt-1" placeholder="{{'basic.password' | translate }}" formControlName="password">
                        <button (click)="hideShowPassword()" class="eye-icon" type="button"><img *ngIf="!passwordShow" src="assets/icons/visibility_gray_24dp.svg"> <img *ngIf="passwordShow" src="assets/icons/visibility_off_gray_24dp.svg"></button>
                    </div>
                </div>
                <div class="form-check form-check-lg d-flex align-items-end">
                    <input class="form-check-input me-2" type="checkbox" value="" id="flexCheckDefault">
                    <label class="form-check-label" for="flexCheckDefault">
                        {{'loginRegister.keepLoggedIn' | translate}}
                    </label>
                </div>
                <button id="login-button" class="btn btn-primary btn-block btn-lg shadow-lg mt-5">{{'basic.login' | translate }}</button>
            </form>
            <div class="text-center mt-5">
                <button class="btn text-gray-600" id="forgotPasswordBtn" data-bs-toggle="modal" data-bs-target="#forgotPassword">
                    <span>{{'basic.forgotPassword' | translate }}</span>
                </button>
            </div>
        </div>

        <div class="modal fade" id="forgotPassword" tabindex="-1" aria-hidden="true">
<!--            <button id="closeModalButton" [hidden]="true" data-toggle="modal"-->
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
                        <button id="closeModalButton" data-toggle="modal"
                                data-target="#forgotPassword" class="btn btn-default"
                                data-bs-dismiss="modal">{{'basic.close' | translate }}</button>
                        <h4>
                            <span id="forgotPassword-title" class="ms-1">{{'basic.forgotPasswordTitle' | translate }}</span>
                        </h4>
                        <div>
                            <form ngNativeValidate [formGroup]="forgotFormGroup">
                                <div class="gray-text">{{'basic.forgotText' | translate }}</div>
                                <input type="email" name="passwordEmail" class="form-control max-content" formControlName="email"><br>
                                <button id="modal-submit" type="button" (click)="forgotPassword()"  class="btn btn-primary mt-2">{{'basic.submit' | translate }}</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
