export const mockData = [
    {
      name: 'John Doe',
      phoneNumber: '+358 123 1234',
      dateTime: '2023-09-01 10:00 AM',
      duration: '1 day',
      progress: '4/3',
    },
    {
      name: 'Jane Smith',
      phoneNumber: '+358 456 5678',
      dateTime: '2023-09-02 11:30 AM',
      duration: '2 days',
      progress: '5/3',
    },
    {
      name: 'James Johnson',
      phoneNumber: '+358 789 8901',
      dateTime: '2023-09-03 3:15 PM',
      duration: '3 days',
      progress: '6/3',
    },
    {
      name: 'Jennifer Brown',
      phoneNumber: '+358 234 3456',
      dateTime: '2023-09-04 2:45 PM',
      duration: '4 days',
      progress: '7/3',
    },
    {
      name: 'Michael Wilson',
      phoneNumber: '+358 567 6789',
      dateTime: '2023-09-05 9:30 AM',
      duration: '5 days',
      progress: '8/3',
    },
  ];