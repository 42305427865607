export const exportCSV = (response: any, name: string) => {
  let fileName = name + '.csv';
  let fileContent = response;

  const file = new Blob([fileContent], { type: 'text/csv' });

  const link = document.createElement('a');
  link.href = URL.createObjectURL(file);
  link.download = fileName;
  link.click();
  link.remove();
};