<div class="page-container">
    <div class="page-heading">
        <h3>
            <img class="nav-icon" src="assets/icons/list_alt_black_24dp.svg">
           <span class="page-title">{{'sideNavigation.titleEndUser' | translate}}</span>
        </h3>
     </div>
    <div class="page-content">
        <load-spinner [hidden]="!weekSpinner"></load-spinner>
        <div [hidden]="weekSpinner">
            <!-- Week changer and weeks -->
            <div class="borders">
                <div class="justify-content-center d-flex pt-3">
                    <img class="chevron p-2" src="assets/icons/chevron_left_gray_24dp.svg" (click)="moveMonth('previous')">
                    <span class="mt-2 fw-bold px-2">{{currentMonth | date: 'MMMM yyyy'}}</span>
                    <img class="chevron p-2" src="assets/icons/chevron_right_gray_24dp.svg" (click)="moveMonth('next')">
                </div>
                <div class="d-flex justify-content-center py-4">
                    <div *ngFor="let week of weeks; let i = index" >
                        <a (click)="weekClicked(i)">
                            <div [ngClass]="i == 0 ? 'both-borders-week' : 'right-border-week'" [style.background]="week.selected ? '#F4F7FC' : 'white'" class="on-hover p-4">
                                <img [src]="getIcon(week.startDate)" class="week-icon">
                                <span class="ms-1">{{'basic.week' | translate}} {{week.startDate | date: 'w'}}</span>
                                <div class="gray-text font-size-small">{{week.startDate | date: 'dd.MM.yyyy'}} - {{week.endDate | date: 'dd.MM.yyyy'}}</div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <!-- Calendar information section -->
            <div class="row mt-3">
                <div class="col-6">
                    <div class="fw-bold">{{'basic.week' | translate}} {{currentWeekStart | date: 'w'}}</div>
                    <div class="font-size-small">{{currentWeekStart | date: 'dd.MM.YYY'}} - {{currentWeekEnd | date: 'dd.MM.yyyy'}}</div>
                </div>
                <div class="col-6">
                    <div [hidden]="workorderAmountSpinner">
                        <div class="gray-text">{{'planner.endUserCommunications.amountOfWorkorders' | translate}}</div>
                        <div class="fw-bold" [hidden]="workorderAmountSpinner">
                            <span>{{'basic.all' | translate}}: {{workorderAmount}}</span>
                        </div>
                    </div>
                    <load-spinner [hidden]="!workorderAmountSpinner"></load-spinner>
                </div>
                    <div class="row py-2" *ngFor="let item of selectedWeek">
                        <div class="col-6">
                            <div class="d-flex">
                                <div class="col-8 align-self-center">{{item.name}}</div>
                                <div ngbDropdown class="col-4">
                                    <button class="btn btn-secondary width-forwarded-button" *ngIf="item.forwardedBoolean" type="button" id="dropdownMenu" ngbDropdownToggle>
                                        <img class="week-icon" src="assets/icons/forward_to_inbox_green_48.svg">
                                        <span class="font-size-small ms-1">{{'planner.endUserCommunications.forwarded' | translate}}</span>
                                    </button>
                                    <button class="btn btn-secondary width-forwarded-button" *ngIf="!item.forwardedBoolean" type="button" id="dropdownMenu" ngbDropdownToggle>
                                        <img class="week-icon" src="assets/icons/mail_open_yellow_48.svg">
                                        <span class="font-size-small ms-1">{{'planner.endUserCommunications.notForwarded' | translate}}</span>
                                    </button>
                                    <div ngbDropdownMenu aria-labelledby="dropdownMenu">
                                        <div *ngFor="let id of mailStatuses">
                                            <button *ngIf="id === 0" ngbDropdownItem type="button" (click)="changeForwarded(id, item.id)">{{'planner.endUserCommunications.forwarded' | translate}}</button>
                                            <button *ngIf="id === 1" ngbDropdownItem type="button" (click)="changeForwarded(id, item.id)">{{'planner.endUserCommunications.notForwarded' | translate}}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-2 align-self-center" [hidden]="!item.forwardedBoolean">
                            <div class="green-text font-size-small ">{{'planner.endUserCommunications.forwarded' | translate}}: {{item.forwarded | date: 'dd.MM.yyyy HH:mm'}}</div>
                        </div>
                        <div class="col-2 align-self-center" [hidden]="item.forwardedBoolean">
                            <div class="red-text font-size-small">{{'planner.endUserCommunications.calendarNotForwarded' | translate}}</div>
                        </div>
                        <div class="col-2">
                            <load-spinner *ngIf="item.spinner"></load-spinner>
                            <button *ngIf="!item.spinner" [disabled]="item.csv_downloaded && item.undownloaded == 0" type="button" class="ms-4 font-size-small btn btn-primary" (click)="downloadCSV(item.id, item.name, false)">{{'planner.endUserCommunications.downloadMailingList' | translate}}</button>
                        </div>
                        <div class="col-2 font-size-small blue-text align-self-center" [hidden]="!item.csv_downloaded">
                            <span>{{'planner.endUserCommunications.downloaded' | translate}}: {{item.csv_downloaded | date: 'dd.MM.yyyy HH:mm'}}</span>
                            <span class="red-text" *ngIf="item.undownloaded > 0"><br>{{'planner.endUserCommunications.undownloaded' | translate}}: {{item.undownloaded}}</span>
                        </div>
                    </div>
                <div *ngIf="selectedWeek == null" class="red-text">{{'calendar.noCalendarsFound' | translate}}</div>
            </div>
            <hr>
            <!-- Mailing file -->
            <div *ngIf="selectedWeek != null">
                <h4 class="fw-bold">{{'planner.endUserCommunications.mailingFile' | translate}} ({{selectedWeek.length}} {{'calendar.calendars' | translate}})</h4>
                <!--<div class="gray-text">The necessary devices are reserved from the inventory when the mailing status is forwarded.</div>-->
                <div class="d-flex mt-2">
                    <div class="d-flex flex-column">
                        <div [hidden]="!forwardedMailStatus" class="green-text mb-2 font-size-small">{{'planner.endUserCommunications.forwarded' | translate}}: {{forwardedMailStatus}}</div>
                        <div ngbDropdown>
                            <button class="btn btn-secondary font-size-small" type="button" id="mailDropdownMenu" ngbDropdownToggle>
                                <span *ngIf="getIcon(currentWeekStart) == 'assets/icons/forward_to_inbox_green_48.svg'">
                                    <img class="week-icon" src="assets/icons/forward_to_inbox_green_48.svg">
                                    <span class="font-size-small ms-1" >{{'planner.endUserCommunications.forwarded' | translate}}</span>
                                </span>
                                <span *ngIf="getIcon(currentWeekStart) == 'assets/icons/mail_open_yellow_48.svg'">
                                    <img class="week-icon" src="assets/icons/mail_open_yellow_48.svg">
                                    <span class="font-size-small ms-1" >{{'planner.endUserCommunications.notForwarded' | translate}}</span>
                                </span>
                            </button>
                            <div ngbDropdownMenu aria-labelledby="dropdownMenu">
                                <div *ngFor="let id of mailStatuses">
                                    <button *ngIf="id === 0" ngbDropdownItem type="button" (click)="changeCurrentMailStatus(id)">{{'planner.endUserCommunications.forwarded' | translate}}</button>
                                    <button *ngIf="id === 1" ngbDropdownItem type="button" (click)="changeCurrentMailStatus(id)">{{'planner.endUserCommunications.notForwarded' | translate}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column ms-2">
                        <div [hidden]="!downloadedMailStatus" class=" mb-2 blue-text font-size-small">{{'planner.endUserCommunications.downloaded' | translate}}: {{downloadedMailStatus}}</div>
                        <button [disabled]="downloadedAllMailStatus" type="button" class="font-size-small btn btn-primary" (click)="downloadMultipleCSV()">{{'planner.endUserCommunications.downloadAllMailingLists' | translate}}</button>
                    </div>
                </div>

                <hr>
            </div>
            <!-- Calling list -->
            <h4 class="fw-bold">{{'planner.endUserCommunications.callingList' | translate}}</h4>
            <div [hidden]="selectedWeek == null">
                <load-spinner *ngIf="!lastCallingCSVDownloaded"></load-spinner>
              <div class="font-size-small mb-2">{{'planner.endUserCommunications.downloadCallingList' | translate}} {{currentWeekStart | date: 'w'}} ({{currentWeekStart | date: 'dd.MM.yyyy'}} - {{currentWeekEnd | date: 'dd.MM.yyyy'}})</div>
              <div class="blue-text my-2 font-size-small" [hidden]="!callingCSVDownloaded">{{'planner.endUserCommunications.callingCSVDownloaded' | translate}}</div>
              <div class="row">
                <div class="col-3" *ngIf="lastCallingCSVDownloaded">
                  <button type="button" class="font-size-small btn btn-primary" (click)="downloadMultipleCallingCSV()">{{'planner.endUserCommunications.downloadCallingListShort' | translate}}</button>
                  <div class="gray-text font-size-small mb-2">{{'planner.endUserCommunications.callingListInfo' | translate}} </div>
                </div>
                <div class="col-3" *ngIf="lastCallingCSVDownloaded">
                  <button type="button" class="font-size-small btn btn-primary" (click)="downloadNotConfirmedCallingCSV()">{{'planner.endUserCommunications.downloadNotConfirmedCallingListShort' | translate}}</button>
                  <div class="gray-text font-size-small mb-2">{{'planner.endUserCommunications.notConfirmedCallingListInfo' | translate}} </div>
                </div>
              </div>
            </div>
            <div [hidden]="selectedWeek != null" class="red-text">{{'calendar.noCalendarsFound' | translate}}</div>
        </div>
    </div>
</div>
